import { Link } from "react-router-dom";

export function ContactList(props) {
	return (
		<ul className="contact-list">
			<ContactListItem data={ props.data } />
		</ul>
	);
}

export function shareContact(item) {
	console.log(item)
	var text = item.firstname + ' ' + item.lastname + '\n\n' + item.company + '\n' + item.position + '\n\n' + item.phone  + '\n' + item.email;
	console.log(text)
	if (navigator.share) {
	    navigator.share({
	        title: 'Kontakt: ' + item.firstname + ' ' + item.lastname,
	        text: text,
	    })
	}
}

export function ContactListItem(data) {
	var contacts = data.data;
	const items = contacts.map((item) =>
		<li key={item.id} className="contact-item">
			<div className="row">
				<Link className="col-7" to={`/contacts/${item.id}`}>
					<div className="name">
						{ item.title &&
							<span>{item.title} </span>
						}
						{item.firstname} {item.lastname}
					</div>
					<div className="company">
						{ item.company &&
							<span>{item.company}</span>
						}
						{ item.position &&
							<span> / {item.position}</span>
						}
						{ item.gewerk &&
							<span> / {item.gewerk}</span>
						}
					</div>
				</Link>
				<div
					className="col-5 text-end"
				>
					{ navigator.share &&
						<div
							className="share d-inline-block"
							onClick={() => shareContact(item)}
						>
							<img src="/icon/share.svg" />
						</div>
					}
					<a className="link" href={"tel:" + item.phone}><img src="/icon/phone.svg" /></a>
					<Link className="link" to={`/contacts/${item.id}`}><img src="/icon/detail.svg" className="details" /></Link>
				</div>
			</div>
		</li>
	);
	return items;
}