import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


export function ProjectList(props) {
	return (
		<ul className="project-list">
			<ProjectListItem data={ props.data } />
		</ul>
	);
}

export function ProjectListItem(data) {

	const navigate = useNavigate();

	var projects = data.data;
	const items = projects.map((item) =>
		<li key={item.id} className="project-item">
			<div className="row align-items-center">
				<div
					className="col-8"
					onClick={() => navigate(`/projects/${item.id}`)}
				>
					<div className="name mb-2">{item.name}</div>
					<div className="company"><strong>Adresse:</strong> {item.plz} {item.city}</div>
				</div>
				<div className="col-4 text-end">
					<a href={"https://maps.google.com/?q=" + item.lat + ',' + item.lon} target="_blank"><img src="icon/map.svg" /></a>
					<Link to={`/projects/${item.id}`}><img src="/icon/detail.svg" className="details" /></Link>
				</div>
			</div>
		</li>
	);
	return items;
}

export function HouseList(data) {
	return (
		<ul className="project-list">
			<HouseListItem data={ data.data } />
		</ul>
	);
}

export function HouseListItem(data) {
	var houses = data.data;
	const items = houses.map((item) =>
		<li key={item.id} className="project-item house">
			<Link to={`/projects/${item.pid}/houses/${item.id}`}>
				<div className="row align-items-center">
					<div className="col-9">
						<div className="name mt-1 mb-1">{item.name}</div>
					</div>
					<div className="col-3 text-end">
						<img src="/icon/detail.svg" className="details" />
					</div>
					<div className="small">
						{ item.street &&
							<span>{item.street}, </span>
						}
						{ item.zip &&
							<span>{item.zip} </span>
						}
						{ item.city &&
							<span>{item.city}</span>
						}
					</div>
				</div>
			</Link>
		</li>
	);
	return items;
}