import { deleteNote } from '../../helper/restapi.js';


async function deleteNoteById(noteId) {
	const shouldClose = window.confirm('Möchten Sie den Eintrag löschen?');
	if (shouldClose) {
		try {
			const response = await deleteNote(noteId);
		} catch (error) {
			console.error('Error deleting note:', error);
		}
	}

}

export function NotesList(props) {
	return (
		<ul className="notes-list">
			<NotesListItem data={ props.data } userid={ props.userid } userrole={ props.userrole }/>
		</ul>
	);
}

export function NotesListItem(data) {
	var notes = data.data;


	const items = notes.map((item) =>
		<div key={item.id}
			className="note col-12"
		>
			<div className="row">
				<div className="col-3">Titel:</div>
				<div className="col-7 text">{ item.text }</div>
				{ item.user === data.userid || data.userrole === 1 ? (
					<div className="col-2 text-end">
						<span
							class="delete-icon"
							onClick={() => {
								deleteNoteById(item.id);
							}}
						>
						</span>
					</div>
				) : null }
			</div>
			<div className="row time">
				<div className="col-3">Datum:</div>
				<div className="col-9">{ item.time }</div>
			</div>

			{ item.note &&
				<div className="row time">
					<div className="col-3">Notiz:</div>
					<div className="col-9">{ item.note }</div>
				</div>
			}

			{ item.type == 'image' &&
				<>
				<img className="mt-2" src={ item.path } />
				</>
			}

			{ item.type == 'audio' &&
				<audio controls>
					<source src={ item.path } type="audio/webm" />
					Your browser does not support the audio element.
				</audio>
			}
		</div>
	);
	return items;
}

			