import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { HeaderLogo, GoBack } from './Parts/Header.js';

import { getCalEntries, updateCalEntry, addCalEntry, deleteCalEntry } from '../helper/restapi.js';


const DnDCalendar = withDragAndDrop(Calendar);

class CalendarView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			events: [],
			users: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			isAddEventMode: true,
			showDetails: false,
			selectedEvent: null,
			admin: false,
			selectedUser: null,
			external: false,
		};
		this.updateInputValue = this.updateInputValue.bind(this);
	}

	async getCalEntries(user, type) {
		const response = await getCalEntries(user, type);

		var entries = null;
		if (response.data.data.length > 0) {
			var entries = response.data.data
			for (const key in entries) {
				entries[key].start = new Date(entries[key].start);
				entries[key].end = new Date(entries[key].end);

				var user = response.data.users.find(item => item.id === entries[key].user);
				entries[key].color = user.color;
			}
		}

		if (entries) {
			if (entries.length > 0) {
				this.setState({
					events: entries,
					users: response.data.users,
					projects: response.data.projects,
				})      
			}      
		} else {
				this.setState({
					events: [],
					users: response.data.users,
					projects: response.data.projects,
				})  
		}


	}

	async updateCalEntry(id, title, user, start, end, type, comment, project, house) {

		const localOffset = start.getTimezoneOffset();

		const startLocalDate = new Date(start.getTime() - localOffset * 60000);
		start = startLocalDate.toISOString().slice(0, 19).replace('T', ' ');

		const endLocalDate = new Date(end.getTime() - localOffset * 60000);
		end = endLocalDate.toISOString().slice(0, 19).replace('T', ' ');

		const response = await updateCalEntry(id, title, user, start, end, type, comment, project, house);
		
		if (response.status == 200) {
			this.setState({ showDetails: false, selectedEvent: null });
			this.getCalEntries(null, 1);
		}
	}

	async deleteCalEntry(id) {

		const response = await deleteCalEntry(id);
		
		if (response.status == 200) {
			this.setState({ showDetails: false, selectedEvent: null });
			this.getCalEntries(null, 1);
		}
	}

	async addCalEntry(title, user, start, end, type, comment, project, event) {

		const localOffset = start.getTimezoneOffset();

		const startLocalDate = new Date(start.getTime() - localOffset * 60000);
		start = startLocalDate.toISOString().slice(0, 19).replace('T', ' ');

		const endLocalDate = new Date(end.getTime() - localOffset * 60000);
		end = endLocalDate.toISOString().slice(0, 19).replace('T', ' ');

		const response = await addCalEntry(title, user, start, end, type, comment, project, event);
		if (response.status == 200) {
			alert('Eintrag gespeichert!');
			this.setState({ showDetails: false, selectedEvent: null });
			this.getCalEntries(null, 1);
		}
	}

	eventStyleGetter = (event, start, end, isSelected) => {
		const style = {
			backgroundColor: event.color || 'lightgray', // Use event color, default to 'lightgray'
			borderRadius: '0px',
			opacity: 0.8,
			color: 'black',
			border: '0px',
			display: 'block',
		};

		return {
			style: style
		};
	};

	componentDidMount() {
		this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		if (this.props.user.user_id == 6 || this.props.user.user_id == 8 || this.props.user.user_id == 1  || this.props.user.user_id == 3) {
			this.setState({ admin: true })
			this.getCalEntries(null, 1);
		} else {
			this.getCalEntries(this.props.user.user_id, 1);
		}

		
	}

	handleSelectSlot = ({ start }) => {

		const end = new Date(start);
		end.setHours(start.getHours() + 2);

		const newEvent = {
			id: null,
			title: 'Zum Bearbeiten klicken',
			start,
			end,
		};

		this.setState((prevState) => ({
			events: [...prevState.events, newEvent],
		}));
	};

	handleAddEvent = () => {

		const start = new Date();
		start.setMinutes(0);
		start.setSeconds(0);
		start.setMilliseconds(0);

		const end = new Date(start);
		end.setHours(start.getHours() + 1);

		const newEvent = {
			id: null,
			title: 'Zum Bearbeiten klicken',
			start,
			end,
		};

		this.setState((prevState) => ({
			events: [...prevState.events, newEvent],
		}));

		this.setState({ showDetails: true, selectedEvent: newEvent });
	}

	handleSelectEvent = (event, e) => {
		if (event.project && event.project !== 'undefined' && event.project !== 'null') {
			var houses = this.state.projects.find(item => item.name === event.project);
			this.setState({ houses: houses.houses });

			if (event.user == 11) {
				this.setState({ external: true })
			} else {
				this.setState({ external: false })
			}
		}

		this.setState({ showDetails: true, selectedEvent: event });
	};

	handleHide = () => {
		this.setState({ showDetails: false, selectedEvent: null });
	};

	handleEventResize = ({ event, start, end }) => {
		const updatedEvent = { ...event, start, end };
		const updatedEvents = this.state.events.map((e) =>
			e.id === updatedEvent.id ? updatedEvent : e
		);

		if (event.id) {
			this.updateCalEntry(event.id, event.title, event.user, start, end, event.type, event.comment, event.project, event.house);
		}
		

		this.setState({ events: updatedEvents });
	};

	handleEventDrop = ({ event, start, end }) => {
		const updatedEvent = { ...event, start, end };
		const updatedEvents = this.state.events.map((e) =>
			e.id === updatedEvent.id ? updatedEvent : e
		);


		this.updateCalEntry(event.id, event.title, event.user, start, end, event.type, event.comment, event.project, event.house);
		this.setState({ events: updatedEvents });
	};

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'comment') {
			this.setState({
				selectedEvent: {
					...this.state.selectedEvent,
					'comment': val,
				}
			});      
		}

		if (name == 'select-user') {
				this.setState({
					selectedUser: val
				});   
				this.getCalEntries(val, 1); 
		}

		if (name == 'user') {
			const selectedIndex = evt.target.selectedIndex; 

			const title = this.state.users[selectedIndex-1].name;

			this.setState({
				selectedEvent: {
					...this.state.selectedEvent,
					'user': val,
					'title': title,
				}
			});

			if (this.state.users[selectedIndex-1].id == 11) {
				this.setState({external: true})
				this.setState({
					selectedEvent: {
						...this.state.selectedEvent,
						'user': val,
						'title': '',
					}
				});
			} else {
				this.setState({external: false})
			}
		}

		if (name == 'title') {
			this.setState({
				selectedEvent: {
					...this.state.selectedEvent,
					'title': val,
				}
			});      
		}

		if (name == 'project') {
			this.setState({
				selectedEvent: {
					...this.state.selectedEvent,
					'project': val,
				}
			});

			var houses = this.state.projects.find(item => item.name === val);
			if (houses.houses.length > 0) {
				this.setState({ houses: houses.houses })
			} else {
				this.setState({ houses: []})
			}

			if (!val) {
				this.setState({
					selectedEvent: {
						...this.state.selectedEvent,
						'house': null,
					}
				});				
			}
		}

		if (name == 'house') {
			this.setState({
				selectedEvent: {
					...this.state.selectedEvent,
					'house': val,
				}
			});
		}

		if (name === 'dateInputStart' || name === 'timeInputStart') {
			const dateInput = name === 'dateInputStart' ? val : this.state.selectedEvent.start.toISOString().slice(0, 10);
			const timeInput = name === 'timeInputStart' ? val : this.state.selectedEvent.start.toISOString().slice(11, 16);
			const dateTimeString = `${dateInput} ${timeInput}:00`;

			this.setState((prevState) => ({
				selectedEvent: {
					...prevState.selectedEvent,
					start: new Date(dateTimeString),
				},
			}));
		}

		if (name === 'dateInputEnd' || name === 'timeInputEnd') {
			const dateInput = name === 'dateInputEnd' ? val : this.state.selectedEvent.end.toISOString().slice(0, 10);
			const timeInput = name === 'timeInputEnd' ? val : this.state.selectedEvent.end.toISOString().slice(11, 16);
			const dateTimeString = `${dateInput} ${timeInput}:00`;

			this.setState((prevState) => ({
				selectedEvent: {
					...prevState.selectedEvent,
					end: new Date(dateTimeString),
				},
			}));
		}

	}

	saveEntry() {
		var event = this.state.selectedEvent
		this.updateCalEntry(event.id, event.title, event.user, event.start, event.end, event.type, event.comment, event.project, event.house);
	}

	deleteEntry() {
		var event = this.state.selectedEvent
		this.deleteCalEntry(event.id);
	}

	addEntry() {
		var event = this.state.selectedEvent
		this.addCalEntry(event.title, event.user, event.start, event.end, 1, event.comment, event.project, event.house);   
	}

	formatDate = (date) => {
		return date.toISOString().split('T')[0];
	};

	formatTime = (date) => {
		const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Berlin' };
		return date.toLocaleTimeString('de-DE', options);
	};

	render() {
		const localizer = momentLocalizer(moment);

		if (!this.state.isReady) {
			return <HeaderLogo />;
		}

		return (
			<>

				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Kalender</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.handleAddEvent}
								>+</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-2">
					{ this.state.admin &&
						<>
							<div className="form-group">
								<label>Filter</label>
								<select
									className="form-select usero"
									onChange={evt => this.updateInputValue(evt)}
									name="select-user"
									value={this.state.selectedUser}
								>
									<option value="">Alle Benutzer</option>
									{this.state.users.map((option) => (
										<option key={option.id} value={option.id}>
											{ option.name}
										</option>
									))}
								</select>
							</div>
							<DnDCalendar
								localizer={localizer}
								defaultView="work_week"
								startAccessor="start"
								endAccessor="end"
								style={{ height: 500 }}
								views={['work_week', 'week', 'day', 'agenda',  'month']}
								min={new Date(0, 0, 0, 5, 0, 0)}
								events={this.state.events}
								onSelectSlot={this.handleSelectSlot}
								onSelectEvent={this.handleSelectEvent}
								onEventResize={this.handleEventResize}
								onEventDrop={this.handleEventDrop}
								eventPropGetter={this.eventStyleGetter}
								selectable
								resizable
								messages={{
									next: '>',
									previous: '<',
									today: 'Heute',
									month: 'Monat',
									agenda: 'Liste',
									week: 'Mo-So',
									work_week: 'Mo-Fr',
									day: 'Tag',
								}}
								components={{
									event: EventComponent,
								}}
								formats={{
									dayFormat: (date, culture, localizer) =>
									localizer.format(date, 'dd, DD.MM.', culture),

									weekdayFormat: (day, culture, localizer) =>
									localizer.format(day, 'ddd', culture),

									monthHeaderFormat: (date, culture, localizer) =>
									localizer.format(date, 'MMMM YYYY', culture),

									dayHeaderFormat: (date, culture, localizer) =>
									localizer.format(date, 'ddd, DD.MM.YYYY', culture) +
									' | KW' + localizer.format(date, 'W', culture),

									dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
									localizer.format(start, 'DD.MM.YYYY', culture) +
									' - ' +
									localizer.format(end, 'DD.MM.YYYY', culture) +
									' | KW' + localizer.format(end, 'W', culture),

									agendaDateFormat: (date, culture, localizer) =>
									localizer.format(date, 'DD.MM.YYYY', culture),

									agendaTimeFormat: (date, culture, localizer) =>
									localizer.format(date, 'hh:mm', culture),

									agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
									localizer.format(start, 'hh:mm', culture) +
									' - ' +
									localizer.format(end, 'hh:mm', culture),
								}}
							/>
						</>
					}
					{ !this.state.admin &&
						<DnDCalendar
							localizer={localizer}
							defaultView="week"
							startAccessor="start"
							endAccessor="end"
							style={{ height: 500 }}
							views={['week', 'day', 'agenda',  'month']}
							events={this.state.events}
							onSelectEvent={this.handleSelectEvent}
							eventPropGetter={this.eventStyleGetter}
							messages={{
								next: '>',
								previous: '<',
								today: 'Heute',
								month: 'Monat',
								week: 'Woche',
								day: 'Tag',
							}}
							components={{
								event: EventComponent,
							}}
							formats={{
								dayFormat: (date, culture, localizer) =>
								localizer.format(date, 'dd', culture),
								weekdayFormat: (day, culture, localizer) =>
								localizer.format(day, 'ddd', culture),
								monthHeaderFormat: (date, culture, localizer) =>
								localizer.format(date, 'MMMM YYYY', culture),
								dayHeaderFormat: (date, culture, localizer) =>
								localizer.format(date, 'ddd, MM.DD.', culture),
								dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
								localizer.format(start, 'MMMM DD', culture) +
								' - ' +
								localizer.format(end, 'MMMM DD', culture),
								agendaDateFormat: (date, culture, localizer) =>
								localizer.format(date, 'DD.MM.DD.', culture),
								agendaTimeFormat: (date, culture, localizer) =>
								localizer.format(date, 'hh:mm', culture),
								agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
								localizer.format(start, 'hh:mm', culture) +
								' - ' +
								localizer.format(end, 'hh:mm', culture),
							}}
						/>
					}
				</div>

					{ this.state.showDetails &&
							<div className="modal fade show" role="dialog" style={{ display: "block" }}>
								<div className="modal-backdrop fade show"></div>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">Details</h5>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleHide}>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										{ !this.state.admin &&
											<div className="modal-body">
												<p><strong>{ this.state.selectedEvent.title }</strong></p>
												<p>{ this.state.selectedEvent.comment }</p>
												<p>
													Start:<br/> 
													{new Date(this.state.selectedEvent.start).toLocaleString('de-DE', {
														year: 'numeric',
														month: '2-digit',
														day: '2-digit',
														hour: '2-digit',
														minute: '2-digit',
													})}
												</p>
												<p>
													Ende:<br/>  
													{new Date(this.state.selectedEvent.end).toLocaleString('de-DE', {
														year: 'numeric',
														month: '2-digit',
														day: '2-digit',
														hour: '2-digit',
														minute: '2-digit',
													})}
												</p>
											</div>
										}
										{ this.state.admin &&
											<div className="modal-body">
												<div className="form-group">
													<label>Zuständige Person</label>
													<select
														className="form-select usero"
														onChange={evt => this.updateInputValue(evt)}
														name="user"
														value={this.state.selectedEvent.user}
													>
														<option value="">Benutzer wählen</option>
														{this.state.users.map((option) => (
															<option key={option.id} value={option.id}>
																{ option.name}
															</option>
														))}
													</select>
												</div>

												{ this.state.external &&
													<div className="form-group">
														<label>Name</label>
														<input
															type="text"
															name="title"
															value={this.state.selectedEvent.title}
															className="form-control"
															onChange={evt => this.updateInputValue(evt)}
														/>
													</div>  
												}

												<div className="row">
													<label>Beginn</label>
													<div className="col-6">
														<div className="form-group">
															<input
																type="date"
																name="dateInputStart"
																value={this.formatDate(this.state.selectedEvent.start)}
																className="form-control"
																onChange={this.updateInputValue}
															/>
														</div>
													</div>
													<div className="col-6">
														<div className="form-group">
															<input
																type="time"
																name="timeInputStart"
																value={this.formatTime(this.state.selectedEvent.start)}
																className="form-control"
																onChange={this.updateInputValue}
																step="900"
															/>
														</div>
													</div>
												</div>

												<div className="row">
													<label>Beginn</label>
													<div className="col-6">
														<div className="form-group">
															<input
																type="date"
																name="dateInputEnd"
																value={this.formatDate(this.state.selectedEvent.end)}
																className="form-control"
																onChange={this.updateInputValue}
															/>
														</div>
													</div>
													<div className="col-6">
														<div className="form-group">
															<input
																type="time"
																name="timeInputEnd"
																value={this.formatTime(this.state.selectedEvent.end)}
																className="form-control"
																onChange={this.updateInputValue}
																step="900"
															/>
														</div>
													</div>
												</div>
												<div className="form-group">
													<label>Projekt</label>
													<select
														className="form-select"
														onChange={evt => this.updateInputValue(evt)}
														name="project"
														value={this.state.selectedEvent.project}
													>
														<option value="">Projekt wählen</option>
														{this.state.projects.map((option) => (
															<option key={option.id} value={option.name}>
																{ option.name}
															</option>
														))}
													</select>
												</div>
												{ this.state.selectedEvent.project && this.state.houses && this.state.houses.length > 0 &&
													<div className="form-group">
														<label>Haus</label>
														<select
															className="form-select"
															onChange={evt => this.updateInputValue(evt)}
															name="house"
															value={this.state.selectedEvent.house}
														>
															<option value="">Haus wählen</option>
															{this.state.houses.map((option) => (
																<option key={option.id} value={option.name}>
																	{ option.name}
																</option>
															))}
														</select>
													</div>
												}
												<div className="form-group">
													<label>Kommentar</label>
													<textarea
														type="text"
														name="comment"
														value={this.state.selectedEvent.comment}
														className="form-control"
														onChange={evt => this.updateInputValue(evt)}
													/>
												</div>  

												{ !this.state.selectedEvent.id && this.state.selectedEvent.user &&
													<button
														type="submit"
														className="btn btn-primary btn-block mb-0"
														onClick={ e => this.addEntry(e) }
													>Eintrag erstellen</button>
												}
												{ this.state.selectedEvent.id &&
													<>
													<button
														type="submit"
														className="btn btn-primary btn-block mb-0"
														onClick={ e => this.saveEntry(e) }
													>Eintrag speichern</button>
													<button
														type="submit"
														className="btn btn-danger btn-block mb-0 mt-1"
														onClick={ e => this.deleteEntry(e) }
													>Eintrag löschen</button>
													</>
												}
											</div>
										}

									</div>
								</div>
							</div>
						}
			</>
		);
	}
}

const EventComponent = ({ event }) => (
	<div>
		<figcaption className="small">
			<>{event.title}</>
			{ event.project && event.project != 'undefined' && event.project != 'null' &&
				<>: { event.project }</>
			}
			{ event.house && event.house != 'undefined' && event.house != 'null' &&
				<>, { event.house }</>
			}	
			{ !event.project && event.comment &&
				<>: {event.comment}</>
			}		
			
		</figcaption>
	</div>
);

export default CalendarView;
