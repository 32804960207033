import React from 'react';
import ReactDOM from 'react-dom';

import { HeaderLogo } from "./Parts/Header.js";

import { handleLogin } from '../helper/restapi.js';

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
			email: '',
			password: '',
			error: false,
			message: '',
		};
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;
		this.setState({
			[name]: val
		});
	}

	async login(e) {
		e.preventDefault();
		var email = this.state.email;
		var password = this.state.password;
		if (email && password) {

			const response = await handleLogin(email, password);

			if (response.status == 200) {
				window.location.reload(false);
			} else {
				this.setState({ error: true });
				this.setState({ message: response.data.message });				
			}

		} else {
			this.setState({ error: true });
			this.setState({ message: 'Bitte geben Sie E-Mail und Passwort an!' });
		}	
	}

	componentDidMount() {
		this.setState({ isReady: true });
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<div>load</div>
			);
		}

		return (
			<>
				<HeaderLogo />
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 offset-lg-3">
							<h2 className="text-center">Login</h2>
							<form className="mt-5">
								<div className="form-group">
									<label>E-Mail</label>
									<input
										type="email"
										name="email"
										value={this.state.email}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Passwort</label>
									<input
										type="password"
										name="password"
										value={this.state.password}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								{ this.state.error && 
									<div>
										{ this.state.message }
									</div>
								}
								<button
									type="submit"
									className="btn btn-primary btn-block"
									onClick={ e => this.login(e) }
								>Anmelden</button>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}

}

export default Home;