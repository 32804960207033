import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { ContactList } from "./Parts/Contacts.js";
import { getAllContacts, getContacts, addNewContact } from '../helper/restapi.js';


function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

class Contacts extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
			gewerke: [],
			message: '',
			term: '',
			project: '',
			house: '',
			add: false,
			projectName: '',
			addContact: {
				title: '',
				firstname: '',
				lastname: '',
				email: '',
				phone: '',
				company: '',
				position: '',
				gewerk: '',
				address: '',
				uid: '',
				office: '',
				outside: '',
				payment: '',
				birthday: '',
			},
		};
	}

	async getAllContacts() {
		const response = await getAllContacts();
		this.setState({ gewerke: response.data.gewerke });
		this.setState({ data: response.data }, () => {
			if (this.props.location.state) {
				if (this.props.location.state.term) {
					this.searchContactSpeach(this.props.location.state.term);
				}
			}
		});
		this.setState({ isReady: true });
	}

	async getContacts(project, house) {
		const response = await getContacts(project, house);

		const seenIds = {};
		const contacts = response.data.data;
		const uniqueArray = contacts.filter(item => {
		  if (!seenIds[item.id]) {
		    seenIds[item.id] = true;
		    return true;
		  }
		  return false;
		});

		response.data.data = uniqueArray;

		this.setState({ gewerke: response.data.gewerke });
		this.setState({ data: response.data }, () => {});
		this.setState({ isReady: true });
		this.setState({ projectName: response.data.projectname });
	}

	async addContactData(contactData, project, house) {

		const response = await addNewContact(contactData, project, house);
		if (response.data.success) {
			this.setState({ add: false });
			if (this.props.location.state) {
				var checkProjectHouse = this.props.location.state.split('/');
				if (checkProjectHouse[1] == 'projects') {
					this.setState({ project: checkProjectHouse[2] });
					this.getContacts(checkProjectHouse[2], null);
				} else {
					this.getAllContacts();
				}
			} else {
				this.getAllContacts();
			}
		} else {
			alert('Es ist ein Fehler aufgetreten!');
		}
	}

	componentDidMount() {
		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');

			if (checkProjectHouse[1] == 'projects' && checkProjectHouse[3] == 'houses') {
				this.setState({ project: checkProjectHouse[2] });
				this.setState({ house: checkProjectHouse[4] });
				this.getContacts(checkProjectHouse[2], checkProjectHouse[4]);
			} else if (checkProjectHouse[1] == 'projects') {
				this.setState({ project: checkProjectHouse[2] });
				this.getContacts(checkProjectHouse[2], null);
			} else {
				this.getAllContacts();
			}
		} else {
			this.getAllContacts();
		}
	}

	searchContactSpeach(term) {
		var searchValue = term;
		searchValue = searchValue.toLowerCase();

		var originalData = this.state.data;

		if (searchValue.length > 0) {
			var data = originalData.data;
			var filtered = [];

			for(var i=0; i < data.length; i++) {

				var searchIn = data[i]['firstname'] + ' ' + data[i]['lastname'] + ' ' + data[i]['company'];
				searchIn = searchIn.toLowerCase();

				if(searchIn.indexOf(searchValue)!=-1) {
					filtered.push(data[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
			}

			var filteredData = {...originalData};
			filteredData.data = filtered;

			this.setState({ data: filteredData });			
		} else {
			this.getAllContacts();
			this.setState({ message: '' });
		}		
	}
	searchContact = (event) => {
		var searchValue = event.target.value;
		searchValue = searchValue.toLowerCase();

		var originalData = this.state.data;

		if (searchValue.length > 0) {
			var data = originalData.data;
			var filtered = [];

			for(var i=0; i < data.length; i++) {

				var searchIn = data[i]['firstname'] + ' ' + data[i]['lastname'] + ' ' + data[i]['company'] + ' ' + data[i]['gewerk'] + ' ' + data[i]['position'];
				searchIn = searchIn.toLowerCase();

				if(searchIn.indexOf(searchValue)!=-1) {
					filtered.push(data[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
			}

			var filteredData = {...originalData};
			filteredData.data = filtered;

			this.setState({ data: filteredData });			
		} else {
			this.getAllContacts();
			this.setState({ message: '' });
		}

	}

	addContact = () => {
		this.setState({ add: true });
	}

	addContactBack = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;
		this.setState({
			addContact: {
				...this.state.addContact,
				[name]: val
			}
		});
	}

	saveContact = () => {
		var contactData = this.state.addContact;
		if ((contactData.lastname && contactData.email) || (contactData.lastname && contactData.phone)) {
			this.setState({ isReady: false });
			this.addContactData(contactData, this.state.project, this.state.house);
		} else {
			alert('Bitte entweder E-Mail oder Telefonnummer angeben!');
		}
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.add == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addContactBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Kontakt hinzufügen</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Titel</label>
									<input
										type="text"
										name="title"
										value={this.state.addContact.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Vorname</label>
									<input
										type="text"
										name="firstname"
										value={this.state.addContact.firstname}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Nachname*</label>
									<input
										type="text"
										name="lastname"
										value={this.state.addContact.lastname}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Geburtstag</label>
									<input
										type="date"
										name="birthday"
										value={this.state.addContact.birthday}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>E-Mail</label>
									<input
										type="email"
										name="email"
										value={this.state.addContact.email}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Telefon</label>
									<input
										type="text"
										name="phone"
										value={this.state.addContact.phone}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Adresse</label>
									<input
										type="text"
										name="address"
										value={this.state.addContact.address}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Firma</label>
									<input
										type="text"
										name="company"
										value={this.state.addContact.company}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>UID-Nummer</label>
									<input
										type="text"
										name="uid"
										value={this.state.addContact.uid}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Zahlungsmodalitäten</label>
									<input
										type="text"
										name="payment"
										value={this.state.addContact.payment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ansprechperson Büro</label>
									<input
										type="text"
										name="office"
										value={this.state.addContact.office}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ansprechperson Baustelle</label>
									<input
										type="text"
										name="outside"
										value={this.state.addContact.outside}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Position</label>
									<input
										type="text"
										name="position"
										value={this.state.addContact.position}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Gewerk</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="gewerk"
										value={this.state.addContact.gewerk}
									>
										<option value="">Gewerk wählen</option>
										{this.state.gewerke.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name }
											</option>
										))}
									</select>
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveContact(e) }
								>Kontakt erstellen</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Kontakte</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.addContact}
								>+</div>
							</div>
						</div>
					</div>
				</div>
				<div className="search-bar">
					<div className="container">
						<div className="form-group">
							<input
								type="text"
								placeholder="Suchen"
								onKeyUp={this.searchContact}
							/>
						</div>
					</div>					
				</div>
				{this.state.projectName &&
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h2 className="pt-0 pb-0">Kontake: { this.state.projectName }</h2>
							</div>
						</div>
					</div>
				}
				<div className="container">
					{ this.state.message && 
						<p className="mt-5 text-center">{ this.state.message }</p>
					}
					{ data.length < 1 &&
						<p>Es gibt noch keine Kontakte</p>
					}
					<ContactList data={ data } />
				</div>
			</>
		);
	}

}

export default withParams(Contacts);
