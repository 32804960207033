import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { ShareOutline } from 'react-ionicons';

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getFilesApi, addNewFile, deleteFileById } from '../helper/restapi.js';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

function deleteFile(fileId) {
	const shouldClose = window.confirm('Möchten Sie die Datei löschen?');
	if (shouldClose) {
		deleteFileById(fileId);
		alert('Datei gelöscht!');
		window.location.reload(false);
	}
}


function FileList(data, user) {
	return (
		<div className="row files">
			<File data={ data.data } user={ data.user } />
		</div>
	);
}

function shareFile(item) {
	var text = 'Hier finden Sie die Datei "' + item.title + '" zum Projekt ' + item.projectname.name;
	if (navigator.share) {
	    navigator.share({
	        title: 'Datei: ' + item.title,
	        text: text,
	        url: item.fullpath
	    })
	}
}

function File(data) {
	var files = data.data;
	var i = 0;
	const items = files.map((item) =>
		<div
			key={i++}
			className="file col-12 relat"
		>
			<div className="inner">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-8">
								<div className="title">
									{ item.title }
								</div>
							</div>
							<div className="col-4 text-end">
								<div className="icons">
									{ navigator.share &&
										<div
											className="share d-inline-block"
											onClick={() => shareFile(item)}
										>
											<img src="/icon/share.svg" />
										</div>
									}
									<a target="_blank" href={ item.fullpath }>
										<img src="/icon/detail.svg" className="details" />
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className="col-12 comment">
						<div className="row">
							<div className="col-3">
								Typ:
							</div>
							<div className="col-9">
								 <span className="text-uppercase">{ item.path.split('.').pop() }</span>
							</div>
						</div>
					</div>
					<div className="col-12 date">
						<div className="row">
							<div className="col-3">
								Datum:
							</div>
							<div className="col-9">
								 { item.date }
							</div>
						</div>
					</div>
					<div className="col-12 comment">
						<div className="row">
							<div className="col-3">
								Kommentar:
							</div>
							<div className="col-9">
								 { item.comment }
							</div>
						</div>
					</div>
					<div className="col-12 user">
						<div className="row">
							<div className="col-3">
								Benutzer:
							</div>
							<div className="col-9">
								 { item.username }
							</div>
						</div>
					</div>
					{ data.user == 1 &&
					<span
						className="delete-icon-internal"
						onClick={() => deleteFile(item.id)}
					></span>
					}
				</div>
			</div>
		</div>
	);
	return items;
}

class Files extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			message: '',
			term: '',
			project: '',
			house: '',
			folders: [],
			files: [],
			currentFolder: '',
			add: false,
			filters: {
				user: null,
				project: null,
				house: null,
				title: null,
				comment: null,
				folder: null,
			},
			userId: '',
			userRole: '',
			userFirstname: '',
			addFile: {
				user: '',
				project: '',
				house: '',
				title: '',
				comment: '',
				folder: 99,
			},
		};
	}

	async getFiles() {
		const response = await getFilesApi(this.state.filters);
		this.setState({ files: response.data.data }, () => {});
		this.setState({ isReady: true });
	}

	addFile = () => {
		this.setState({ add: true });
	}

	addFileBack = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'file') {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]:  evt.target.files[0]
				}
			});
		} else {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]: val
				}
			});			
		}

	}

	saveFile = () => {

		var user = this.state.userId;
		var project = this.state.project;
		var house = this.state.house;
		var fileData = this.state.addFile;

		if (fileData.title && fileData.folder && fileData.file) {
			this.setState({ isReady: false });
			this.addFileData(user, project, house, fileData);
		} else {
			alert('Bitte Titel und Ordner wählen!');
		}

	}

	async addFileData(user, project, house, fileData) {
		const response = await addNewFile(user, project, house, fileData);

		if (response.data.success) {
			this.setState({ add: false });
			this.setState({ isReady: false });
			this.getFiles();
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: false });
		}
	}
	
	componentDidMount() {

		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		this.setState({
			filters: {
				...this.state.filters,
				'folder': 99,
			}
		}, () => {
			this.getFiles();
		});
	}

	render() {

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								{ !this.state.add &&
									<GoBack />
								}
								{ this.state.add &&
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addFileBack}
									/>
								}
							</div>
							<div className="col-8 text-center">
								<h1>Besprechungsprotokolle</h1>
							</div>
							<div className="col-2 add-contact text-end">
								{ !this.state.add &&
								<div
									className="add-link"
									onClick={this.addFile}
								>+</div>
								}
							</div>
						</div>
					</div>
				</div>
				
				{ !this.state.add &&
					<>

						{ this.state.files.length > 0 &&
							<div className="container">
								<h2 className="">Alle Protokolle</h2>
								
								<FileList data={ this.state.files } user={ this.state.userRole } />
							</div>
						}
						{ this.state.files.length < 1 &&
							<div className="container">
								{ this.state.currentFolder &&
									<h2 className="">{ this.state.currentFolder }</h2>
								}
								{ !this.state.currentFolder &&
									<h2 className="">Alle Dateien</h2>
								}
								
								<p>Keine Dateien in diesem Ordner</p>
							</div>
						}
					</>
				}

				{ this.state.add &&
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Titel</label>
									<input
										type="text"
										name="title"
										value={this.state.addFile.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Kommentar</label>
									<textarea
										type="text"
										name="comment"
										value={this.state.addFile.comment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<input
								id="imageUpload"
								name="file"
								type="file"
								className="mb-4"
								onChange={evt => this.updateInputValue(evt)}
							/>
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveFile(e) }
								>Dokument hochladen</button>
							</div>
						</div>
					</div>
				}
			</>
		);
	}

}

export default withParams(Files);
