import React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { ContactList } from "./Parts/Contacts.js";
import { getContactById, editContact, deleteContact, editMatches } from '../helper/restapi.js';

import { Tiles } from "./Parts/Tiles.js";


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ContactDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			id: '',
			data: [],
			tiles: [],
			userRole: '',
			edit: false,
			editData: [],
			projects: [],
			matches: [],
			matchesEdited: false,
		};
	}

	async getContact(id) {
		const response = await getContactById(id);
		this.setState({ data: response.data });
		this.setState({ editData: response.data.data });

		this.setState({ projects: response.data.projects });
		this.setState({ matches: response.data.matches });

		const tiles = [
			{
				'order': 1,
				'title': 'Anrufen',
				'icon': 'phone',
				'route': 'tel:' + response.data.data.phone,
			},
			{
				'order': 2,
				'title': 'Schreiben',
				'icon': 'mail',
				'route': 'mailto:' + response.data.data.email,
			},
		];

		this.setState({ tiles: tiles});

		this.setState({ isReady: true });
	}

	componentDidMount() {
		let { id } = this.props.params;
		this.setState({ userRole: this.props.user.user_role });
		this.getContact(id);
	}

	updateInputValue(evt, pid) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'project') {
			var targetId = evt.target.value;
			const projects = this.state.projects;
			const index = projects.findIndex(item => item.id == targetId);

			if (projects[index].match) {
				projects[index].match = false;

				projects[index].houses.forEach(house => {
					house.match = false;
				});

			} else {
				projects[index].match = true;
				// projects[index].houses.forEach(house => {
				// 	house.match = true;
				// });
			}

			this.setState({
				projects: projects,
				matchesEdited: true,
			});			
		} else if (name == 'house') {

			var targetId = pid;
			const projects = this.state.projects;
			const index = projects.findIndex(item => item.id == targetId);

			var houseId = evt.target.value;
			const houses = projects[index].houses;
			const houseIndex = houses.findIndex(item => item.id == houseId);

			if (projects[index].houses[houseIndex].match) {
				projects[index].houses[houseIndex].match = false;
			} else {
				projects[index].houses[houseIndex].match = true;
			}

			this.setState({
				projects: projects,
				matchesEdited: true,
			});	
		} else {
			this.setState({
				editData: {
					...this.state.editData,
					[name]: val,
				}
			});			
		}

	}

	async saveContact(contactData) {

		var contactData = this.state.editData;

		this.setState({ isReady: false });

		if (contactData.lastname) {

			const response = await editContact(contactData);
			if (response.data.success) {

				if (this.state.matchesEdited) {
					const projects = this.state.projects;

					var matchesArray = [];
					projects.map(project => {
						if (project.match) {
							var houses = project.houses;

							var countHouses = 0;
							houses.map(house => {
								if (house.match) {
									matchesArray.push({
										contact: parseInt(this.props.params.id),
										project: project.id,
										house: house.id,
									})
									countHouses++;
								}
							})	

							if (countHouses < 1) {
								matchesArray.push({
									contact: parseInt(this.props.params.id),
									project: project.id,
									house: 0,
								})						
							}
						}

					})	
					const response = await editMatches(parseInt(this.props.params.id), matchesArray);
				}

				this.getContact(contactData.id);
				this.setState({edit: false});
				this.setState({ isReady: true });
			} else {
				alert('Es ist ein Fehler aufgetreten!');
				this.setState({ isReady: true });
			}			
		} else {
			alert('Bitte alle Felder ausfüllen!');
			this.setState({ isReady: true });
		}
	}

	editContactView = () => {
		this.setState({ edit: true });
	}

	editContactViewBack = () => {
		this.setState({ edit: false });
	}

	deleteContactConfirm = (id) => {
		const shouldClose = window.confirm('Möchten Sie den Kontakt löschen?');
		if (shouldClose) {
			this.deleteContact(id);
			this.setState({ isReady: false });
		}
	};

	async deleteContact(id) {
		const response = await deleteContact(id);
		if (response.data.success) {
			window.history.go(-1)
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	render() {

		const { data, projects, matches } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.edit == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.editContactViewBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Kontakt bearbeiten</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Titel</label>
									<input
										type="text"
										name="title"
										value={this.state.editData.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Vorname</label>
									<input
										type="text"
										name="firstname"
										value={this.state.editData.firstname}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Nachname*</label>
									<input
										type="text"
										name="lastname"
										value={this.state.editData.lastname}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Geburtstag</label>
									<input
										type="date"
										name="birthday"
										value={this.state.editData.birthday}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>E-Mail</label>
									<input
										type="email"
										name="email"
										value={this.state.editData.email}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Telefon</label>
									<input
										type="text"
										name="phone"
										value={this.state.editData.phone}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Adresse</label>
									<input
										type="text"
										name="address"
										value={this.state.editData.address}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Firma</label>
									<input
										type="text"
										name="company"
										value={this.state.editData.company}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>UID-Nummer</label>
									<input
										type="text"
										name="uid"
										value={this.state.editData.uid}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Zahlungsmodalitäten</label>
									<input
										type="text"
										name="payment"
										value={this.state.editData.payment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ansprechperson Büro</label>
									<input
										type="text"
										name="office"
										value={this.state.editData.office}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ansprechperson Baustelle</label>
									<input
										type="text"
										name="outside"
										value={this.state.editData.outside}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Position</label>
									<input
										type="text"
										name="position"
										value={this.state.editData.position}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div>
									{ projects.length > 0 &&
										<div className="part">
											<div className="form-group">
												<label>Zugewiesene Projekte</label>
												<hr className="mt-0" />
											</div>
											
												{projects.map((project) => (
													<div>
													    <div className="form-check" key={project.id}>
													    	<input
													    		className="form-check-input"
													    		type="checkbox"
													    		checked={project.match}
													    		name="project"
													    		value={project.id}
													    		onChange={evt => this.updateInputValue(evt)}
													    	/>
															<label className="form-check-label">{ project.name }</label>
													    </div>
													    { project.match && project.houses.length > 0 &&
															<div className="ms-4 mb-3 mt-2">
																{project.houses.map((house) => (
																    <div className="form-check" key={house.id}>
																    	<input
																    		className="form-check-input"
																    		type="checkbox"
																    		checked={house.match}
																    		name="house"
																    		value={house.id}
																    		onChange={evt => this.updateInputValue(evt, project.id)}
																    	/>
																		<label className="form-check-label">{ house.name }</label>
																    </div>
																))}
															</div>
													    }
												  	</div>
												))}
										</div>
									}	
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5 mt-3"
									onClick={ e => this.saveContact(e) }
								>Änderungen speichern</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-1">
								<GoBack />
							</div>
							<div className="col-10 text-center">
								<h1>Kontakte</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="container contact">
					<h1>
						{ data.title &&
							<span>{ data.title } </span>
						}
						{ data.firstname } { data.lastname }
					</h1>

					{ this.state.userRole == 1 &&
						<div className="row mb-3">
							{ !this.state.edit &&
								<div className="col-12">
									<div
										className="btn btn-block btn-secondary mt-1 mb-1"
										onClick={() => this.editContactView()}
									>Bearbeiten</div>
								</div>
							}
							{ !this.state.edit &&
								<div className="col-12">
									<div
										className="btn btn-block btn-danger mt-1 mb-3 ml-3"
										onClick={() => this.deleteContactConfirm(this.props.params.id)}
									>Löschen</div>
								</div>
							}
						</div>
					}

					<Tiles data={ this.state.tiles } />
					{ data.position &&
						<div className="part first">	
							<div>
								<div className="hl">Typ</div>
								{ data.position }
							</div>
						</div>
					}
					{ data.company || data.address &&
						<div className="part">	
							{ data.company &&
								<div className="hl">Firma</div>
							}
							{ data.company &&
								<div>{ data.company }</div>
							}
							{ data.gewerk &&
								<div>
									<br/>
									<div className="hl">gewerk</div>
									{ data.gewerkname }
								</div>
							}
							{ data.address &&
								<div>
									<br/>
									<div className="hl">Adresse</div>
									{ data.address }
								</div>
							}
							{ data.uid &&
								<div>
									<br/>
									<div className="hl">UID-Nummer</div>
									{ data.uid }
								</div>
							}
							{ data.office &&
								<div>
									<br/>
									<div className="hl">Ansprechperson Bürp</div>
									{ data.office }
								</div>
							}
							{ data.outside &&
								<div>
									<br/>
									<div className="hl">Ansprechperson Baustelle</div>
									{ data.outside }
								</div>
							}
							{ data.payment &&
								<div>
									<br/>
									<div className="hl">Zahlungsmodalitäten</div>
									{ data.payment }
								</div>
							}
						</div>
					}
					{ data.phone &&
						<div className="part">		
							<div className="hl">Telefon</div>
							<div>{ data.phone }</div>
						</div>
					}	
					{ data.birthday &&
						<div className="part">		
							<div className="hl">Geburtstag</div>
							<div>{ data.birthday }</div>
						</div>
					}					

					{ data.email &&
						<div className="part">		
							<div className="hl">E-Mail</div>
							<div><a href={`mailto:${data.email}`}>{ data.email }</a></div>
						</div>
					}	

					{ matches.length > 0 &&
						<div className="part">		
							<div className="hl">Projekte</div>
								{projects.map((project) => (
								  project.match && (
								    <div key={project.id}>
								      {project.name}

								      {project.houses.map((house) => (
								        house.match && (
								          <span key={house.id}>
								            / {house.name}
								          </span>
								        )
								      ))}
								    </div>
								  )
								))}
						</div>
					}						
				</div>
			</>
		);
	}

}

export default withParams(ContactDetails);
