import React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getKeyById, deleteKey, editKey } from '../helper/restapi.js';

import SignatureCanvas from 'react-signature-canvas'


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class KeyDetails extends React.Component {

	constructor(props) {
		super(props);
		this.signatureRef = React.createRef();
		this.state = {
			isReady: false,
			id: '',
			data: [],
			saveData: [],
			userRole: '',
			return: false,
		};
	}

	async getKey(id) {
		const response = await getKeyById(id);
		this.setState({ data: response.data });
		this.setState({ isReady: true });
	}

	componentDidMount() {
		let { id } = this.props.params;
		this.setState({ userRole: this.props.user.user_role });
		this.getKey(id);
	}

	deleteKeyConfirm = (id) => {
		const shouldClose = window.confirm('Möchten Sie den Eintrag löschen?');
		if (shouldClose) {
			this.deleteKey(id);
			this.setState({ isReady: false });
		}
	};

	async deleteKey(id) {
		const response = await deleteKey(id);
		if (response.data.success) {
			window.history.go(-1)
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	returnKey = () => {
		this.setState({ return: true });
	}

	returnKeyBack = () => {
		this.setState({ return: false });
	
		let { id } = this.props.params;
		this.getKey(id);
	}

	getSignature = () => {
		const imageDataUrl = this.signatureRef.current.toDataURL();
		var saveData = this.state.data.data;
		saveData.signature_return = imageDataUrl;

		var m = new Date();
		var dateString = m.getUTCFullYear() + '-' + (m.getUTCMonth()+1) + '-' + m.getUTCDate();

		saveData.date_return = dateString;

		console.log(saveData);

		this.setState({ saveData: saveData })
	};

	async saveKey() {

		this.setState({
			isReady: false,
		})
		
		var keyData = this.state.saveData;

		const response = await editKey(keyData);

		if (response.data.success) {
			let { id } = this.props.params;
			this.getKey(id);
			this.setState({
				return: false,
				isReady: true,
			})
		}
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.return  == true) {
			return (
				<div className="signature-outer">
					<div className="signature text-center pt-5">
						
						<div className="text-center">
							<SignatureCanvas
								ref={this.signatureRef}
								penColor='#0170C1'
    							canvasProps={{width: 300, height: 200, className: 'signature-detail'}}
    							onEnd={this.getSignature}
    						/>
						</div>

						<label className="mb-5">Unterschrift von SPL</label>

						{ this.state.saveData.signature_return &&
							<div className="container mt-0">
								<button
									type="submit"
									className="btn btn-success btn-block mb-0"
									onClick={ e => this.saveKey(e) }
								>Rückgabe bestätigen</button>
							</div>
						}
						<div className="container mt-1">
								<div className="row mb-2">
									<div className="col-12">
										<a
											className="btn btn-danger btn-block"
											onClick={() => this.returnKeyBack()}
										>Abbrechen</a>
									</div>
								</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-1">
								<GoBack />
							</div>
							<div className="col-10 text-center">
								<h1>Schlüssel</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2 className="mb-0">{ data.name }</h2>
						</div>
					</div>
				</div>
				<div className="container ticket-infos mt-2">
					<div className="inner">
						<div className="row">
							<div className="col-6 col-lg-4">
								<strong>Status:</strong>
							</div>
							<div className="col-6 col-lg-8">
								{ !data.date_return &&
									<div className="text-danger">nicht retourniert</div>
								}
								{ data.date_return &&
									<div className="text-success">retourniert</div>
								}
							</div>						
						</div>
						<div className="row">
							<div className="col-6 col-lg-4">
								<strong>Vergeben am:</strong>
							</div>
							<div className="col-6 col-lg-8">
								{ data.date_given }
							</div>						
						</div>
						{ data.date_return &&
							<div className="row">
								<div className="col-6 col-lg-4">
									<strong>Retourniert am:</strong>
								</div>
								<div className="col-6 col-lg-8">
									{ data.date_return }
								</div>						
							</div>
						}
						{ data.projectname &&
							<div className="row">
								<div className="col-6 col-lg-4">
									<strong>Projekt:</strong>
								</div>
								<div className="col-6 col-lg-8">
									{ data.projectname }
								</div>						
							</div>
						}
						{ data.housename &&
							<div className="row">
								<div className="col-6 col-lg-4">
									<strong>Haus:</strong>
								</div>
								<div className="col-6 col-lg-8">
									{ data.housename }
								</div>						
							</div>
						}
						{ data.keys &&
							<div className="row">
								<div className="col-12">
									<strong>Schlüssel:</strong>
								</div>
								<div className="col-12">
									{ data.keys }
								</div>						
							</div>
						}
					</div>
				</div>
				{ data.upload &&
					<div className="container mt-3">
					<img src={ 'https://backend.spl-hausbau.app/' + data.upload } />
					</div>
				}
				{ data.signature_given &&
					<div className="container mt-3">
						<div className="row">
							<div className="col-6">
								<label>Unterschrift { data.name }:</label>
							</div>
							<div className="col-6 text-end">
								<img className="signature-detail" width="300" height="200" src={ data.signature_given } />
							</div>						
						</div>
					</div>
				}
				{ data.signature_return &&
					<div className="container mt-3">
						<div className="row">
							<div className="col-6">
								<label>Unterschrift SPL:</label>
							</div>
							<div className="col-6 text-end">
								<img className="signature-detail" width="300" height="200" src={ data.signature_return } />
							</div>						
						</div>
					</div>
				}

				<div className="container mt-3">
					{ this.state.userRole == 1 && !data.signature_return &&
						<div className="row mb-2">
							<div className="col-12">
								<a
									className="btn btn-success btn-block"
									onClick={() => this.returnKey(this.props.params.id)}
								>Rückgabe bestätigen</a>
							</div>
						</div>
					}
					{ this.state.userRole == 1 &&
						<div className="row mb-4">
							<div className="col-12">
								<a
									className="btn btn-danger btn-block"
									onClick={() => this.deleteKeyConfirm(this.props.params.id)}
								>Eintrag löschen</a>
							</div>
						</div>
					}
				</div>
			</>
		);
	}

}

export default withParams(KeyDetails);
