import { useNavigate } from "react-router-dom";
import { EllipsisHorizontalOutline } from 'react-ionicons'

export function HeaderLogo() {
	const navigate = useNavigate();
	return (
		<div id="header" className="sticky-top">
			<div className="container">
				<div className="row">	
					<div className="col-12 text-center logo">
						<img
							src="icon/logo.svg"
							onClick={() => {
								navigate('/');
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export function HeaderLoading() {
	return (
		<>
		<div id="header" className="sticky-top">
			<div className="container">
				<div className="row">	
					<div className="col-12 text-center">
						<h1 style={{ opacity: 0 }}>Lade</h1>
					</div>
				</div>
			</div>
		</div>
		<div className="loading text-center">
			<EllipsisHorizontalOutline
				color={'#007CC5'} 
				beat 
				height="80px"
				width="80px"
			/>
		</div>
		</>
	);
}

export function Loading() {
	return (
		<>
		<div className="loading text-center">
			<EllipsisHorizontalOutline
				color={'#007CC5'} 
				beat 
				height="80px"
				width="80px"
			/>
		</div>
		</>
	);
}

export function GoBack() {
	const navigate = useNavigate();

	return (
		<img
			className="back"
			src="/icon/back.svg"
			onClick={() => {
				navigate(-1);
			}}
		/>
	);
}

export function GoBackDel() {
	const navigate = useNavigate();

	navigate(-1);
}
