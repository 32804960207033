import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { ShareOutline } from 'react-ionicons';

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getFilesApi, getFoldersApi, addNewFile, editFileById, updateFile } from '../helper/restapi.js';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

function Folders(props) {	
	return (
		<div className="row folders">
			<Folder props={ props } />
		</div>
	);
}

function Folder(props) {
	var folders = props.props.data;
	var i = 0;

	const idsToRemove = [1, 11, 12];

	const newFolders = folders.filter(item => !idsToRemove.includes(item.id));

	const items = newFolders.map((item) =>
		<div 
			key={i++} 
			className="folder col-6 col-md-4 col-lg-3"
			onClick={() => props.props.changeFolder(item.id, item.name)}
		>
			<div className="inner">
				<div className="inner-inner">	
					<div className="title">
						{ item.name }
					</div>
				</div>
			</div>
		</div>
	);
	return items;
}

function FileList(data, user) {
	return (
		<div className="row files">
			<File data={ data.data } user={ data.user } />
		</div>
	);
}

function shareFile(item) {
	var text = 'Hier finden Sie die Datei "' + item.title + '" zum Projekt ' + item.projectname.name;
	if (navigator.share) {
	    navigator.share({
	        title: 'Datei: ' + item.title,
	        text: text,
	        url: item.fullpath
	    })
	}
}

function File(data) {
	var files = data.data;
	var i = 0;

	const userRole = data.user;

	const items = files.map((item) =>
		<div
			key={i++}
			className="file col-12"
		>
			
			<div className="inner">
				<div className="row">
					
					<div className="col-12 col-md-8 mb-2 mb-md-0">
						<div className="col-12">
							<div className="row">
								<div className="col-12">
									<div className="title">
										{ item.title }
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 comment">
							<div className="row">
								<div className="col-3">
									Typ:
								</div>
								<div className="col-9">
									 <span className="text-uppercase">{ item.path.split('.').pop() }</span>
								</div>
							</div>
						</div>
						<div className="col-12 folder">
							<div className="row">
								<div className="col-3">
									Ordner:
								</div>
								<div className="col-9">
									 { item.foldername }
								</div>
							</div>
						</div>
						<div className="col-12 date">
							<div className="row">
								<div className="col-3">
									Datum:
								</div>
								<div className="col-9">
									 { item.date }
								</div>
							</div>
						</div>
						{ item.deadline &&
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Deadline:
									</div>
									<div className="col-9">
										 { item.deadline }
									</div>
								</div>
							</div>
						}
						<div className="col-12 user">
							<div className="row">
								<div className="col-3">
									Benutzer:
								</div>
								<div className="col-9">
									 { item.username }
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 text-md-end">
						<div className="icons">
							<Link className="btn btn-outline-primary btn-sm" to={item.path.split('.').pop() == 'pdf' ? `/files/pdf/${item.id}` : item.fullpath}>Detailansicht</Link>
							{ navigator.share &&
								<div
									className="share d-inline-block"
									onClick={() => shareFile(item)}
								>
									<img src="/icon/share.svg" />
								</div>
							}
							{ item.in_id &&
								<Link to={`/invoice/${item.in_id}`}>
									Detailansicht
								</Link>
							}
							{ !item.in_id &&
								<a className="btn btn-outline-primary btn-sm ms-1" target="_blank" href={ item.fullpath }>
									Nur öffnen
								</a>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return items;
}

class Files extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			message: '',
			term: '',
			project: '',
			projectName: '',
			house: '',
			houseName: '',
			folders: [],
			files: [],
			currentFolder: '',
			add: false,
			typeSet: '',
			filters: {
				user: null,
				project: null,
				house: null,
				title: null,
				comment: null,
				folder: null,
			},
			userId: '',
			userRole: '',
			userFirstname: '',
			plantypes: [],
			addFile: {
				user: '',
				project: '',
				house: '',
				title: '',
				comment: '',
				folder: 1,
				plantype: '',
				deadline: '',
			},
			filterdata: [],
			filter: false,
		};
		this.changeFolder = this.changeFolder.bind(this);
	}

	changeFolder(folder, foldername) {
		this.setState({ currentFolder: foldername})
		this.setState({
			filters: {
				...this.state.filters,
				'folder': folder
			}
		}, () => {
			this.getFiles();
		});		
	}

	resetFolder() {
		this.setState({ currentFolder: ''})
		this.setState({
			filters: {
				...this.state.filters,
				'folder': null
			}
		}, () => {
			this.getFiles();
		});		
	}

	async getFiles() {
		const response = await getFilesApi(this.state.filters);

		if (response.data.housename) {
			this.setState({ houseName: response.data.housename });
		}
		this.setState({ files: response.data.data }, () => {});
		this.setState({ plantypes: response.data.plantypes });
		this.setState({ isReady: true });
		this.setState({ projectName: response.data.projectname });
	}

	async getFolders() {
		const response = await getFoldersApi();

		if (this.state.typeSet) {
			var filterType = '';
			if (this.state.typeSet == 'anbote') {
				var folderId = 11;
			}
			if (this.state.typeSet == 'auftraege') {
				var folderId = 12;
			}
			var data = response.data.data;
			const filteredData = data.filter(item => item.id === folderId);

			this.setState({
				addFile: {
					...this.state.addFile,
					'folder':  folderId
				}
			});

			this.setState({ folders: filteredData }, () => {});
		} else {
			this.setState({ folders: response.data.data }, () => {});
		}
		
	}

	addFile = () => {
		this.setState({ add: true });
	}

	addFileBack = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'file') {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]:  evt.target.files[0]
				}
			});
		} else {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]: val
				}
			});			
		}

	}

	saveFile = () => {

		var user = this.state.userId;
		var project = this.state.project;
		var house = this.state.house;

		var fileData = this.state.addFile;

		if (fileData.title && fileData.folder && fileData.file) {
			this.setState({ isReady: false });
			this.addFileData(user, project, house, fileData);
		} else {
			alert('Bitte Titel und Ordner wählen!');
		}
	}

	async addFileData(user, project, house, fileData) {
		const response = await addNewFile(user, project, house, fileData);

		this.setState({ isReady: true });

		if (response.data.success) {
			this.setState({ add: false });
			this.setState({ isReady: false });
			this.getFiles();
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: false });
		}
	}
	
	componentDidMount() {

		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		var fileType = '';
		if (this.props.type) {
			fileType = this.props.type;
		}

		this.getFolders();

		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');

			if (checkProjectHouse[1] == 'projects' && checkProjectHouse[3] == 'houses') {
				var project = checkProjectHouse[2];
				this.setState({ project: project });

				var house = checkProjectHouse[4];
				this.setState({ house: house })

				this.setState({
					filters: {
						...this.state.filters,
						'project': checkProjectHouse[2],
						'house': checkProjectHouse[4],
					}
				}, () => {
					this.getFiles();
				});

			} else if (checkProjectHouse[1] == 'projects') {
				var project = checkProjectHouse[2];
				this.setState({ project: project })

				if (fileType) {
					this.setState({typeSet: fileType})
					if (fileType == 'anbote') {
						var folderId = 11;
					}
					if (fileType == 'auftraege') {
						var folderId = 12;
					}
					this.setState({
						filters: {
							...this.state.filters,
							'project': checkProjectHouse[2],
							'folder': folderId,
						}
					}, () => {
						this.getFiles();
					});					
				} else {
					this.setState({
						filters: {
							...this.state.filters,
							'project': checkProjectHouse[2]
						}
					}, () => {
						this.getFiles();
					});						
				}


			}

		} else {
			this.getFiles();
		}
	}

	searchDocs = (event) => {
		var searchValue = event.target.value;
		searchValue = searchValue.toLowerCase();
		var originalData = this.state.files;

		if (searchValue.length > 0) {
			this.setState({ filter: true });
			var flattenedObjects = originalData;
			var filteredData = flattenedObjects;
			this.setState({ filterdata: filteredData });	

			var filtered = [];

			for(var i=0; i < flattenedObjects.length; i++) {

				var searchIn = flattenedObjects[i]['title'] + ' ' + flattenedObjects[i]['description'] + ' ' + flattenedObjects[i]['projectname'] + ' ' + flattenedObjects[i]['aname'] + ' ' + flattenedObjects[i]['aname2'];
				searchIn = searchIn.toLowerCase();

				if(searchIn.indexOf(searchValue)!=-1) {
					filtered.push(flattenedObjects[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
			}

			this.setState({ filterdata: filtered });			
		} else {
			this.setState({ filterdata: [] });	
			this.setState({ filter: false });	
			this.setState({ message: '' });
		}

	}

	render() {

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								{ !this.state.filters.folder && !this.state.add &&
									<GoBack />
								}
								{ this.state.typeSet && !this.state.add &&
									<GoBack />
								}
								{ this.state.filters.folder && !this.state.typeSet &&
									<img
										className="back"
										src="/icon/back.svg"
										onClick={() => {
											this.resetFolder()
										}}
									/>
								}
								{ this.state.add &&
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addFileBack}
									/>
								}
							</div>
							<div className="col-8 text-center">
								{ this.state.currentFolder &&
									<h1>{ this.state.currentFolder }</h1>
								}
								{ !this.state.currentFolder && !this.state.typeSet &&
									<h1>Dokumente</h1>
								}
								{ this.state.typeSet == 'auftraege' &&
									<h1>Aufträge</h1>
								}
								{ this.state.typeSet == 'anbote' &&
									<h1>Anbote</h1>
								}
							</div>
							{ this.state.typeSet &&
								<div className="col-2 add-contact text-end">
									{ !this.state.add &&
									<div
										className="add-link"
										onClick={this.addFile}
									>+</div>
									}
								</div>
							}
							{ !this.state.typeSet &&
								<div className="col-2 add-contact text-end">
									{ !this.state.filters.folder && !this.state.add &&
									<div
										className="add-link"
										onClick={this.addFile}
									>+</div>
									}
								</div>
							}
						</div>
					</div>
				</div>
				
				{ !this.state.add &&
					<>

						<div className="search-bar">
							<div className="container">
								<div className="form-group">
									<input
										type="text"
										placeholder="Suchen"
										onKeyUp={this.searchDocs}
									/>
								</div>
							</div>					
						</div>

						{this.state.projectName &&
							<div className="container">
								<div className="row">
									<div className="col-12">
									{ !this.state.typeSet &&
										<h2>Dokumente:
											{ this.state.projectName }
											{ this.state.houseName &&
												<span> | {this.state.houseName }</span>
											}
										</h2>
									}
									{ this.state.typeSet == 'auftraege' &&
										<h2>Aufträge: { this.state.projectName }</h2>
									}
									{ this.state.typeSet == 'anbote' &&
										<h2>Anbote: { this.state.projectName }</h2>
									}
									</div>
								</div>
							</div>
						}

						{ !this.state.filters.folder && this.state.folders && !this.state.typeSet &&
							<div className="container">
								{ !this.state.filter &&
									<>
									<Folders data={ this.state.folders } changeFolder={this.changeFolder} />
									</>
								}
							</div>
						}

						{ this.state.files.length > 0 &&
							<div className="container">
								{ this.state.currentFolder &&
									<h2 className="">{ this.state.currentFolder }</h2>
								}
								{ !this.state.currentFolder &&
									<h2 className="">Alle Dateien</h2>
								}
								
								{ !this.state.filter &&
									<FileList data={ this.state.files } user={this.state.userRole} />
								}
								{ this.state.message && 
									<p className="mt-5 text-center">{ this.state.message }</p>
								}
								{ this.state.filter &&
									<FileList data={ this.state.filterdata } user={this.state.userRole} />
								}
							</div>
						}
						{ this.state.files.length < 1 &&
							<div className="container">
								{ this.state.currentFolder &&
									<h2 className="">{ this.state.currentFolder }</h2>
								}
								{ !this.state.currentFolder &&
									<h2 className="">Alle Dateien</h2>
								}
								
								<p>Keine Dateien in diesem Ordner</p>
							</div>
						}
					</>
				}

				{ this.state.add &&
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Titel</label>
									<input
										type="text"
										name="title"
										value={this.state.addFile.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Kommentar</label>
									<textarea
										type="text"
										name="comment"
										value={this.state.addFile.comment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Ordner</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="folder"
										value={this.state.addFile.folder}
									>
										{this.state.folders.map((option) => (
											<option key={option.id} value={option.id} selected={option.id === this.state.addFile.folder}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
							</div>
							{ this.state.typeSet == 'auftraege' &&
								<div className="col-12">
									<div className="form-group">
										<label>Deadline</label>
										<input
											type="date"
											name="deadline"
											value={this.state.addFile.deadline}
											className="form-control"
											onChange={evt => this.updateInputValue(evt)}
										/>
									</div>
								</div>								
							}
							{ this.state.addFile.folder == 2 &&
								<div className="col-12">
									<div className="form-group">
										<label>Plan</label>
										<select
											className="form-select"
											onChange={evt => this.updateInputValue(evt)}
											name="plantype"
											value={this.state.addFile.plantype}
										>
											<option value="">Plan wählen</option>
											{this.state.plantypes.map((option) => (
												<option key={option.id} value={option.id}>
													{ option.name }
												</option>
											))}
										</select>
									</div>
								</div>
							}
							<input
								id="imageUpload"
								name="file"
								type="file"
								className="mb-4"
								onChange={evt => this.updateInputValue(evt)}
							/>
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveFile(e) }
								>Dokument hochladen</button>
							</div>
						</div>
					</div>
				}
			</>
		);
	}

}

export default withParams(Files);
