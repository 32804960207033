import { Link, useLocation } from "react-router-dom";
import { ReactComponent as IconPhone } from '../../images/phone.svg';
import { ReactComponent as IconDetail } from '../../images/detail.svg';
import { ReactComponent as IconNotes } from '../../images/notes.svg';
import { ReactComponent as IconContacts } from '../../images/contacts.svg';
import { ReactComponent as IconProjects } from '../../images/projects.svg';
import { ReactComponent as IconMail } from '../../images/mail.svg';
import { ReactComponent as IconDocs } from '../../images/documents.svg';
import { ReactComponent as IconBauzeit } from '../../images/bauzeit.svg';
import { ReactComponent as IconKey } from '../../images/key.svg';
import { ReactComponent as IconCal } from '../../images/calendar.svg';


export function Tiles(props) {
	return (
		<div className="row tiles">
			<TilesItem data={ props.data } tickets={ props.tickets } />
		</div>
	);
}

export function TilesItem(data) {

	const location = useLocation();

	var tiles = data.data;

	const items = tiles.map((item) => {
		if (item.title == 'Schreiben') {
			item.route = 'https://www.spl-hausbau.at/?mail=' + item.route;
			item.blank = true;
		}
		return (
			<div key={item.order} className="tile col-6 col-md-4 col-lg-2">
				<Link to={item.route} state={location.pathname} target={item.blank ? '_blank' : undefined}>
				<div className="inner ratio ratio-1x1">
					<div className="inner-inner text-center">	
						<div className="icon">
							<Icon name={item.icon} />
						</div>
						<div className="title">
							{ item.title == 'Schlüssel-Management' &&
								<>
								<div>Schlüssel-</div>
								<div>Management</div>
								</>
							}
							{ item.title !== 'Schlüssel-Management' &&
								<span>{ item.title} </span>
							}
							
						</div>
						{ data.tickets > 0 && item.title == 'Aufgaben' && 
							<div className="nubsi">
								{ data.tickets }
							</div>						
						}
					</div>
				</div>
				</Link>
			</div>
		);
	});
	return items;
}

function Icon(props) {
	const { name } = props;

	const icons = {
		phone: IconPhone,
		detail: IconDetail,
		notes: IconNotes,
		contacts: IconContacts,
		projects: IconProjects,
		mail: IconMail,
		docs: IconDocs,
		bauzeit: IconBauzeit,
		key: IconKey,
		cal: IconCal,
	};

	const IconComponent = icons[name];
	return <IconComponent />;
}



