import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { addPushToken } from '../helper/restapi.js';

const firebaseConfig = {
  apiKey: "AIzaSyDmWrMjFE2lHS8UW1eVwCpNKlY9VqBoCo8",
  authDomain: "spl-hausbau.firebaseapp.com",
  projectId: "spl-hausbau",
  storageBucket: "spl-hausbau.appspot.com",
  messagingSenderId: "49584609166",
  appId: "1:49584609166:web:8efb0db29f05c9f7ce76df"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = (uid) => {
  // Request permission to show notifications

  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // Retrieve the current registration token
        return getToken(messaging, { vapidKey: 'BLfZfN15Qo2WlAhlTQijSh4kvtx_GTtry8aM6ZEV3UFDJPvUpcycLvU1GIo5rxZOcw73r40ebyujTXd7VfQVtYM' })
          .then((currentToken) => {
            if (currentToken) {
              if (currentToken && uid) {
                addPushToken(currentToken, uid);         
              }
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      } else {
        console.log('User denied permission to show notifications.');
      }
    });
  }
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
    resolve(payload);
  });
});