import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { ShareOutline } from 'react-ionicons';

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getPlansApi, getFoldersApi, addNewFile } from '../helper/restapi.js';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

function FileList(data) {
	//console.table(data.data);
	return (
		<div className="row files">
			<File data={ data.data } />
		</div>
	);
}

function shareFile(item) {
	var text = 'Hier finden Sie die Datei "' + item.title + '" zum Projekt ' + item.projectname.name;
	if (navigator.share) {
	    navigator.share({
	        title: 'Datei: ' + item.title,
	        text: text,
	        url: item.fullpath
	    })
	}
}

function File(data) {
	var files = data.data;
	var i = 0;
	const items = files.map((item) =>
		<div
			key={i++}
			className="file col-12"
		>
			<h4>{item.name}</h4>
			{ item.files.length > 0 &&
				<div>Versionen:</div>
			}
			{ item.files.length < 1 &&
				<div>Noch keine Pläne hinterlegt</div>
			}
			
			{Array.isArray(item.files) ? (
				item.files.map((file, index) => (
					<Link className="edit-link" to={(index == 0 && file.path.split('.').pop() == 'pdf') || (index > 0 && file.house !== item.files[index-1].house) ? `/files/pdf/${file.id}` : '#'}>
						<div key={file.id} className="mt-3">
							<div className="row">
								<div className="col-8">{file.date}</div>
								<div className="col-4 text-end">
								{ index == 0 && file.path.split('.').pop() == 'pdf' &&
									<Link className="edit-link" to={`/files/pdf/${file.id}`}>ansehen</Link>
								}
								{ index > 0 && file.house !== item.files[index-1].house &&
									<>
										{ file.path.split('.').pop() == 'pdf' &&
											<Link className="edit-link" to={`/files/pdf/${file.id}`}>ansehen</Link>
										}
									</>
								}
								</div>
							</div>
							
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Typ:
									</div>
									<div className="col-9">
										 <span className="text-uppercase">{ file.path.split('.').pop() }</span>
									</div>
								</div>
							</div>
							<div className="col-12 date">
								<div className="row">
									<div className="col-3">
										Datum:
									</div>
									<div className="col-9">
										 { file.date }
									</div>
								</div>
							</div>
							<div className="col-12 date">
								<div className="row">
									<div className="col-3">
										Haus:
									</div>
									<div className="col-9">
										 { file.housename }
									</div>
								</div>
							</div>
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Kommentar:
									</div>
									<div className="col-9">
										 { file.comment }
									</div>
								</div>
							</div>
							<div className="col-12 user">
								<div className="row">
									<div className="col-3">
										Benutzer:
									</div>
									<div className="col-9">
										 { file.username }
									</div>
								</div>
							</div>
						</div>
					</Link>
				))
			) : (
				<Link className="edit-link" to={item.files.path.split('.').pop() === 'pdf' ? `/files/pdf/${item.files.id}` : '#'}>
					<div key={item.files.id} className="mt-3">
						<div className="row">
							<div className="col-8">{item.files.title} {item.files.housename}</div>

							<div className="col-4 text-end">
									{ item.files.path.split('.').pop() == 'pdf' &&
										<Link className="edit-link" to={`/files/pdf/${item.files.id}`}>ansehen</Link>
									}
							</div>
						</div>

						<div className="col-12 comment">
							<div className="row">
								<div className="col-3">
									Typ:
								</div>
								<div className="col-9">
									 <span className="text-uppercase">{ item.files.path.split('.').pop() }</span>
								</div>
							</div>
						</div>
						<div className="col-12 date">
							<div className="row">
								<div className="col-3">
									Datum:
								</div>
								<div className="col-9">
									 { item.files.date }
								</div>
							</div>
						</div>
						<div className="col-12 comment">
							<div className="row">
								<div className="col-3">
									Kommentar:
								</div>
								<div className="col-9">
									 { item.files.comment }
								</div>
							</div>
						</div>
						<div className="col-12 user">
							<div className="row">
								<div className="col-3">
									Benutzer:
								</div>
								<div className="col-9">
									 { item.files.username }
								</div>
							</div>
						</div>
					</div>
				</Link>
			)}
		</div>
	);
	return items;
}

function FileSingle(data) {
	console.log(data);
}

class Files extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			message: '',
			term: '',
			project: '',
			house: '',
			files: [],
			add: false,
			userId: '',
			userRole: '',
			userFirstname: '',
			plantypes: [],
			projectName: 'a',
			houseName: '',
			addFile: {
				user: '',
				project: '',
				house: '',
				title: '',
				comment: '',
				folder: 2,
				plantype: '',
			},
		};
	}

	async getFiles() {
		const response = await getPlansApi(this.state.filters);

		if (response.data.data.length) {
			var houseName = response.data.data[0].files.housename;
			this.setState({ houseName: houseName });
		}

		this.setState({ files: response.data.data }, () => {});
		this.setState({ plantypes: response.data.plantypes });
		this.setState({ isReady: true });
		this.setState({ projectName: response.data.projectname });

		
	}

	addFile = () => {
		this.setState({ add: true });
	}

	addFileBack = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'file') {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]:  evt.target.files[0]
				}
			});
		} else {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]: val
				}
			});			
		}

	}

	saveFile = () => {

		var user = this.state.userId;
		var project = this.state.project;
		var house = this.state.house;

		var fileData = this.state.addFile;

		if (fileData.title && fileData.folder && fileData.file) {
			this.setState({ isReady: false });
			this.addFileData(user, project, house, fileData);
		} else {
			alert('Bitte Titel und Ordner wählen!');
		}
	}

	async addFileData(user, project, house, fileData) {
		console.log(fileData)
		const response = await addNewFile(user, project, house, fileData);

		this.setState({ isReady: true });

		if (response.data.success) {
			this.setState({ add: false });
			this.setState({ isReady: false });
			this.getFiles();
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: false });
		}
	}
	
	componentDidMount() {

		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');

			if (checkProjectHouse[1] == 'projects' && checkProjectHouse[3] == 'houses') {
				var project = checkProjectHouse[2];
				this.setState({ project: project });

				var house = checkProjectHouse[4];
				this.setState({ house: house })

				this.setState({
					filters: {
						...this.state.filters,
						'project': checkProjectHouse[2],
						'house': checkProjectHouse[4],
					}
				}, () => {
					this.getFiles();
				});

			} else if (checkProjectHouse[1] == 'projects') {
				var project = checkProjectHouse[2];
				this.setState({ project: project })
				this.setState({
					filters: {
						...this.state.filters,
						'project': checkProjectHouse[2]
					}
				}, () => {
					this.getFiles();
				});

			}

		} else {
			this.getFiles();
		}
	}

	render() {

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								{ !this.state.filters.folder && !this.state.add &&
									<GoBack />
								}
								{ this.state.filters.folder &&
									<img
										className="back"
										src="/icon/back.svg"
										onClick={() => {
											this.resetFolder()
										}}
									/>
								}
								{ this.state.add &&
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addFileBack}
									/>
								}
							</div>
							<div className="col-8 text-center">
								{ this.state.currentFolder &&
									<h1>{ this.state.currentFolder }</h1>
								}
								{ !this.state.currentFolder &&
									<h1>Pläne</h1>
								}
							</div>
							<div className="col-2 add-contact text-end">
								{ !this.state.filters.folder && !this.state.add &&
								<div
									className="add-link"
									onClick={this.addFile}
								>+</div>
								}
							</div>
						</div>
					</div>
				</div>
				
				{ !this.state.add &&
					<>
						{this.state.projectName &&
							<div className="container">
								<div className="row">
									<div className="col-12">
										<h2>
											Pläne: { this.state.projectName }
											{ this.state.houseName &&
												<span>| { this.state.houseName }</span>
											}
										</h2>
									</div>
								</div>
							</div>
						}
						{ this.state.files.length > 0 &&
							<div className="container">
								<FileList data={ this.state.files } />
							</div>
						}
					</>
				}

				{ this.state.add &&
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Titel</label>
									<input
										type="text"
										name="title"
										value={this.state.addFile.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Kommentar</label>
									<textarea
										type="text"
										name="comment"
										value={this.state.addFile.comment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Plan</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="plantype"
										value={this.state.addFile.plantype}
									>
										<option value="">Plan wählen</option>
										{this.state.plantypes.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name }
											</option>
										))}
									</select>
								</div>
							</div>
							<input
								id="imageUpload"
								name="file"
								type="file"
								className="mb-4"
								onChange={evt => this.updateInputValue(evt)}
							/>
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveFile(e) }
								>Dokument hochladen</button>
							</div>
						</div>
					</div>
				}
			</>
		);
	}

}

export default withParams(Files);
