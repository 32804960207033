import axios from 'axios';
import CircularJSON from 'circular-json';

//var url = 'https://spl-app.dev/';
var url = 'https://backend.spl-hausbau.app/';

const config = {
  headers: {
    'Content-Type': 'multipart/form-data'
    //'Content-Type': 'audio/webm'
  }
};

export const handleLogin = async (email, password) => {

	var endpoint = 'user-login';
	var endpoint = url + endpoint;

	var data = new FormData();
	data.append('username', email);
	data.append('password', password);

	try {
		const response = await axios.post(endpoint, data, config);
		var token = response.data.token;
		localStorage.setItem('token', token);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const checkToken = async () => {

	var endpoint = 'user-validate';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const updatePw = async (newPw) => {

	var endpoint = 'pw';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('pw', newPw);
	
	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const addPushToken = async (firebase, uid) => {

	var endpoint = 'add-firebase-token';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('firebase', firebase);
	data.append('uid', uid);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getAllContacts = async () => {

	var endpoint = 'contacts';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');
	var data = [];

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getContacts = async (project, house) => {

	var endpoint = 'contacts';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (project) {
		data.append('project', project);
	}

	if (house) {
		data.append('house', house);
	}
	
	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const addNewContact = async (contactData, project, house) => {

	var endpoint = 'contact-add';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	var contactData = JSON.stringify(contactData);
	data.append('contactdata', contactData);

	if (project) {
		data.append('project', project);
	}

	if (house) {
		data.append('house', house);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const editContact = async (contactData) => {

	var endpoint = 'contact-edit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	var contactData = JSON.stringify(contactData);
	data.append('contactdata', contactData);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};


export const editMatches = async (contact, matchesArray) => {

	var endpoint = 'matches-edit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	data.append('contact', contact);

	var matchesArray = JSON.stringify(matchesArray);
	data.append('matches', matchesArray);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const deleteContact = async (id) => {

	var endpoint = 'contact-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getContactById = async (id) => {

	var endpoint = 'contacts/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getKeys = async (project, house, state) => {

	var endpoint = 'keys';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (project) {
		data.append('project', project);
	}

	if (house) {
		data.append('house', house);
	}

	if (state) {
		data.append('state', state);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const addKey = async (keyData) => {

	var endpoint = 'keys-add';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;	

	var data = new FormData();

	if (keyData.upload) {
		data.append('upload', keyData.upload);
	}

	var keyData = JSON.stringify(keyData);

	data.append('keydata', keyData);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getKeyById = async (id) => {

	var endpoint = 'keys/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const deleteKey= async (id) => {

	var endpoint = 'keys-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const editKey = async (keyData) => {

	var endpoint = 'keys-edit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	var keyData = JSON.stringify(keyData);

	data.append('keydata', keyData);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getAllProjects = async () => {

	var endpoint = 'projects';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getProjects = async (filter) => {

	var endpoint = 'projects';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	for (const [key, value] of Object.entries(filter)) {
		if (value) {
			data.append(key, value);
		}
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const addNewProject = async (projectData) => {
	var endpoint = 'project-add';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	var projectData = JSON.stringify(projectData);
	data.append('projectdata', projectData);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const editProject = async (projectData) => {
	var endpoint = 'project-edit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	var projectData = JSON.stringify(projectData);
	data.append('projectdata', projectData);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const editHouse = async (newHouseName, newHouseStreet, newHouseZip, newHouseCity, houseId) => {
	var endpoint = 'house-edit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	var houseData = JSON.stringify(houseData);

	data.append('name', newHouseName);
	data.append('street', newHouseStreet);
	data.append('plz', newHouseZip);
	data.append('city', newHouseCity);
	data.append('id', houseId);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const getProjectById = async (id) => {

	var endpoint = 'projects/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const addNewHouseApi = async (newHouseName, newHouseStreet, newHouseZip, newHouseCity, projectId) => {

	var endpoint = 'house-add';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('name', newHouseName);
	data.append('street', newHouseStreet);
	data.append('zip', newHouseZip);
	data.append('city', newHouseCity);
	data.append('pid', projectId);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const deleteHouse = async (id) => {

	var endpoint = 'house-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};


export const deleteProject = async (id) => {

	var endpoint = 'project-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};


export const getNotesByUserId = async () => {

	var endpoint = 'notes';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getNotesByProjectId = async (id) => {

	var endpoint = 'projects/' + id + '/notes';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getBauzeitPlan = async (id) => {
	var endpoint = 'projects/' + id + '/bauzeit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const getUpdates = async (user, project, house, title) => {

	var endpoint = 'updates-search';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (user) {
		data.append('user', user);
	}
	
	if (project) {
		data.append('project', project);
	}

	if (house) {
		data.append('house', house);
	}

	if (title) {
		data.append('title', title);
	}
	
	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	

}

export const addNewUpdate = async (project, house, title, text, files) => {

	var endpoint = 'updates';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('type', 'image');
	data.append('project', project);
	data.append('house', house);
	data.append('title', title);
	data.append('text', text);

	for (let i = 0; i < files.length; i++) {
		data.append('file[]', files[i]);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
	
}

export const deleteUpdate = async (id) => {
	var endpoint = 'update-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const addRead = async (id) => {
	var endpoint = 'update-add-read';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const addUpdateComment = async (update, comment) => {
	var endpoint = 'update-add-comment';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('update', update);
	data.append('comment', comment);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}



export const getNotesByHouseId = async (id) => {

	var endpoint = 'houses/' + id + '/notes';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const addNote = async (text, type, userId, project, house, blob, note) => {

	var endpoint = 'notes';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('text', text);
	data.append('file', blob);
	data.append('type', type);
	data.append('userId', userId);
	data.append('project', project);
	data.append('house', house);
	data.append('note', note);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const deleteNote = async(noteId) => {
	var endpoint = 'delete-note';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', noteId);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const getFilesApi = async(filters) => {

	var endpoint = 'files';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	for (const [key, value] of Object.entries(filters)) {
		if (value) {
			data.append(key, value);
		}
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const getPlansApi = async(filters) => {

	var endpoint = 'plans';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	for (const [key, value] of Object.entries(filters)) {
		if (value) {
			data.append(key, value);
		}
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const getFilesById = async (id) => {

	var endpoint = 'file/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const editFileById = async(id, title) => {

	var endpoint = 'file-edit/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (title) {
		data.append('title', title);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	

};

export const deleteFileById = async(id) => {

	var endpoint = 'file-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (id) {
		data.append('id', id);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	

};



export const getInvoiceById = async (id) => {

	var endpoint = 'invoice/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const saveDraw = async(id, draw) => {
	var endpoint = 'invoice-draw/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();	

	data.append('draw', draw);

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const updateInvoice = async(id, state, split, logs) => {

	var endpoint = 'invoice/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (state) {
		data.append('state', state);
	}

	if (split) {
		var split = JSON.stringify(split);
		data.append('split', split);
	}	

	if (logs) {
		data.append('log', logs);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const updateFile = async(id, comments) => {

	var endpoint = 'file/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	data.append('comments', comments);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const updatePlanPosition = async(id, plan, plancoords) => {

	var endpoint = 'ticketposition/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	data.append('plan', plan);
	data.append('plancoords', plancoords);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const getFoldersApi = async() => {

	var endpoint = 'folders';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

export const addNewFile = async(user, project, house, fileData) => {
	var endpoint = 'add-file';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	data.append('user', user);

	if (project) {
		data.append('project', project);
	}
	if (house) {
		data.append('house', house);
	}

	for (const [key, value] of Object.entries(fileData)) {
		if (value) {
			data.append(key, value);
		}
	}

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	
}


export const getCoordinates = async(address) => {

	try {
		const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${address}&format=json`);
		const { lat, lon } = response.data[0];
		const coordinates = [];
		coordinates.push({
			'lat': lat,
			'lon': lon
		})
		return coordinates;
	} catch (error) {
		return error.response;
	}
}

export const getCalEntries = async(user, type) => {
	var endpoint = 'calendar';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;	

	var data = new FormData();
	if (user) {
		data.append('user', user);
	}
	
	if (type) {
		data.append('type', type);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	

}

export const updateCalEntry = async(id, title, user, start, end, type, comment, project, house) => {
	var endpoint = 'calendar/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;	

	var data = new FormData();
	data.append('title', title);
	data.append('user', user);
	data.append('start', start);
	data.append('end', end);
	data.append('type', type);
	data.append('comment', comment);
	data.append('project', project);
	data.append('house', house);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	

}

export const addCalEntry = async(title, user, start, end, type, comment, project, house) => {
	var endpoint = 'calendar-add';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;	

	var data = new FormData();
	data.append('title', title);
	data.append('user', user);
	data.append('start', start);
	data.append('end', end);
	data.append('type', type);	
	data.append('comment', comment);
	data.append('project', project);
	data.append('house', house);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	

}

export const deleteCalEntry = async (id) => {

	var endpoint = 'calendar-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getTickets = async(title, cid, aid, state, project, house) => {
	var endpoint = 'tickets';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	if (title) {
		data.append('title', title);
	}
	
	if (cid) {
		data.append('cid', cid);
	}
	
	if (aid) {
		data.append('aid', aid);
	}
	
	if (state) {
		data.append('state', state);
	}

	if (project) {
		data.append('project', project);
	}
		
	if (house) {
		data.append('house', house);
	}
	
	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}


export const deleteTicket = async (id) => {

	var endpoint = 'ticket-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const updateComment = async (id, readby) => {
	var endpoint = 'update-comment';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);
	data.append('readby', readby);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const deleteInvoice = async (id) => {

	var endpoint = 'invoice-remove';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

// get ticket
export const getTicketById = async(id) => {
	var endpoint = 'tickets/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;
	
	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}

// update ticket
export const updateTicket = async(id, title, description, aid, file, state, deadline, done) => {
	var endpoint = 'tickets/' + id;
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	if (title) {
		data.append('title', title);
	}
	if (description) {
		data.append('description', description);
	}
	if (aid) {
		data.append('aid', aid);
	}
	if (file) {
		data.append('file', file);
	}
	if (state) {
		data.append('state', state);
	}
	if (deadline) {
		data.append('deadline', deadline);
	}
	if (done) {
		data.append('done', done);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}		
}

export const updateTodos = async(id, todos) => {
	var endpoint = 'tickets-todos/' + id;
	var endpoint = url + endpoint;

	var todos = JSON.stringify(todos);

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('todos', todos);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
}

// add comment
export const saveCommentData = async(ticket, user, comment) => {
	var endpoint = 'comment';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('ticket', ticket);
	data.append('user', user);
	data.append('comment', comment);

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	
}

// add comment to invoice
export const saveCommentDataIn = async(ticket, user, comment) => {
	var endpoint = 'comment-invoice';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('ticket', ticket);
	data.append('user', user);
	data.append('comment', comment);

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	
}

export const updateCommentIn = async (id, readby) => {
	var endpoint = 'update-comment-invoice';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('id', id);
	data.append('readby', readby);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}
};

// add ticket
export const addNewTicket = async(ticketData) => {
	var endpoint = 'tickets-add';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();

	for (const [key, value] of Object.entries(ticketData)) {
		if (value) {
			console.log(key);
			console.log(value);
			data.append(key, value);
		}
	}

	try {
		const response = await axios.post(endpoint, data, config);
		console.log(response);
		return response;
	} catch (error) {
		return error.response;
	}	
}

// edit bauzeit
export const editBauzeit = async(project, bzData) => {

	var endpoint = 'edit-bauzeit';
	var endpoint = url + endpoint;

	var token  = localStorage.getItem('token');

	config.headers.Authorization = `Bearer ${token}`;

	var data = new FormData();
	data.append('project', project);
	var bzData = JSON.stringify(bzData);
	data.append('data', bzData);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}	
}
