import React from 'react';
import ReactDOM from 'react-dom';
import { useParams, useLocation } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getProjectById, addNewHouseApi, deleteProject, getCoordinates, editProject, getTickets } from '../helper/restapi.js';

import { Tiles } from "./Parts/Tiles.js";
import { HouseList } from "./Parts/Projects.js";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}

class ProjectDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			id: '',
			data: [],
			tiles: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			location: '',
			houses: [],
			add: false,
			newHouseName: '',
			newHouseStreet: '',
			newHouseZip: '',
			newHouseCity: '',
			edit: false,
			possibleStates: [],
			editData: [],
			openTickets: 0,
		};
	}

	async getTickets(title, cid, aid, state, project, house) {
		const response = await getTickets(title, cid, aid, state, project, house);
		const openTickets = response.data.data[1].length + response.data.data[2].length;
		this.setState({ openTickets: openTickets });
	}

	async getProject(id) {
		const response = await getProjectById(id);

		this.setState({ data: response.data });
		this.setState({ editData: response.data.data });
		this.setState({ houses: response.data.data.houses });
		this.setState({ possibleStates: response.data.data.states.data });

		this.setState({ newHouseZip: response.data.data.plz });
		this.setState({ newHouseCity: response.data.data.city });

		const tiles = [
			// {
			// 	'order': 2,
			// 	'title': 'Aufgaben',
			// 	'icon': 'docs',
			// 	'route': `/projects/${id}/tickets`,
			// },
			{
				'order': 5,
				'title': 'Kontakte',
				'icon': 'contacts',
				'route': `/projects/${id}/contacts`,
			},
			{
				'order': 3,
				'title': 'Rechnungen',
				'icon': 'notes',
				'route': `/projects/${id}/invoices`,
			},
			{
				'order': 8,
				'title': 'Anbote',
				'icon': 'docs',
				'route': `/projects/${id}/filesanbote`,
			},
			{
				'order': 9,
				'title': 'Aufträge',
				'icon': 'docs',
				'route': `/projects/${id}/filesauftraege`,
			},
			// {
			// 	'order': 4,
			// 	'title': 'Pläne',
			// 	'icon': 'docs',
			// 	'route': `/projects/${id}/plans`,
			// },
			{
				'order': 7,
				'title': 'Bauzeitplan',
				'icon': 'bauzeit',
				'route': `/projects/${id}/bauzeitplan`,
			},
			// {
			// 	'order': 6,
			// 	'title': 'Dokumentation',
			// 	'icon': 'notes',
			// 	'route': `/projects/${id}/notes`,
			// },
		];

		this.setState({ tiles: tiles});
		this.setState({ isReady: true });
	}

	triggerAddHouse = () => {
		this.setState({ add: true });
	}

	triggerAddHouseOut = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'name') {
			this.setState({ newHouseName: val });
		}

		if (name == 'street') {
			this.setState({ newHouseStreet: val });
		}

		if (name == 'zip') {
			this.setState({ newHouseZip: val });
		}

		if (name == 'city') {
			this.setState({ newHouseCity: val });
		}
		
	}
	
	saveHouse = () => {
		var newHouseName = this.state.newHouseName;
		var newHouseStreet = this.state.newHouseStreet;
		var newHouseZip = this.state.newHouseZip;
		var newHouseCity = this.state.newHouseCity;

		var projectId = this.state.data.data.id;
		if (newHouseName && projectId) {
			this.setState({ isReady: false });
			this.addNewHouse(newHouseName, newHouseStreet, newHouseZip, newHouseCity, projectId);
		} else {
			alert('Bitte alle Felder ausfüllen!');
		}
	}

	async addNewHouse(newHouseName, newHouseStreet, newHouseZip, newHouseCity, projectId) {
		const response = await addNewHouseApi(newHouseName, newHouseStreet, newHouseZip, newHouseCity, projectId);
		if (response.data.success) {
			this.setState({ add: false });
			this.getProject(projectId);
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	componentDidMount() {
		let { id } = this.props.params;
		this.getProject(id);
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ location: this.props.location.state });

		this.getTickets(null, null, this.props.user.user_id, null, id, null);
	}

	editConfirm = (id) => {
		this.setState({ edit: true });
	}

	updateEditValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({
			editData: {
				...this.state.editData,
				[name]: val
			}
		});
	}

	saveProject = () => {
		var projectData = this.state.editData;

		if (projectData.name && projectData.plz && projectData.city && projectData.company) {
			this.setState({ isReady: false });
			this.getCoordinates(projectData.plz + ' ' + projectData.city).then(coordinates => {
				projectData.lat = coordinates[0].lat;
				projectData.lon = coordinates[0].lon;
				this.editProjectData(projectData);
			});
		} else {
			alert('Bitte alle Felder ausfüllen!');
		}
	}

	async editProjectData(projectData) {
		delete projectData.houses;
		delete projectData.image;

		if (typeof projectData.state !== 'string') {
			projectData.state = projectData.state.id;
		}

		const response = await editProject(projectData);

		if (response.data.success) {
			this.setState({ add: false });
			this.getProject(this.props.params.id);
			this.setState({ isReady: true });
			this.setState({ edit: false });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	async getCoordinates(address) {
		const response = await getCoordinates(address);	
		return response;
	}

	deleteProjectConfirm = (id) => {
		const shouldClose = window.confirm('Möchten Sie das Haus löschen?');
		if (shouldClose) {
			this.deleteProject(id);
			this.setState({ isReady: false });
		}
	};

	async deleteProject(id) {
		const response = await deleteProject(id);
		if (response.data.success) {
			window.history.go(-1)
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.add) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.triggerAddHouseOut}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Haus hinzufügen</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Name</label>
									<input
										type="text"
										name="name"
										value={this.state.newHouseName}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Straße</label>
									<input
										type="text"
										name="street"
										value={this.state.newHouseStreet}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>PLZ</label>
									<input
										type="text"
										name="zip"
										value={this.state.newHouseZip}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ort</label>
									<input
										type="text"
										name="city"
										value={this.state.newHouseCity}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveHouse(e) }
								>Haus erstellen</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-1">
								<GoBack />
							</div>
							<div className="col-10 text-center">
								<h1>Projekt</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>{ data.name }</h2>
						</div>
						<div className="col-12">
							{ this.state.userRole == 1 &&
								<div className="row">
									{ !this.state.edit &&
										<div className="col-12">
											<div
												className="btn btn-block btn-secondary mt-1 mb-1"
												onClick={() => this.editConfirm(this.props.params.id)}
											>Bearbeiten</div>
										</div>
									}
									{ !this.state.edit &&
										<div className="col-12">
											<div
												className="btn btn-block btn-danger mt-1 mb-3 ml-3"
												onClick={() => this.deleteProjectConfirm(this.props.params.id)}
											>Löschen</div>
										</div>
									}
								</div>
							}
						</div>
					</div>
					{ this.state.edit &&
						<div className="row">
							<div className="form-group">
								<label>Name</label>
								<input
									type="text"
									name="name"
									value={this.state.editData.name}
									className="form-control"
									onChange={evt => this.updateEditValue(evt)}
								/>
							</div>
							<div className="form-group">
								<label>Straße</label>
								<input
									type="text"
									name="street"
									value={this.state.editData.street}
									className="form-control"
									onChange={evt => this.updateEditValue(evt)}
								/>
							</div>
							<div className="form-group">
								<label>PLZ</label>
								<input
									type="number"
									name="plz"
									value={this.state.editData.plz}
									className="form-control"
									onChange={evt => this.updateEditValue(evt)}
								/>
							</div>
							<div className="form-group">
								<label>Ort</label>
								<input
									type="text"
									name="city"
									value={this.state.editData.city}
									className="form-control"
									onChange={evt => this.updateEditValue(evt)}
								/>
							</div>
							<div className="form-group">
								<label>GST-NR</label>
								<input
									type="text"
									name="gstnr"
									value={this.state.editData.gstnr}
									className="form-control"
									onChange={evt => this.updateEditValue(evt)}
								/>
							</div>
							<div className="form-group">
								<label>Firma</label>
								<select
									className="form-select"
									onChange={evt => this.updateEditValue(evt)}
									name="company"
									value={this.state.editData.company}
								>
									<option value="">Auswählen</option>
									<option value="1">SPL Hausbau</option>
									<option value="2">SPL Terrassenberg</option>
									<option value="3">SPL Gartengasse</option>
									<option value="4">SPL Projektgesellschaft</option>
									<option value="5">SP Liegenschaftsverwaltung "Felixdorf"</option>
									<option value="6">SP Vermögensverwaltung GmbH</option>
									<option value="7">SP Liegenschaftsverwaltung "Felixdorf"</option>
									<option value="8">SP Vermögensverwaltung GmbH</option>
									<option value="9">Dr. Schnabl & Partner OG</option>
									<option value="10">Dr. Norbert Schnabl</option>
									<option value="11">Roland Pataki</option>
								</select>
							</div>
							<div className="form-group">
								<label>Status</label>
								<select
									className="form-select"
									onChange={evt => this.updateEditValue(evt)}
									name="state"
									value={this.state.editData.state.id}
								>
									{this.state.possibleStates.map((option) => (
										<option key={option.id} value={option.id} selected={option.id == this.state.editData.state.id}>
											{ option.name}
										</option>
									))}
								</select>
							</div>
							<div className="col-12">
							<button
								type="submit"
								className="btn btn-primary btn-block mb-5"
								onClick={ e => this.saveProject(e) }
							>Projekt speichern</button>
							</div>
						</div>
					}
					{ !this.state.edit &&
						<Tiles data={ this.state.tiles } tickets={ this.state.openTickets } />
					}
				</div>
				{ !this.state.edit &&
					<div className="container mt-4">
						<div className="row align-items-center">
							<div className="col-10">
								<h2 className="mb-0">Einzenle Häuser</h2>
							</div>
							<div className="col-2 text-end">
								<img
									className="add-house"
									src="/icon/add.svg"
									onClick={this.triggerAddHouse}
								/>
							</div>
						</div>
						
						{ this.state.houses.length < 1 &&
							<p>Es wurden noch keine Häuser angelegt.</p>
						}
						{ this.state.houses &&
							<HouseList data={ this.state.houses } />
						}
					</div>
				}
			</>
		);
	}

}

export default withParams(ProjectDetails);
