import React from 'react';
import ReactDOM from 'react-dom';
import { updatePw } from '../helper/restapi.js';


import { HeaderLogo } from "./Parts/Header.js";

class User extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			changePw: false,
			newPw: '',
		};
	}

	componentDidMount() {
		this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
	}

	changePw = () => {
		this.setState({ changePw: true });
	}

	changePwBack = () => {
		this.setState({ changePw: false });
	}

	updateInputValue(evt) {
		const val = evt.target.value;
		this.setState({ newPw: val })
	}

	logout() {
		localStorage.clear();
		window.location.href = "/";
	}

	async setPw() {
		this.setState({ isReady: false });
		const response = await updatePw(this.state.newPw);
		if (response.status == 200) {
			this.setState({ isReady: true });
			this.setState({ changePw: false });
		}
		
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLogo />
			);
		}

		if (this.state.changePw) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.changePwBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Bearbeiten</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4">
						<div className="col-12 form-group">
						<label>Neues Passwort</label>
							<input 
								onChange={evt => this.updateInputValue(evt)}
								className="form-control"
								type="password"
								name="password"
								value={this.state.newPw}
							/>
						</div>
						<button
							type="submit"
							className="btn btn-success btn-block"
							onClick={ e => this.setPw() }
						>Passwort speichern</button>
					</div>
				</>
			);
		}

		return (
			<>
				<HeaderLogo />
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 offset-lg-3">
							<h2 className="text-center">Benutzer</h2>
						</div>
					</div>
				</div>
				<div className="container">
					<button
						type="submit"
						className="btn btn-primary btn-block"
						onClick={ e => this.changePw() }
					>Passwort ändern</button>
				</div>
				<div className="container">
					<button
						type="submit"
						className="mt-3 btn btn-danger btn-block"
						onClick={ e => this.logout() }
					>Abmelden</button>
				</div>
			</>
		);
	}

}

export default User;