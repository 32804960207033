import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { HomeOutline, MicCircleOutline, AddCircleOutline, PersonOutline, DocumentOutline, CheckmarkDoneOutline, FingerPrintOutline } from 'react-ionicons'
import { handleVoiceRecord } from '../../helper/voice.js';

function Footer() {

	const navigate = useNavigate();
	const location = useLocation();
	const [isListening, setIsListening] = useState(false);

	async function handleRecognitionResult(event) {
		var words = event.results[0][0].transcript;
		var response = await handleVoiceRecord(words);
		if (response) {
			navigate(response.route, { state: { term: response.term } });
			console.log(response.route)
		}
		setIsListening(false);
	}

	function startRecognition() {
		const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
		const recognition = new SpeechRecognition();
		recognition.lang = 'de-DE';
		setIsListening(true);
		recognition.addEventListener('result', handleRecognitionResult);
		recognition.start();
		setTimeout(() => {
		recognition.stop();
		setIsListening(false);
		}, 3000);
	}

	return (
		<>
			{ !location.pathname.includes('/tickets/') && !location.pathname.includes('/invoice/') &&
				<div className="spacer"></div>
			}
			
			{ !location.pathname.includes('/tickets/') && !location.pathname.includes('/invoice/') &&
			<div id="footer" className="fixed-bottom">
				<div className="container">	
					<div className="row">
						<div
							className="col icon-small"
							onClick={() => navigate('/contacts')}
						>
							<PersonOutline
								color={`${location.pathname === '/contacts' ? '#007CC5' : ''}`}
							/>
							<p>Kontakte</p>
						</div>
						
						<div
							className="col icon-small"
							onClick={() => navigate('/notes')}
						>
						<DocumentOutline
			              color={`${location.pathname === '/notes' ? '#007CC5' : ''}`}
			            />
							<p>Notizen</p>
						</div>
						<div 
							className="col icon-small home"
							onClick={() => navigate('/')}
						>
							<HomeOutline
								color={`${location.pathname === '/' ? '#007CC5' : ''}`}
							/>
							<p>Home</p>
						</div>
						<div
							className="col icon-small"
							onClick={() => navigate('/tickets')}
						>
							<CheckmarkDoneOutline
					              color={`${location.pathname === '/tickets' ? '#007CC5' : ''}`}
					            />
							<p>Aufgaben</p>
						</div>
						<div
							className="col icon-small"
							onClick={() => navigate('/user')}
						>
							<FingerPrintOutline
					              color={`${location.pathname === '/user' ? '#007CC5' : ''}`}
					            />
							<p>Benutzer</p>
						</div>
					</div>
				</div>

			</div>
			}
		</>
	);
}

export default Footer;