import React, { Component } from 'react';
import { useParams } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";

import { Document, Page, pdfjs } from 'react-pdf';

import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import SignatureCanvas from 'react-signature-canvas'

import { getInvoiceById, updateInvoice, deleteInvoice, editFileById, saveDraw, saveCommentDataIn, updateCommentIn } from '../helper/restapi.js';

import { ChevronUpOutline, ChevronDownOutline } from 'react-ionicons'

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class FileInvoice extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isReady: true,
			data: [],
			type: '',
			invoice: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			userLastname: '',
			showDetails: false,
			showCheck: false,
			showPercent: false,
			houses: [],
			checkSum: 0,
			logs: [],
			edit: false,
			newTitle: '',
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			drawMode: false,
			comments: [],
			unread: 0,
			readby: [],
			showComments: false,
			message: '',
			showActions: true
		}
		this.signatureRef = React.createRef();
		this.viewer = React.createRef();
		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);
		this.handleShowCheck = this.handleShowCheck.bind(this);
		this.handleHideCheck = this.handleHideCheck.bind(this);
		this.saveSplit = this.saveSplit.bind(this);

	}

	async getFile(id) {
		const response = await getInvoiceById(id);

		if (response.data.data.path.includes("pdf") || response.data.data.path.includes("PDF")) {
			this.setState({ type: 'pdf' });
			var checkJpg = response.data.data.fullpath.replace("pdf", "jpg");
			response.data.data.fullpathJpg = checkJpg;
		} else {
			this.setState({ type: 'image' });
		}

		if (response.data.data.invoice.log) {
			var logs = JSON.parse(response.data.data.invoice.log);
			this.setState({logs, logs});
		}

		this.setState({ comments: response.data.comments})
		if (response.data.comments.length > 0) {
			var currentUser = this.props.user.user_id.toString();
			var comments = response.data.comments;
			var unRead = 0;
			var readByArr = [];
			Object.keys(comments).forEach((key) => {
				if (comments[key].readby) {
					var readBy = comments[key].readby;

					this.setState({readby: readBy});
					var readBy = JSON.parse(readBy);
					if (!readBy.includes(currentUser)) {
						readByArr.push(comments[key]);
						unRead++;
					}
				}
			});
			this.setState({ unread: unRead, readby: readByArr });
		}

		// if (response.data.data.coomments.length > 0) {
		// 	console.log('a')
		// }
		this.setState({ data: response.data.data });
		this.setState({ invoice: response.data.data.invoice });
		this.setState({ checkSum: response.data.data.invoice.amount })
		this.setState({ newTitle: response.data.data.title });

		var houses = response.data.houses;

		var totalAmount = response.data.data.invoice.amount;
		var numberOfPeople = houses.length;
		var equalAmount = totalAmount / numberOfPeople;
		var roundedEqualAmount = Math.floor(equalAmount * 100) / 100;
		var remainingAmount = totalAmount - roundedEqualAmount * numberOfPeople;
		var remainingAmount = remainingAmount.toFixed(2) * 100;
		var splitAmounts = Array(numberOfPeople).fill(roundedEqualAmount);


		for (var i = 0; i < remainingAmount; i++) {
			var splitty = splitAmounts[i] + 0.01;
			splitty = (Math.ceil(splitty * 100) / 100).toFixed(2);
			splitAmounts[i] =  splitty;
		}

		var houseArr = [];
		houses.map((house, index) => {
			houseArr.push({
				'id': house.id,
				'name': house.name,
				'amount': splitAmounts[index],
				'amountPercent': ((splitAmounts[index] / totalAmount)*100).toFixed(2),
			})
		});

		this.setState({ houses: houseArr });
	}

	deleteInvoiceConfirm = (id) => {
		const shouldClose = window.confirm('Möchten Sie die Rechnung löschen?');
		if (shouldClose) {
			this.deleteInvoice(id);
			this.setState({ isReady: false });
		}
	};

	async deleteInvoice(id) {
		const response = await deleteInvoice(id);
		if (response.data.success) {
			window.history.go(-1)
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	async componentDidMount() {
		this.setState({ userId: this.props.user.user_id });
		//this.setState({ userId: 4 });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ userLastname: this.props.user.user_lastname });
	    let { id } = this.props.params;
	    await this.getFile(id);
	    window.addEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		this.setState({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		});
	};

	toggleEdit = () => {
		this.setState({ edit: true });
	}

	toggleDraw = () => {
		this.setState({ drawMode: true });
	}

	saveDraw = () => {
		if (this.state.draw) {
			var response = saveDraw(this.state.data.invoice.id, this.state.draw);
			this.setState({ draw: false });
			this.getFile(this.state.data.invoice.id);
			alert('Gespeichert!');
		} else {
			this.setState({ drawMode: false });
		}
		
		// alert('save');
		// this.setState({ drawMode: false });
	}
	resetDraw = () => {
		this.setState({ draw: false, drawMode: false });
	}
	
	closeEdit = () => {
		this.setState({ edit: false });
	}

	saveFile = () => {
		if (this.state.newTitle) {
			editFileById(this.state.data.invoice.file, this.state.newTitle);
			this.setState({ edit: false });
		}
	}

	handleShow() {
		this.setState({ showDetails: true })
	}
	handleHide() {
		this.setState({ showDetails: false })
	}
	handleShowCheck() {
		this.setState({ showCheck: true })
	}
	handleHideCheck() {
		this.setState({ showCheck: false })
	}

	updateInputPercentValue(evt, index) {

		const newHouses = [...this.state.houses];
		var totalAmount = this.state.invoice.amount;
		
		newHouses[index].amountPercent = evt.target.value;
		newHouses[index].amount = (totalAmount*(evt.target.value/100)).toFixed(2);
		this.setState({ houses: newHouses });	

		var sum = 0;
		newHouses.forEach((house) => {
			sum += Number(house.amount);
		});

		this.setState({checkSum: sum.toFixed(2)});

	}

	updateInputValue(evt, index) {

		const newHouses = [...this.state.houses];

		var totalAmount = this.state.invoice.amount;

		if (evt.target.name == 'checkbox') {

			if (evt.target.checked) {
				newHouses[index].amount = 1;
			} else {
				newHouses[index].amount = 0;
			}

			var numberOfPeople = 0;
			newHouses.map((house, index) => {
				if (house.amount > 0.1) {
					numberOfPeople++;
				}
			});

			var equalAmount = totalAmount / numberOfPeople;
			var roundedEqualAmount = Math.floor(equalAmount * 100) / 100;
			var remainingAmount = totalAmount - roundedEqualAmount * numberOfPeople;
			var remainingAmount = remainingAmount.toFixed(2) * 100;
			var splitAmounts = Array(numberOfPeople).fill(roundedEqualAmount);
			var splitAmountsPercent = Array(numberOfPeople).fill(roundedEqualAmount);

			for (var i = 0; i < remainingAmount; i++) {
				var splitty = splitAmounts[i] + 0.01;
				splitty = (Math.ceil(splitty * 100) / 100).toFixed(2);
				splitAmounts[i] =  splitty;
			}

			var houseArr = [];
			var splitIndex = 0;

			newHouses.map((house, index) => {
			    if (splitIndex < splitAmounts.length) {
			        if (house.amount > 0.1) {
			            houseArr.push({
			                ...house,
			                amount: splitAmounts[splitIndex],
			            });
			            splitIndex++;
			        } else {
			            houseArr.push(house);
			        }
			    } else {
			        houseArr.push(house);
			    }

	            houseArr[index].amountPercent = ((houseArr[index].amount / totalAmount) * 100).toFixed(2);
			});

			this.setState({ houses: houseArr });

			var sum = 0;
			houseArr.forEach((house) => {
				sum += Number(house.amount);
			});

			this.setState({checkSum: sum.toFixed(2)});

		} else {
			newHouses[index].amount = evt.target.value;
			newHouses[index].amountPercent = ((evt.target.value / totalAmount) * 100).toFixed(2);
			this.setState({ houses: newHouses });	

			var sum = 0;
			newHouses.forEach((house) => {
				sum += Number(house.amount);
			});

			this.setState({checkSum: sum.toFixed(2)});

		}


	}

	updateNameValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'title') {
			this.setState({ newTitle: val });
		}
	}

	saveSplit() {
		this.setState({ isReady: false });
		var check = this.state.checkSum - this.state.invoice.amount;
		if (check == 0) {
			var id = this.state.invoice.id;
			var state = 3;
			var split = this.state.houses;
			this.updateInvoice(id, state, split);
			this.setState({ isReady: true });
		} else {
			alert('Die Summe ist nicht korrekt.');
			this.setState({ isReady: true });
		}
	}

	changeState = (state, message) => {
		const shouldChange = window.confirm(message);
		if (shouldChange) {
			var id = this.state.invoice.id;
			this.updateInvoice(id, state);
		}
	};

	async updateInvoice(id, state, split) {

		// prepare logs
		var log = [];

		var name = this.state.userFirstname + ' ' + this.state.userLastname;
		log.push(name);

		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
		const day = String(now.getDate()).padStart(2, '0');
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');
		const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		log.push(formattedDateTime);

		var info = '';
		var oldState = state-1;
		if (oldState == 2) {
			var info = 'Kontrolliert';
		}
		if (oldState == 3) {
			var info = 'An Pataki';
		}
		if (oldState == 4) {
			var info = 'Zahlung freigegeben';
		}
		if (oldState == 5) {
			var info = 'Bezahlt';
		}
		log.push(info);

		var logs = [];
		if (this.state.data.invoice.log) {
			var rLogs = JSON.parse(this.state.data.invoice.log)
			logs = rLogs;
		}
		logs.push(log);
		
		logs = JSON.stringify(logs);

		const response = await updateInvoice(id, state, split, logs);

		if (response.data.success) {
			this.setState({ isReady: true });
			this.setState({ showCheck: false })
			this.getFile(id);
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}

	}


	async addTextToPdf(pdfBytes, pdfText, pdfTitle) {

		const pdfDoc = await PDFDocument.load(pdfBytes);

		const page = pdfDoc.addPage();
		const { width, height } = page.getSize();

		const fontSize = 12;
		const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

		page.drawText('Aufteilung: ' + pdfTitle, {
			x: 50,
			y: height - 60,
			size: 14,
			color: rgb(0, 0, 0),
			font: font,
		});

		page.drawLine({ start: { x: 50, y: height - 84 }, end: { x: width - 50, y: height - 84}, thickness: .5, color: rgb(0.5,0.5,0.5) });
		pdfText.forEach((data, rowIndex) => {

			page.drawText(data.house_name, {
				x: 50,
				y: height - (20 * (rowIndex + 5)),
				size: 12,
				color: rgb(0, 0, 0),
				font: font,
			});

			if (!data.amount) {
				data.amount = 0;
			}
			var amount = parseFloat(data.amount);
			amount = amount.toLocaleString('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			const textWidth = font.widthOfTextAtSize(amount, fontSize);

			page.drawText(amount, {
				x: width - 50 - textWidth,
				y: height - (20 * (rowIndex + 5)),
				size: 12,
				color: rgb(0, 0, 0),
				font: font,
			});

			page.drawLine({ start: { x: 50, y: height - (20 * (rowIndex + 5) + 6) }, end: { x: width - 50, y: height - (20 * (rowIndex + 5) + 6) }, thickness: .5, color: rgb(0.5,0.5,0.5) });

		});

		const modifiedPdfBytes = await pdfDoc.save();

		return modifiedPdfBytes;
	}

	downloadPdf(pdfBytes, filename) {
		const blob = new Blob([pdfBytes], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	async fetchPdfAsBytes(pdfUrl) {
		const response = await fetch(pdfUrl);
		const pdfBytes = await response.arrayBuffer();
		return new Uint8Array(pdfBytes);
	}

	getPdfSplitFile = (state) => {
		var pdfUrl = this.state.data.fullpath;
		var pdfTitle = this.state.data.title;
		var pdfText = this.state.data.invoice.splits;
		
		this.fetchPdfAsBytes(pdfUrl).then(existingPdfBytes => {
			this.addTextToPdf(existingPdfBytes, pdfText, pdfTitle).then(modifiedPdfBytes => {
				this.downloadPdf(modifiedPdfBytes, pdfTitle + '.pdf');
			});
		});
	}

	togglePercent = () => {
		if (this.state.showPercent) {
			this.setState({ showPercent: false })
		} else {
			this.setState({ showPercent: true })
		}
	}

	getSignature = () => {
		const imageDataUrl = this.signatureRef.current.toDataURL();

		this.setState({
			draw: imageDataUrl
		});
	};

	updateMessage(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({ message: val });
	}

	saveComment = () => {
		if (this.state.message) {
			var ticket = this.state.data.invoice.id;
			var user = this.state.userId;
			var message = this.state.message;
			this.setState({ isReady: false });
			this.saveCommentDataIn(ticket, user, message);
		}
	}
	async saveCommentDataIn(ticket, user, comment) {
		const response = await saveCommentDataIn(ticket, user, comment);
		if (response.data.success) {
			this.setState({ edit: false });
			this.getFile(ticket);
			this.setState({ isReady: true });
			this.setState({ showComments: true });
			this.setState({ message: '' });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	toggleShowComments = () => {
		if (this.state.unread > 0) {

			var readBy = this.state.readby;
			Object.keys(readBy).forEach((key) => {
				var id = readBy[key].id;
				var readByEntry = readBy[key].readby;
				var readByEntry = JSON.parse(readByEntry);
				var userId = this.state.userId.toString();
				readByEntry.push(userId);
				readByEntry = JSON.stringify(readByEntry);
				updateCommentIn(id, readByEntry);
			});

			this.setState({unread: 0});

		}
		
		this.setState({ showComments: true });
	}
	toggleShowCommentsBack = () => {
		this.setState({ showComments: false });
	}

	toggleShowActions = () => {
		const showActions = this.state.showActions;
		if (showActions) {
			this.setState({ showActions: false })
		} else {
			this.setState({ showActions: true })
		}
	}

	render() {

		const { comments } = this.state;

		if (this.state.showComments) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.toggleShowCommentsBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Kommentare</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container comments-wrapper">
						<div className="row comments">
							{Object.keys(comments).map(index => (
								<div key={index} className={`comment col-12 ${comments[index].user == this.state.userId ? 'me' : ''}`}>
									<div className="text">
										<div className="name">
											{ comments[index].username }
										</div>
										{ comments[index].comment }
									</div>
									<div className="time">
										{ comments[index].created }
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="container message-outer">
						<div className="row">
							<div className="form-group col-10 pr-0">
								<textarea
									type="text"
									name="message"
									value={ this.state.message }
									className="form-control message"
									onChange={evt => this.updateMessage(evt)}
								/>
							</div>
							<div className="col-2 pl-0">
								<a
									className="btn btn-sm btn-success btn-message"
									onClick={ e => this.saveComment(e) }
								>s</a>
							</div>
						</div>
					</div>
				</>	
			);	
		}

		return (
			<>
				{!this.state.isReady  &&
					<HeaderLoading />
				}

				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-3">
								<GoBack />
							</div>

							<div className="col-9 text-end">
								{ !this.state.drawMode &&
									<>
										<div
											className="add-link-draw"
											onClick={this.toggleDraw}
										>✎</div>
										<div
											className="add-link-message"
											onClick={this.toggleShowComments}
										>✉
											{ this.state.unread > 0 &&
												<span>{this.state.unread}</span>
											}
										</div>
									</>
								}

								{ this.state.edit == true && this.state.userRole == 1 &&
									<>
										{ this.state.userRole == 1 &&
										<div
											className="add-link"
											onClick={this.closeEdit}
										>x</div>
										}
									</>
								}
								{ !this.state.edit && this.state.userRole == 1 &&
									<>
										{ this.state.userRole == 1 &&
										<div
											className="add-link"
											onClick={this.toggleEdit}
										>⚙</div>
										}
									</>
								}
							</div>
						</div>
					</div>
				</div>
				{ this.state.edit == true && this.state.userRole == 1 &&
					<div className="container mt-4">
						<div className="form-group">
							<label>Titel*</label>
							<input
								type="text"
								name="title"
								value={this.state.newTitle}
								className="form-control"
								onChange={evt => this.updateNameValue(evt)}
							/>
						</div>
						<button
							type="submit"
							className="btn btn-primary btn-block mb-4"
							onClick={ e => this.saveFile(e) }
						>Speichern</button>
					</div>
				}
				{ this.state.data &&
					<>
						{this.state.type == 'image' &&
							<div className="image">
							<img src={this.state.data.fullpath} />
							</div>
						}
						{this.state.type == 'pdf' &&
							<div className={`pdf-outer ${this.state.drawMode ? 'show-comments' : ''}`}>
								{ this.state.drawMode &&
									<SignatureCanvas
										ref={this.signatureRef}
										penColor='#0170C1'
										minWidth='0.5'
										maxWidth='1.5'
		    							canvasProps={{width: this.state.windowWidth <= 800 ? this.state.windowWidth : 800, height: this.state.windowWidth <= 800 ? this.state.windowWidth * 1.41 : 800*1.41, className: 'pdf-draw'}}
		    							onEnd={this.getSignature}
		    						/>
	    						}
								{this.state.drawMode && this.state.data.draw && this.state.data.draw.map((item, index) => (
									<img 
										key={index}
										className="draw"
										src={item.draw} />
								))}

	    						{ this.state.drawMode &&
	    							<>
	    							<div className="pdf-buttons">
										{ this.state.draw &&
											<div
												className="pdf-button save"
												onClick={this.saveDraw}
											>Speichern</div>
										}
										{ this.state.draw &&
											<div
												className="pdf-button reset"
												onClick={this.resetDraw}
											>Verwerfen</div>
										}
										{ !this.state.draw &&
											<div
												className="pdf-button reset"
												onClick={this.resetDraw}
											>Zurück</div>
										}
									</div>

									<iframe className="pdf draw-mode d-block" width={this.state.windowWidth <= 800 ? this.state.windowWidth : 800} height={this.state.windowWidth <= 800 ? this.state.windowWidth*1.41 : 800*1.41} src={`${this.state.data.fullpath}#toolbar=0&zoom=0&new=false`} />
									</>
								}

	    						{ !this.state.drawMode &&
									<iframe className="pdf d-block"  height={this.state.windowHeight} width={this.state.windowWidth} src={`${this.state.data.fullpath}#toolbar=0&zoom=0&new=false`} />
								}

								<img className="pdfContainer d-none d-md-none" src={this.state.data.fullpathJpg} />
							</div>
						}

						{ !this.state.drawMode &&
							<div className="container actions">

								<div className="toggler" onClick={this.toggleShowActions}>
									{ this.state.showActions &&
										<ChevronDownOutline color={'#007CC5'} />
									}
									{ !this.state.showActions &&
										<ChevronUpOutline color={'#007CC5'} />
									}
								</div>

								{ this.state.showActions &&
									<>

										{ this.state.invoice.state == 2 && this.state.invoice.user == this.state.userId &&
											<div className="btn btn-success btn-block mt-2 mb-0" onClick={this.handleShowCheck}>Kontrollieren und Splitten</div>
										}

										{ this.state.invoice.state == 3 && this.state.userId == 8 &&
											<div className="btn btn-success btn-block mt-2 mb-0" onClick={() => this.changeState(4, 'Soll die Rechnung zur Freigabe weitergegeben werden?')}>Für Freigabe bereit</div>
										}

										{ this.state.invoice.state == 4 && this.state.userId == 3 &&
											<div className="btn btn-success btn-block mt-2 mb-0" onClick={() => this.changeState(5, 'Soll die Rechnung zur Zahlung freigegeben werden?')}>Für Zahlung bereit</div>
										}

										{ this.state.invoice.state ==5 && this.state.userId == 4 &&
											<div className="btn btn-success btn-block mt-2 mb-0" onClick={() => this.changeState(6, 'Soll die Rechnung als bezahlt markiert werden?')}>Als bezahlt markieren</div>
										}

										<div className="btn btn-secondary btn-block mt-2" onClick={this.handleShow}>Details</div>

										{ this.state.invoice.splits &&
											<div className="btn btn-secondary btn-block mt-1 mb-0 d-none d-md-block" onClick={this.getPdfSplitFile}>Mit Split downloaden</div>
										}
										{ this.state.userRole == 1 &&
											<div className="row mb-1 mt-1">
												<div className="col-12">
													<a
														className="btn btn-danger btn-block"
														onClick={() => this.deleteInvoiceConfirm(this.props.params.id)}
													>Ticket löschen</a>
												</div>
											</div>
										}

										<a href={this.state.data.fullpath} target="_blank" className="btn btn-secondary btn-block mt-0 mb-2 d-block">Original ansehen</a>


										{ this.state.showDetails &&
											<div className="modal fade show" role="dialog" style={{ display: "block" }}>
												<div className="modal-backdrop fade show"></div>
												<div className="modal-dialog" role="document">
													<div className="modal-content">
														<div className="modal-header">
															<h5 className="modal-title">Details</h5>
															<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleHide}>
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<p><strong>Titel:</strong><br />{ this.state.data.title }</p>

															{ this.state.data.comment &&
																<p><strong>Kommentar:</strong><br />{ this.state.data.comment }</p>
															}												
															<p><strong>Status:</strong><br />{ this.state.invoice.state_name }</p>
															{ this.state.data.deadline &&
																<p><strong>Deadline:</strong><br />{ this.state.data.deadline }</p>
															}
															<p><strong>Kontrolle durch:</strong><br />{ this.state.invoice.user_name }</p>
															<p><strong>Summe:</strong><br />€ { this.state.invoice.amount }</p>
															{ this.state.invoice.splits &&
																<>
																	<div><strong>Split:</strong><br />

																		{this.state.invoice.splits.map((house) => (
																			<div className="row">
																				<div className="col-6">
																					{ house.house_name }
																				</div>
																				<div className="col-6 text-end">
																					€ { house.amount }
																				</div>
																			</div>
																		))}

																	</div>
																</>
															}
															<hr />
															<p><strong>Logdaten:</strong><br /></p>
															<table className="table table-sm logs">
																<tbody>
																	<tr>
																		<td>Upload</td>
																		<td>{ this.state.data.user_name }</td>
																		<td className="text-end">{ this.state.data.date }</td>
																	</tr>
																	{ this.state.logs &&
																		<>
																			{this.state.logs.map((log) => (
																				<tr>	
																					<td>{ log[2] }</td>
																					<td>{ log[0] }</td>
																					<td className="text-end">{ log[1] }</td>
																				</tr>
																			))}
																		</>
																	}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										}

										{ this.state.showCheck &&
											<div className="modal fade show" role="dialog" style={{ display: "block" }}>
												<div className="modal-backdrop fade show"></div>
												<div className="modal-dialog" role="document">
													<div className="modal-content">
														<div className="modal-header">
															<h5 className="modal-title">Splitten</h5>
															<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleHideCheck}>
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<div className="row">
																<p className="col-6">
																	Summe:
																</p>
																<div className="col-6 text-end">
																	€ { this.state.invoice.amount }
																</div>
															</div>
															<div className="row">
																<p className="col-6">
																	Kontrollsumme:
																</p>
																<div className="col-6 text-end">
																	{ this.state.checkSum > 0 && 
																		<div>€ { this.state.checkSum }</div>
																	}
																	{ this.state.checkSum == 0 && 
																		<div>€ { this.state.invoice.amount }</div>
																	}
																</div>
															</div>

															<hr className="mb-4" />

															<p
																className="text-end"
																onClick={this.togglePercent}
															>Prozentuell verteilen</p>
															{this.state.houses.map((house, index) => (
																<div className="row">
																	<div className="col-1">
																		{ this.state.houses[index].amount < 0.1 &&
																			<input
																				type="checkbox"
																				onChange={evt => this.updateInputValue(evt, index)}
																				name="checkbox"
																			/>
																		}
																		{ this.state.houses[index].amount > 0 &&
																			<>
																				<input
																					type="checkbox"
																					onChange={evt => this.updateInputValue(evt, index)}
																					name="checkbox"
																					checked="true"
																				/>
																			</>
																		}

																	</div>

																	{ this.state.showPercent &&
																		<>
																			<div className="col-3">
																				<p>{house.name}</p>
																			</div>
																			
																			<div className="col-4 text-end">
																				<input
																					type="number"
																					value={this.state.houses[index].amount}
																					className="form-control text-end"
																					onChange={evt => this.updateInputValue(evt, index)}
																				/>
																			</div>
																			<div className="col-4 text-end">
																				<input
																					type="number"
																					value={this.state.houses[index].amountPercent}
																					className="form-control text-end"
																					onChange={evt => this.updateInputPercentValue(evt, index)}
																				/>
																			</div>
																		</>
																	}
																	{ !this.state.showPercent &&
																		<>
																			<div className="col-6">
																				<p>{house.name}</p>
																			</div>
																			
																			<div className="col-5 text-end">
																				<input
																					type="number"
																					value={this.state.houses[index].amount}
																					className="form-control text-end"
																					onChange={evt => this.updateInputValue(evt, index)}
																				/>
																			</div>
																		</>
																	}
																</div>
															))}

															{ this.state.isReady  &&
																<div className="btn btn-success btn-block mt-3 mb-2" onClick={this.saveSplit}>Freigeben</div>
															}
														</div>
													</div>
												</div>

											</div>
										}
									</>
								}
							</div>
						}
					</>
				}
			</>
		);
	}
}

export default withParams(FileInvoice);