import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getTickets, getAllProjects, addNewTicket, getProjectById } from '../helper/restapi.js';
import ReactSwitch from "react-switch";

import axios from 'axios';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

const styles = StyleSheet.create({
	main: {
		fontSize: 12,
		marginTop: 20,
		marginBottom: 20,
	},
	headline: {
		fontSize: 16,
		color: '#007CC5',
		marginBottom: 20,
		paddingLeft: 20,
		paddingRight: 20,
	},
	part: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 10,
		paddingLeft: 20,
		paddingRight: 20,
	},
	labelContainer: {
		width: 100,
		color: '#007CC5',
	},
	strong: {
		fontWeight: 'bold',
	},
	valueContainer: {
		flex: 1,
	},
	description: {
		marginBottom: 10,
		marginTop: 20,
		paddingLeft: 20,
		paddingRight: 20,
		color: '#007CC5',
	},
	text: {
		paddingLeft: 20,
		paddingRight: 20,
	},
	image: {
		paddingLeft: 20,
		paddingRight: 20,
		marginTop: 20,
		marginBottom: 20		
	}
	// section: {
	// 	margin: 10,
	// 	padding: 10,
	// 	fontSize: 10,
	// },
	// viewer: {
	// 	width: window.innerWidth,
	// 	height: window.innerHeight,
	// },
});

class Tickets extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			project: null,
			projects: [],
			users: [],
			house: null,
			data: [],
			all: [],
			filter: false,
			filterdata: [],
			states: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			userLastname: '',
			location: '',
			add: false,
			view: 1,
			message: '',
			projectName: '',
			clustered: false,
			clusteredData: [],
			customfiltered: false,
			showFilter: false,
			showPrint: false,
			selectedToPrint: [],
			printData: [],
			customFilter: {
				cid: null,
				cidName: '',
				aid: null,
				aidName: '',
				state: null,
				stateName: '',
				project: null,
				projectName: '',
				house: null,
				houseName: '',
				gewerk: '',
				category: '',
				sort: '',
			},
			addTicket: {
				title: "",
				description: "",
				aid: "",
				aid2: "",
				deadline: "",
				project: "",
				house: "",
				category: "",	
				gewerk: "",
				company: "",
			},
			scrollPosition: 0
		};

		this.handleLinkClick = this.handleLinkClick.bind(this);
	}

	handleLinkClick() {
		const scrollPosition = window.scrollY;
		this.setState({ scrollPosition });
		localStorage.setItem('scrollPosition', scrollPosition);
	}

	async getTickets(title, cid, aid, state, project, house) {
		const response = await getTickets(title, cid, aid, state, project, house);

		console.log(response.data.data)

		if (this.state.clustered) {

			//const data = { ...response.data.data[1], ...response.data.data[2] };

			const object1 = response.data.data[1];
			const object2 = response.data.data[2];

			const renamedObject2 = {};
			for (const key in object2) {
			  renamedObject2[`renamed_${key}`] = object2[key];
			}

			const data = { ...object1, ...renamedObject2 };

			const clusteredData = {};

			for (const key in data) {
				const item = data[key];
				const projectName = item.projectname;

				if (projectName !== null && projectName !== undefined) {
					if (!clusteredData[projectName]) {
						clusteredData[projectName] = [];
					}

					clusteredData[projectName].push(item);
				}
			}

			const sortedClusteredData = Object.keys(clusteredData).map(projectName => ({
				projectName,
				items: clusteredData[projectName]
			}));

			sortedClusteredData.sort((a, b) => b.items.length - a.items.length);

			this.setState({clusteredData: sortedClusteredData});


		}

		var responseData1 = response.data.data[1]
		responseData1.sort((a, b) => new Date(b.created) - new Date(a.created));

		var responseData2 = response.data.data[2]
		responseData2.sort((a, b) => new Date(b.created) - new Date(a.created));

		var responseData3 = response.data.data[3]
		responseData3.sort((a, b) => new Date(b.created) - new Date(a.created));

		if (this.state.customFilter.category) {
			responseData1 = responseData1.filter(entry => entry.category === this.state.customFilter.category);
			responseData2 = responseData2.filter(entry => entry.category === this.state.customFilter.category);
			responseData3 = responseData3.filter(entry => entry.category === this.state.customFilter.category);
		}

		if (this.state.customFilter.gewerk) {
			responseData1 = responseData1.filter(entry => entry.gewerk === this.state.customFilter.gewerk);
			responseData2 = responseData2.filter(entry => entry.gewerk === this.state.customFilter.gewerk);
			responseData3 = responseData3.filter(entry => entry.gewerk === this.state.customFilter.gewerk);
		}

		if (this.state.customFilter.sort) {
			if (this.state.customFilter.sort == 'deadline') {
				responseData1.sort((a, b) => {
					const dateA = new Date(a.deadline);
					const dateB = new Date(b.deadline);
					return dateA - dateB;
				});
				responseData2.sort((a, b) => {
					const dateA = new Date(a.deadline);
					const dateB = new Date(b.deadline);
					return dateA - dateB;
				});
				responseData3.sort((a, b) => {
					const dateA = new Date(a.deadline);
					const dateB = new Date(b.deadline);
					return dateA - dateB;
				});
			}
		}

		response.data.data[1] = responseData1;
		response.data.data[2] = responseData2;
		response.data.data[3] = responseData3;

		const object1 = response.data.data[1];
		const object2 = response.data.data[2];
		const object3 = response.data.data[3];

		var getThemAll = [];
		for (const key in object1) {
			getThemAll[object1[key].id] = object1[key];
		}	
		for (const key in object2) {
			getThemAll[object2[key].id] = object2[key];
		}		
		for (const key in object3) {
			getThemAll[object3[key].id] = object3[key];
		}

		this.setState({ all: getThemAll });

		// const renamedObject2 = {};
		// for (const key in object2) {
		//   renamedObject2[`renamed_${key}`] = object2[key];
		// }
		// const renamedObject3 = {};
		// for (const key in object3) {
		//   renamedObject3[`renamed3_${key}`] = object3[key];
		// }

		// const getThemAll = { ...object1, ...renamedObject2 };
		// getThemAll = { ...object1, ...renamedObject3 };

		// console.log(getThemAll);

		var i = 0;
		for (const item of responseData3) {
			var c = i++;
			var unRead = 0;
			if (item.comments.length > 0) {
				var currentUser = this.props.user.user_id.toString();
				var comments = item.comments;
				
				var readByArr = [];

				Object.keys(comments).forEach((key) => {
					if (comments[key].readby) {
						var readBy = comments[key].readby;
						this.setState({readby: readBy});
						var readBy = JSON.parse(readBy);
						if (!readBy.includes(currentUser)) {
							readByArr.push(comments[key]);
							unRead++;
						}
					}
				});	

			}
			responseData3[c]['unread'] = unRead;
		}

		if (response.data.housename2) {
			this.setState({ projectName: response.data.projectname2 + ' > ' + response.data.housename2});
		} else {
			this.setState({ projectName: response.data.projectname2});
		}
		
		this.setState({ data: response.data.data });
		this.setState({ states: response.data.states });
		this.setState({ users: response.data.users });
		this.setState({ isReady: true });
	}

	componentDidMount() {

		this.getAllProjects();

		var clustered = localStorage.getItem('clustered');
		if (clustered == 'true') {
			this.setState({clustered: true});
		} else {
			this.setState({clustered: false});
		}

		var project  = null;
		var house = null;

		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');

			if (checkProjectHouse[1] == 'projects') {
				this.setState({ project: checkProjectHouse[2] });

				var project = checkProjectHouse[2];				

				this.setState({
					addTicket: {
						...this.state.addTicket,
						'project': parseInt(project),
						'cid': parseInt(this.props.user.user_id),
					}
				});

			}	
			if (checkProjectHouse[3] == 'houses') {
				this.setState({ project: checkProjectHouse[2] });
				this.setState({ house: checkProjectHouse[4] });

				var project = checkProjectHouse[2];
				var house = checkProjectHouse[4];

				this.setState({
					addTicket: {
						...this.state.addTicket,
						'project': parseInt(project),
						'house': parseInt(house),
						'cid': parseInt(this.props.user.user_id),
					}
				});
			}			
		}

		this.setState({
			customFilter: {
				...this.state.customFilter,
				'aid': this.props.user.user_id
			}
		});

		//title, cid, aid, state, project, house
		const customFilter = localStorage.getItem('customFilter');
		if (customFilter) {
			const parsedCustomFilter = JSON.parse(customFilter);
			this.setState({ customFilter: parsedCustomFilter });
			this.getTickets(null, parsedCustomFilter.cid, parsedCustomFilter.aid, parsedCustomFilter.state, parsedCustomFilter.project, parsedCustomFilter.house);
		} else {
			this.getTickets(null, null, this.props.user.user_id, null, project, house);
		}

		
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ userLastname: this.props.user.user_lastname });
		this.setState({ location: this.props.location.state });



		const validUserIds = [1, 3, 4, 5, 6, 7, 8];

		if (validUserIds.includes(this.props.user.user_id)) {
			this.setState({ showAll: 1 })
		}

		const storedPosition = localStorage.getItem('scrollPosition');
		setTimeout(() => {
			window.scrollTo(0, parseInt(storedPosition));
		}, 1000);
	}

	changeView = (view) => {
		this.setState({ view: view });
		if (view == 2) {
			this.getTickets(null, this.props.user.user_id, null, null, this.state.project, this.state.house);
		} else if (view == 3) {
			this.getTickets(null, null, null, null, this.state.project, this.state.house);
		} else {
			this.getTickets(null, null, this.props.user.user_id, null, this.state.project, this.state.house);
		}
	};

	addTicket = () => {
		this.setState({ add: true });
		this.getAllProjects();
	}

	addTicketBack = () => {
		const shouldGoBack = window.confirm('Aufgabe verwerfen?');
		if (shouldGoBack) {
			this.setState({ add: false });
		}
	}

	printSelection(itemId) {
		var selectedToPrint = this.state.selectedToPrint;
		if (selectedToPrint.includes(itemId)) {
			const updatedCheckboxes = selectedToPrint.filter((id) => id !== itemId);
			this.setState({ selectedToPrint: updatedCheckboxes });
		} else {
			this.setState({ selectedToPrint: [...selectedToPrint, itemId] });
		}
	}

	printView = () => {
		if (this.state.showPrint) {
			this.setState({ showPrint: false })
		} else {
			this.setState({ showPrint: true })
		}

	}

	printNow = () => {
		var selected = this.state.selectedToPrint;
		var print = this.state.all;
		var data = [];
		if (selected.length > 0) {
			selected.forEach(function (element) {
				data.push(print[element]);
			});
			this.setState({printData: data});
			this.setState({view: 4});
		} else {
			alert('Wählen Sie mindestens ein Ticket aus!')
		}
	}

	stopPdf = () => {
		this.setState({view: 1})
		this.setState({selectedToPrint: []})
	}

	updateCustomFilter(evt) {
		const name = evt.target.name;
		const val = evt.target.value;
		const valName = evt.target.options[evt.target.selectedIndex].text;

		this.setState(
			(prevState) => {
				const updatedState = {
					customfiltered: true,
				};

				if (name === 'project') {
					updatedState.customFilter = {
						...prevState.customFilter,
						[name]: val,
						projectName: valName,
					};
				} else if (name === 'cid') {
					updatedState.customFilter = {
						...prevState.customFilter,
						[name]: val,
						cidName: valName,
					};
				} else if (name === 'aid') {
					updatedState.customFilter = {
						...prevState.customFilter,
						[name]: val,
						aidName: valName,
					};
				} else {
					updatedState.customFilter = {
						...prevState.customFilter,
						[name]: val,
					};
				}

				return updatedState;
			},
			() => {
				localStorage.setItem('customFilter', JSON.stringify(this.state.customFilter));
				this.getTickets(null, this.state.customFilter.cid, this.state.customFilter.aid, null, this.state.customFilter.project, null);
			}
		);
	}

	showFilter = () => {
		this.setState({ showFilter: true });
	}
	hideFilter = () => {
		this.setState({ showFilter: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'upload') {
			this.setState({
				addTicket: {
					...this.state.addTicket,
					[name]:  evt.target.files[0]
				}
			});
		} else if (name == 'upload2') {
			this.setState({
				addTicket: {
					...this.state.addTicket,
					[name]:  evt.target.files[0]
				}
			});
		} else if (name == 'upload3') {
			this.setState({
				addTicket: {
					...this.state.addTicket,
					[name]:  evt.target.files[0]
				}
			});
		} else if (name == 'upload4') {
			this.setState({
				addTicket: {
					...this.state.addTicket,
					[name]:  evt.target.files[0]
				}
			});
		} else if (name == 'upload5') {
			this.setState({
				addTicket: {
					...this.state.addTicket,
					[name]:  evt.target.files[0]
				}
			});
		} else {
			this.setState({
				addTicket: {
					...this.state.addTicket,
					[name]: val
				}
			});		
		}


	}

	saveTicket = () => {
		var ticketData = this.state.addTicket;

		if (ticketData.title && ticketData.description && ticketData.aid) {
			this.setState({ isReady: false });
			this.addTicketData(ticketData);
		} else {
			alert('Bitte alle Felder ausfüllen!');
		}
	}

	async addTicketData(ticketData) {
		const response = await addNewTicket(ticketData);
		this.setState({ isReady: true });
		if (response.data.success) {
			alert('Die Aufgabe wurde erfolgreich erstellt!');
			this.setState({ add: false });
			this.setState({ view: 2 });
			this.getTickets(null, this.props.user.user_id, null, null, this.state.project, this.state.house);
			//this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	async getAllProjects() {
		const response = await getAllProjects();
		this.setState({ projects: response.data.data });
	}

	changeCluster = () => {
		if (this.state.clustered) {
			this.getTickets(null, this.state.customFilter.cid, this.state.customFilter.aid, null, this.state.customFilter.project, null);
			this.setState({clustered: false});
			localStorage.setItem('clustered', false);
		} else {
			this.getTickets(null, this.state.customFilter.cid, this.state.customFilter.aid, null, this.state.customFilter.project, null);
			this.setState({clustered: true});
			localStorage.setItem('clustered', true);
		}
	}

	searchTicket = (event) => {
		var searchValue = event.target.value;
		searchValue = searchValue.toLowerCase();

		var originalData = this.state.data;

		if (searchValue.length > 0) {
			this.setState({ filter: true });
			var flattenedObjects = Object.values(originalData).flat();
			var filteredData = flattenedObjects;
			this.setState({ filterdata: filteredData });	

			var filtered = [];

			for(var i=0; i < flattenedObjects.length; i++) {

				var searchIn = flattenedObjects[i]['title'] + ' ' + flattenedObjects[i]['description'] + ' ' + flattenedObjects[i]['projectname'] + ' ' + flattenedObjects[i]['aname'] + ' ' + flattenedObjects[i]['aname2'];
				searchIn = searchIn.toLowerCase();

				if(searchIn.indexOf(searchValue)!=-1) {
					filtered.push(flattenedObjects[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
			}

			this.setState({ filterdata: filtered });			
		} else {
			this.setState({ filterdata: [] });	
			this.setState({ filter: false });	
			this.setState({ message: '' });
		}

	}

	render() {

		const data = this.state.data;
		const filterdata = this.state.filterdata;
		const states = this.state.states;
		const clustereddata = this.state.clusteredData;

		console.log(this.state.customFilter.project)

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.view == 4) {
			var count = 1;
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.stopPdf}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>PDF-Ansicht</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<PDFViewer className="viewer">
						<Document>
							<Page size="A4" style={styles.main}>
							{Object.keys(this.state.printData).map(index => (
								<>
									{ count++ !== 1 &&
										<Text break style={styles.text}></Text>
									}
									<Text style={styles.headline}>
										{ this.state.printData[index].title }
									</Text>
									
									{ this.state.printData[index].projectname &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Projekt:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].projectname}</Text>
											</View>
										</View>
									}

									{ this.state.printData[index].housename &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Haus:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].housename}</Text>
											</View>
										</View>
									}

									{ this.state.printData[index].statename &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Status:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].statename}</Text>
											</View>
										</View>
									}


									{ this.state.printData[index].created &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Erstell am:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].created}</Text>
											</View>
										</View>
									}

									{ this.state.printData[index].deadline &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Deadline:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].deadline}</Text>
											</View>
										</View>
									}

									{ this.state.printData[index].category &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Kategorie:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].category}</Text>
											</View>
										</View>
									}

									{ this.state.printData[index].aname &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Zuständig:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>
													{this.state.printData[index].aname}
													{ this.state.printData[index].aname2 !== '' &&
														<>, {this.state.printData[index].aname2}</>
													}
												</Text>
											</View>
										</View>
									}

									{ this.state.printData[index].cname &&
										<View style={styles.part}>
											<View style={styles.labelContainer}>
												<Text style={styles.strong}>Erstellt von:</Text>
											</View>
											<View style={styles.valueContainer}>
												<Text>{this.state.printData[index].cname}</Text>
											</View>
										</View>
									}
									<View>
										<View style={styles.description}>
											<Text>Beschreibung:</Text>
										</View>
										<View style={styles.text}>
											<Text>{ this.state.printData[index].description }</Text>
										</View>
									</View>
									{ this.state.printData[index].upload &&
										<Image
											style={styles.image}
											src={ 'https://backend.spl-hausbau.app/' + this.state.printData[index].upload }
										/> 
									}
									{ this.state.printData[index].upload2 &&
										<Image
											style={styles.image}
											src={ 'https://backend.spl-hausbau.app/' + this.state.printData[index].upload2 }
										/> 
									}
									{ this.state.printData[index].upload3 &&
										<Image
											style={styles.image}
											src={ 'https://backend.spl-hausbau.app/' + this.state.printData[index].upload3 }
										/> 
									}
									{ this.state.printData[index].upload4 &&
										<Image
											style={styles.image}
											src={ 'https://backend.spl-hausbau.app/' + this.state.printData[index].upload4 }
										/> 
									}
									{ this.state.printData[index].upload5 &&
										<Image
											style={styles.image}
											src={ 'https://backend.spl-hausbau.app/' + this.state.printData[index].upload5 }
										/> 
									}
									
								</>
							))}
							</Page>
						</Document>
					</PDFViewer>
				</>
			);
		}

		if (this.state.add == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addTicketBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Ticket hinzufügen</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								{ !this.state.project &&
									<div className="form-group">
										<label>Projekt</label>
										<select
											className="form-select"
											onChange={evt => this.updateInputValue(evt)}
											name="project"
											value={this.state.addTicket.project}
										>
											<option value="">Projekt wählen</option>
											{this.state.projects.map((option) => (
												<option key={option.id} value={option.id}>
													{ option.name}
												</option>
											))}
										</select>
									</div>
								}
								<div className="form-group">
									<label>Kategorie (optional)</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="category"
										value={this.state.addTicket.category}
									>
										<option value="">Kategorie wählen</option>
										<option key="1" value="After Sale">After Sale</option>	
										<option key="2" value="Rechnungen">Rechnungen</option>		
										<option key="3" value="Sonstiges">Sonstiges</option>	
										<option key="4" value="Mangel">Mangel</option>	
										<option key="5" value="Planung">Planung</option>
										<option key="6" value="Baustelle">Baustelle</option>	
										<option key="7" value="Werbung">Werbung</option>	
									</select>
								</div>
								<div className="form-group">
									<label>Zuständige Person</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="aid"
										value={this.state.addTicket.aid}
									>
										<option value="">Benutzer wählen</option>
										{this.state.users.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
								<div className="form-group">
									<label>Zuständige Person</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="aid2"
										value={this.state.addTicket.aid2}
									>
										<option value="">Benutzer wählen</option>
										{this.state.users.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
								<div className="form-group">
									<label>Titel*</label>
									<input
										type="text"
										name="title"
										value={this.state.addTicket.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Gewerk</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="gewerk"
										value={this.state.addTicket.gewerk}
									>
										<option value="">Gewerk wählen</option>
										<option>Beton</option>
										<option>Estrich</option>
										<option>Bewehrungsstahl</option>
										<option>Baustofflieferant</option>
										<option>Elementdecke</option>
										<option>Treppe</option>
										<option>Schwarzdecker</option>
										<option>Spengler</option>
										<option>Baumeister</option>
										<option>Zimmerer</option>
										<option>Bodengutachten</option>
										<option>Fenster/Türen</option>
										<option>Fenster/Türen Einbau</option>
										<option>Elektriker</option>
										<option>Installateur</option>
										<option>Erdbauer</option>
										<option>Mulden</option>
										<option>Baustellen WC</option>
										<option>Fassade</option>
										<option>Innenputz</option>
										<option>Infra Strom</option>
										<option>Infra Wasser</option>
										<option>Infra Telekom</option>
										<option>Infra Kanal</option>
										<option>Zaunmaterial</option>
										<option>Gebäudereinigung/Grünschnitt</option>
									</select>
								</div>
								<div className="form-group">
									<label>Firma</label>
									<input
										type="text"
										name="company"
										value={this.state.addTicket.company}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Beschreibung*</label>
									<textarea
										type="text"
										name="description"
										value={this.state.addTicket.description}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>							
								<div className="form-group">
									<label>Deadline</label>
									<input
										type="date"
										name="deadline"
										value={this.state.addTicket.deadline}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div>
									<label>Datei</label>
									<div>
									<input
										id="imageUpload"
										name="upload"
										type="file"
										className="mb-4"
										onChange={evt => this.updateInputValue(evt)}
									/>
									</div>
								</div>
								{ this.state.addTicket.upload &&
									<div>
										<div>
										<input
											id="imageUpload"
											name="upload2"
											type="file"
											className="mb-4"
											onChange={evt => this.updateInputValue(evt)}
										/>
										</div>
									</div>
								}
								{ this.state.addTicket.upload2 &&
									<div>
										<div>
										<input
											id="imageUpload"
											name="upload3"
											type="file"
											className="mb-4"
											onChange={evt => this.updateInputValue(evt)}
										/>
										</div>
									</div>
								}
								{ this.state.addTicket.upload3 &&
									<div>
										<div>
										<input
											id="imageUpload"
											name="upload4"
											type="file"
											className="mb-4"
											onChange={evt => this.updateInputValue(evt)}
										/>
										</div>
									</div>
								}
								{ this.state.addTicket.upload4 &&
									<div>
										<div>
										<input
											id="imageUpload"
											name="upload5"
											type="file"
											className="mb-4"
											onChange={evt => this.updateInputValue(evt)}
										/>
										</div>
									</div>
								}
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveTicket(e) }
								>Aufgabe erstellen</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Aufgaben</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.addTicket}
								>+</div>
							</div>
						</div>
					</div>
				</div>
				<div className="search-bar">
					<div className="container">
						<div className="form-group">
							{ this.state.clustered &&
								<input
									type="text"
									placeholder="Suchen"
									onKeyUp={this.searchTicket}
									disabled="true"
									className="disabled"
								/>
							}
							{ !this.state.clustered &&
								<input
									type="text"
									placeholder="Suchen"
									onKeyUp={this.searchTicket}
								/>
							}

						</div>
					</div>					
				</div>

				<div className="container">
					<div className="row">
						<div className="col-8 p-0">
							<div className="container switch-toggle">
								<div className="align-items-center">
									<ReactSwitch
										checked={this.state.clustered}
										onChange={this.changeCluster}
									/>
									<div className="d-inline label">Projektübersicht</div>
								</div>
							</div>	
						</div>
						<div className="col-4 text-end">
							<button
								type="submit"
								className="btn btn-secondary btn-sm"
								onClick={this.printView}
							>PDF-Export</button>
						</div>
					</div>	
				</div>

				{ this.state.showPrint &&
					<div className="btn-print-outer">
						<button
							type="submit"
							className="btn btn-success btn-block btn-print"
							onClick={this.printNow}
						>Auswahl als PDF speichern</button>
					</div>
				}
				<div className="container mt-2">
					<div className="row">
						<div className="col-12">
							<div className="filter p-2 border">
								<div className="row align-items-center">
									<div className="col-10">
										<strong>Filter: </strong>

										{ !this.state.customfiltered &&
											<span>Alle Tickets für mich</span>
										}

										{ this.state.customfiltered &&
											<span>
												{ this.state.customFilter.projectName &&
													<span>{ this.state.customFilter.projectName }</span>
												}
												{ !this.state.customFilter.projectName &&
													<span>Alle Projekte</span>
												}	
												{ this.state.customFilter.cidName &&									
													<span>, von { this.state.customFilter.cidName }</span>
												}
												{ this.state.customFilter.aidName &&									
													<span>, für { this.state.customFilter.aidName }</span>
												}
												{ this.state.customFilter.category &&									
													<span>, { this.state.customFilter.category }</span>
												}
												{ this.state.customFilter.gewerk &&									
													<span>, { this.state.customFilter.gewerk }</span>
												}
											</span>
										}
									</div>
									<div className="col-2 text-end">
										{ !this.state.showFilter &&
											<img
												className="filter-icon"
												src="/icon/filter.svg"
												onClick={this.showFilter}
											/>
										}
										{ this.state.showFilter &&
											<img
												className="filter-icon"
												src="/icon/close.svg"
												onClick={this.hideFilter}
											/>
										}
									</div>
								</div>

								{ this.state.showFilter &&
									<div className="filterset">
										<div className="form-group mt-2">
											<label>Projekt</label>
											<select
												className="form-select"
												onChange={evt => this.updateCustomFilter(evt)}
												name="project"
												value={this.state.customFilter.project}
											>
												<option value="">Alle Projekte</option>
												{this.state.projects.map((option) => (
													<option key={option.id} value={option.id}>
														{ option.name}
													</option>
												))}
											</select>
										</div>

										<div className="form-group">
											<label>Ticket erstellt von</label>
											<select
												className="form-select"
												onChange={evt => this.updateCustomFilter(evt)}
												name="cid"
												value={this.state.customFilter.cid}
											>
												<option value="">Alle Benutzer</option>
												{this.state.users.map((option) => (
													<option key={option.id} value={option.id}>
														{ option.name}
													</option>
												))}
											</select>
										</div>

										<div className="form-group">
											<label>Ticket erstellt für</label>
											<select
												className="form-select"
												onChange={evt => this.updateCustomFilter(evt)}
												name="aid"
												value={this.state.customFilter.aid}
											>
												<option value="">Alle Benutzer</option>
												{this.state.users.map((option) => (
													<option key={option.id} value={option.id}>
														{ option.name}
													</option>
												))}
											</select>
										</div>
										<div className="form-group">
											<label>Kategorie</label>
											<select
												className="form-select"
												onChange={evt => this.updateCustomFilter(evt)}
												name="category"
												value={this.state.customFilter.category}
											>
												<option value="">Alle Kategorien</option>
												<option key="1" value="After Sale">After Sale</option>	
												<option key="2" value="Rechnungen">Rechnungen</option>		
												<option key="3" value="Sonstiges">Sonstiges</option>	
												<option key="4" value="Mangel">Mangel</option>	
												<option key="5" value="Planung">Planung</option>
												<option key="6" value="Baustelle">Baustelle</option>	
												<option key="7" value="Werbung">Werbung</option>
											</select>
										</div>
										<div className="form-group">
											<label>Gewerk</label>
											<select
												className="form-select"
												onChange={evt => this.updateCustomFilter(evt)}
												name="gewerk"
												value={this.state.customFilter.gewerk}
											>
												<option value="">Alle Gewerke</option>
												<option>Beton</option>
												<option>Estrich</option>
												<option>Bewehrungsstahl</option>
												<option>Baustofflieferant</option>
												<option>Elementdecke</option>
												<option>Treppe</option>
												<option>Schwarzdecker</option>
												<option>Spengler</option>
												<option>Baumeister</option>
												<option>Zimmerer</option>
												<option>Bodengutachten</option>
												<option>Fenster/Türen</option>
												<option>Fenster/Türen Einbau</option>
												<option>Elektriker</option>
												<option>Installateur</option>
												<option>Erdbauer</option>
												<option>Mulden</option>
												<option>Baustellen WC</option>
												<option>Fassade</option>
												<option>Innenputz</option>
												<option>Infra Strom</option>
												<option>Infra Wasser</option>
												<option>Infra Telekom</option>
												<option>Infra Kanal</option>
												<option>Zaunmaterial</option>
												<option>Gebäudereinigung/Grünschnitt</option>
											</select>
										</div>
										<div className="form-group">
											<label>Sortierung</label>
											<select
												className="form-select"
												onChange={evt => this.updateCustomFilter(evt)}
												name="sort"
												value={this.state.customFilter.sort}
											>
												<option key="1" value="date">Neueste zuerst</option>	
												<option key="2" value="deadline">Nach Deadline</option>		
											</select>
										</div>
									</div>
								}
							</div>							
						</div>
					</div>
				</div>
				<div className="container mt-0">

					{ this.state.clustered &&
						<div className="row mt-4">
							{ clustereddata.length < 1 &&
								<div className="col-12">
									<p>Es gibt keine offenen Tickerts</p>
								</div>
							}
							<div className="col-12">
								{Object.keys(clustereddata).map(key => (
								  <div key={key}>
								    <p className="blue mt-3">
								      <span className="row">
								        <span className="col-9">
								          {clustereddata[key].projectName}
								        </span>
								        <span className="col-3 text-end">
								          Offen: {clustereddata[key].items.length}
								        </span>
								      </span>
								    </p>

								    <div className="row tickets">
								      {clustereddata[key].items.map(item => (
										<div
											key={item.id}
											className={`ticket col-12`}
										>
											<>
												{ this.state.showPrint &&
													<div class="select-for-print">
														<input
															class="form-check-input"
															type="checkbox"
															value={item.id}
															id={item.id}
															onChange={() => this.printSelection(item.id)}
														/>
													</div>
												}
												<Link onClick={this.handleLinkClick} to={item.type == 'invoice' ? `/invoice/${item.id}` : `/tickets/${item.id}`}>
													<div className="row">
														<div className="col-8">
															<div className="title">{ item.title }</div>
														</div>
														<div className="col-4 text-end">
															<img src="/icon/detail.svg" className="details" />
														</div>
													</div>	

													<div className="info row">
														<div className="col-4">
															<strong>Status: </strong>
														</div>
														<div className="col-8">
															{ item.state == 1 &&
																<span>Neu</span>
															}
															{ item.state == 2 &&
																<span>Offen</span>
															}
															{ item.state == 3 &&
																<span>Erledigt</span>
															}
														</div>
													</div>
													{ item.gewerk &&
														<div className="info row">
															<div className="col-4">
																<strong>Gewerk: </strong>
															</div>
															<div className="col-8">
																{ item.gewerk }
															</div>
														</div>
													}
													<div className="info row">
														<div className="col-4">
															<strong>Erstellt von: </strong>
														</div>
														<div className="col-8">
															{ item.cname }
														</div>
													</div>
													<div className="info row">
														<div className="col-4">
															<strong>Erstellt für: </strong>
														</div>
														<div className="col-8">
															{ item.aname }
															{ item.aname2 !== ' ' &&
																<>, { item.aname2 }</>
															}
															
														</div>
													</div>
													<div className="info row">
														<div className="col-4">
															<strong>Erstellt am: </strong>
														</div>
														<div className="col-8">
															{ item.created }
														</div>
													</div>
													{ item.done &&
														<div className="info row">
															<div className="col-4">
																<strong>Erledigt am: </strong>
															</div>
															<div className="col-8">
																{ item.done }
															</div>
														</div>
													}
													{ item.doneby &&
														<div className="info row">
															<div className="col-4">
																<strong>Erledigt von: </strong>
															</div>
															<div className="col-8">
																{ item.doneby }
															</div>
														</div>
													}

													{ item.projectname &&
														<div className="info row">
															<div className="col-4">
																<strong>Projekt: </strong>
															</div>
															<div className="col-8">
																{ item.projectname }
															</div>
														</div>
													}

													{ item.housename &&
														<div className="info row">
															<div className="col-4">
																<strong>Haus: </strong>
															</div>
															<div className="col-8">
																{ item.housename }
															</div>
														</div>
													}
													{ item.deadline &&
														<div className="info row">
															<div className="col-4">
																<strong>Deadline: </strong>
															</div>
															<div className="col-8">
																{ item.deadline }
															</div>
														</div>
													}


													<hr className="mb-0"/>	
												</Link>		
											</>					
										</div>
								      ))}
								    </div>
								  </div>
								))}
							</div>
						</div>
					}

					{ !this.state.clustered &&
					<div className="row mt-4">
						<div className="col-12">
							{ !this.state.filter &&
								<>
									{Object.keys(states).map(key => (
										<div key={states[key].id}>
											{ key == 0 &&
												<p className="red mt-3">{ states[key].name } ({ data[states[key].id].length })</p>
											}
											{ key == 1 &&
												<p className="orange mt-3">{ states[key].name } ({ data[states[key].id].length })</p>
											}
											{ key == 2 &&
												<p className="blue mt-3">{ states[key].name } ({ data[states[key].id].length })</p>
											}
											{ data[states[key].id].length > 0 &&
												<div className="row tickets">
													{Object.keys(data[states[key].id]).map(index => (
													<div
														key={index}
														 className={`ticket ticket-${key} col-12`}
													>
														{index == 0 &&
															<hr className="mt-0" />
														}
														<>
															{ this.state.showPrint &&
																<div class="select-for-print">
																	<input
																		class="form-check-input"
																		type="checkbox"
																		value={data[states[key].id][index].id}
																		id={data[states[key].id][index].id}
																		onChange={() => this.printSelection(data[states[key].id][index].id)}
																	/>
																</div>
															}
															<Link onClick={this.handleLinkClick} to={data[states[key].id][index].type == 'invoice' ? `/invoice/${data[states[key].id][index].id}` : `/tickets/${data[states[key].id][index].id}`}>
																<div className="row">
																	<div className="col-8">
																		<div className="title">{ data[states[key].id][index].title }</div>
																	</div>
																	<div className="col-4 text-end">
																		<img src="/icon/detail.svg" className="details" />
																	</div>
																</div>
																{ data[states[key].id][index].unread > 0 &&
																	<div class="unread-messages">
																		{ data[states[key].id][index].unread }
																	</div>
																}
																<div className="info row">
																	<div className="col-4">
																		<strong>Status: </strong>
																	</div>
																	<div className="col-8">
																		{ data[states[key].id][index].state == 1 &&
																			<span>Neu</span>
																		}
																		{ data[states[key].id][index].state == 2 &&
																			<span>Offen</span>
																		}
																		{ data[states[key].id][index].state == 3 &&
																			<span>Erledigt</span>
																		}
																	</div>
																</div>
																{ data[states[key].id][index].gewerk &&
																	<div className="info row">
																		<div className="col-4">
																			<strong>Gewerk: </strong>
																		</div>
																		<div className="col-8">
																			{ data[states[key].id][index].gewerk }
																		</div>
																	</div>
																}
																<div className="info row">
																	<div className="col-4">
																		<strong>Erstellt von: </strong>
																	</div>
																	<div className="col-8">
																		{ data[states[key].id][index].cname }
																	</div>
																</div>
																<div className="info row">
																	<div className="col-4">
																		<strong>Erstellt für: </strong>
																	</div>
																	<div className="col-8">
																		{ data[states[key].id][index].aname }
																		{ data[states[key].id][index].aname2 !== ' ' &&
																			<>, { data[states[key].id][index].aname2 }</>
																		}
																		
																	</div>
																</div>
																<div className="info row">
																	<div className="col-4">
																		<strong>Erstellt am: </strong>
																	</div>
																	<div className="col-8">
																		{ data[states[key].id][index].created }
																	</div>
																</div>
																{ data[states[key].id][index].done &&
																	<div className="info row">
																		<div className="col-4">
																			<strong>Erledigt am: </strong>
																		</div>
																		<div className="col-8">
																			{ data[states[key].id][index].done }
																		</div>
																	</div>
																}
																{ data[states[key].id][index].doneby &&
																	<div className="info row">
																		<div className="col-4">
																			<strong>Erledigt von: </strong>
																		</div>
																		<div className="col-8">
																			{ data[states[key].id][index].doneby }
																		</div>
																	</div>
																}

																{ data[states[key].id][index].projectname &&
																	<div className="info row">
																		<div className="col-4">
																			<strong>Projekt: </strong>
																		</div>
																		<div className="col-8">
																			{ data[states[key].id][index].projectname }
																		</div>
																	</div>
																}

																{ data[states[key].id][index].housename &&
																	<div className="info row">
																		<div className="col-4">
																			<strong>Haus: </strong>
																		</div>
																		<div className="col-8">
																			{ data[states[key].id][index].housename }
																		</div>
																	</div>
																}
																{ data[states[key].id][index].deadline &&
																	<div className="info row">
																		<div className="col-4">
																			<strong>Deadline: </strong>
																		</div>
																		<div className="col-8">
																			{ data[states[key].id][index].deadline }
																		</div>
																	</div>
																}
															</Link>
														</>
														<hr className="mb-0"/>
													</div>
													))}
												</div>
											}
											{ data[states[key].id].length < 1 &&
												<hr/>
											}

										</div>
									))}
								</>
							}

							{ this.state.filter &&
								<div className="row tickets">
									{ this.state.message && 
										<p className="mt-5 text-center">{ this.state.message }</p>
									}
									{Object.keys(filterdata).map(index => (
										<div
											key={index}
											className="ticket col-12"
										>
											{index == 0 &&
												<hr className="mt-0" />
											}

											<Link onClick={this.handleLinkClick} to={filterdata[index].type == 'invoice' ? `/invoice/${filterdata[index].id}` : `/tickets/${filterdata[index].id}`}>
												<div className="row">
													<div className="col-8">
														<div className="title">{ filterdata[index].title }</div>
													</div>
													<div className="col-4 text-end">
														<img src="/icon/detail.svg" className="details" />
													</div>
												</div>
												{ filterdata[index].gewerk &&
													<div className="info row">
														<div className="col-4">
															<strong>Gewerk: </strong>
														</div>
														<div className="col-8">
															{ filterdata[index].gewerk }
														</div>
													</div>
												}
												<div className="info row">
													<div className="col-4">
														<strong>Erstellt von: </strong>
													</div>
													<div className="col-8">
														{ filterdata[index].cname }
													</div>
												</div>
												<div className="info row">
													<div className="col-4">
														<strong>Erstellt für: </strong>
													</div>
													<div className="col-8">
														{ filterdata[index].aname }
														{ filterdata[index].aname2 !== ' ' &&
															<>, { filterdata[index].aname2 }</>
														}
														
													</div>
												</div>
												<div className="info row">
													<div className="col-4">
														<strong>Erstellt am: </strong>
													</div>
													<div className="col-8">
														{ filterdata[index].created }
													</div>
												</div>

												{ filterdata[index].projectname &&
													<div className="info row">
														<div className="col-4">
															<strong>Projekt: </strong>
														</div>
														<div className="col-8">
															{ filterdata[index].projectname }
														</div>
													</div>
												}

												{ filterdata[index].housename &&
													<div className="info row">
														<div className="col-4">
															<strong>Haus: </strong>
														</div>
														<div className="col-8">
															{ filterdata[index].housename }
														</div>
													</div>
												}
												{ filterdata[index].deadline &&
													<div className="info row">
														<div className="col-4">
															<strong>Deadline: </strong>
														</div>
														<div className="col-8">
															{ filterdata[index].deadline }
														</div>
													</div>
												}
											</Link>
											<hr className="mb-0"/>
										</div>
									))}
								</div>
							}

						</div>
					</div>
					}
				</div>
			</>
		);
	}

}

export default withParams(Tickets);
