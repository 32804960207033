import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { ProjectList } from "./Parts/Projects.js";
import { getAllProjects, getProjects, getCoordinates, addNewProject } from '../helper/restapi.js';

import axios from 'axios';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

class Contacts extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
			message: '',
			userId: '',
			userRole: '',
			userFirstname: '',
			location: '',
			add: false,
			addProject: {
				name: '',
				plz: '',
				city: '',
				lat: '',
				lon: '',
				gstnr: '',
				street: '',
				company: '',
			},
			showFilter: false,
			filter: [],
			filterset: {
				states: '',
			},
		};
	}

	async getAllProjects() {
		const response = await getAllProjects();
		this.setState({ data: response.data });
		this.setState({ filter: response.data.filter });
		this.setState({ isReady: true });
	}

	async getProjects(filters) {
		const response = await getProjects(filters);
		this.setState({ data: response.data });
		this.setState({ isReady: true });
	}

	async getCoordinates(address) {
		const response = await getCoordinates(address);	
		return response;
	}

	componentDidMount() {
		this.getAllProjects();
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ location: this.props.location.state })
	}

	searchProject = (event) => {
		var searchValue = event.target.value;
		searchValue = searchValue.toLowerCase();

		var originalData = this.state.data;

		if (searchValue.length > 0) {
			var data = originalData.data;
			var filtered = [];

			for(var i=0; i < data.length; i++) {

				var searchIn = data[i]['name'] + ' ' + data[i]['plz'] + ' ' + data[i]['city'];
				searchIn = searchIn.toLowerCase();

				if(searchIn.indexOf(searchValue)!=-1) {
					filtered.push(data[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
				this.setState({ data: originalData });
			}

			var filteredData = {...originalData};
			filteredData.data = filtered;

			this.setState({ data: filteredData });			
		} else {
			this.getAllProjects();
			this.setState({ message: '' });
		}

	}

	addProject = () => {
		this.setState({ add: true });
	}

	addProjectBack = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({
			addProject: {
				...this.state.addProject,
				[name]: val
			}
		});
	}

	updateFilterValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({
			filterset: {
				...this.state.filterset,
				[name]: val
			}
		}, () => {
			this.getProjects(this.state.filterset);
		});	
	}

	saveContact = () => {
		var projectData = this.state.addProject;

		if (projectData.name && projectData.plz && projectData.city && projectData.company) {
			this.setState({ isReady: false });
			this.getCoordinates(projectData.plz + ' ' + projectData.city).then(coordinates => {

				projectData.lat = coordinates[0].lat;
				projectData.lon = coordinates[0].lon;

				this.addProjectData(projectData);
			});
		} else {
			alert('Bitte alle Felder ausfüllen!');
		}
	}

	async addProjectData(projectData) {
		this.setState({ isReady: true });
		const response = await addNewProject(projectData);
		if (response.data.success) {
			this.setState({ add: false });
			this.getAllProjects();
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	showFilter = () => {
		this.setState({ showFilter: true });
	}
	hideFilter = () => {
		this.setState({ showFilter: false });
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.add == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addProjectBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Projekt hinzufügen</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Name</label>
									<input
										type="text"
										name="name"
										//value={this.state.addProject.name}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>PLZ</label>
									<input
										type="number"
										name="plz"
										value={this.state.addProject.plz}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ort</label>
									<input
										type="text"
										name="city"
										value={this.state.addProject.city}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Firma</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="company"
										value={this.state.addProject.company}
									>
										<option value="">Auswählen</option>
										<option value="1">SPL Hausbau</option>
										<option value="2">SPL Terrassenberg</option>
										<option value="3">SPL Gartengasse</option>
										<option value="4">SPL Projektgesellschaft</option>
										<option value="5">SP Liegenschaftsverwaltung "Felixdorf"</option>
										<option value="6">SP Vermögensverwaltung GmbH</option>
										<option value="7">SP Liegenschaftsverwaltung "Felixdorf"</option>
										<option value="8">SP Vermögensverwaltung GmbH</option>
										<option value="9">Dr. Schnabl & Partner OG</option>
										<option value="10">Dr. Norbert Schnabl</option>
										<option value="11">Roland Pataki</option>
									</select>
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveContact(e) }
								>Projekt erstellen</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Projekte</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.addProject}
								>+</div>
							</div>
						</div>
					</div>
				</div>
				<div className="search-bar">
					<div className="container">
						<div className="row">
							<div className="col-10">
								<div className="form-group">
									<input
										type="text"
										placeholder="Suchen"
										onKeyUp={this.searchProject}
									/>
								</div>
							</div>
							<div className="col-2 text-end">
								{ !this.state.showFilter &&
									<img
										className="filter-icon"
										src="/icon/filter.svg"
										onClick={this.showFilter}
									/>
								}
								{ this.state.showFilter &&
									<img
										className="filter-icon"
										src="/icon/close.svg"
										onClick={this.hideFilter}
									/>
								}
							</div>
						</div>
					</div>					
				</div>
				<div className="container">
					{ this.state.showFilter &&
						<div className="filter">
							{ this.state.filter &&
								<>
									<div className="form-group">
										<label>Status</label>
										<select
											className="form-select"
											onChange={evt => this.updateFilterValue(evt)}
											name="states"
											value={this.state.filterset.states}
										>
											<option value="">Alle</option>
											{this.state.filter.states.map((option) => (
												<option key={option.id} value={option.id} selected={option.id === this.state.filterset.states}>
													{ option.name}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label>PLZ</label>
										<select
										    className="form-select"
										    onChange={evt => this.updateFilterValue(evt)}
										    name="plz"
										    value={this.state.filterset.plz}
										>
										    <option value="">Alle</option>
										    {Object.entries(this.state.filter.plz).map(([key, value]) => (
										        <option key={key} value={value} selected={key === this.state.filterset.plz}>
										            {value}
										        </option>
										    ))}
										</select>

									</div>
									<div className="form-group">
										<label>Ort</label>
										<select
										    className="form-select"
										    onChange={evt => this.updateFilterValue(evt)}
										    name="city"
										    value={this.state.filterset.city}
										>
										    <option value="">Alle</option>
										    {Object.entries(this.state.filter.city).map(([key, value]) => (
										        <option key={key} value={value} selected={key === this.state.filterset.city}>
										            {value}
										        </option>
										    ))}
										</select>
									</div>
								</>
							}
						</div>
					}
				</div>
				<div className="container">
					{ this.state.message && 
						<p className="mt-5 text-center">{ this.state.message }</p>
					}
					<ProjectList data={ data } />
				</div>
			</>
		);
	}

}

export default withParams(Contacts);
