import React from 'react';
import ReactDOM from 'react-dom';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { HeaderLoading, GoBack, GoBackDel } from "./Parts/Header.js";
import { getProjectById, addNewHouseApi, deleteHouse, editHouse } from '../helper/restapi.js';

import { Tiles } from "./Parts/Tiles.js";
import { HouseList } from "./Parts/Projects.js";



function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}


class ProjectDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			id: '',
			project: '',
			data: [],
			tiles: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			location: '',
			edit: false,
			newHouseName: '',
			newHouseStreet: '',
			newHouseZip: '',
			newHouseCity: '',
		};
	}

	async getHouse(pid, id) {
		const response = await getProjectById(pid);

		const houses = response.data.data.houses;
		const house = houses.find(item => item.id === parseInt(id));

		this.setState({
			newHouseName: house.name,
			newHouseStreet: house.street,
			newHouseZip: house.zip,
			newHouseCity: house.city,
		});

		house.name = response.data.data.name + ' > ' + house.name;

		this.setState({ data: house });

		const tiles = [
			// {
			// 	'order': 1,
			// 	'title': 'Aufgaben',
			// 	'icon': 'docs',
			// 	'route': 'tel:',
			// 	'route': `/projects/${pid}/houses/${id}/tickets`,
			// },
			{
				'order': 2,
				'title': 'Kontakte',
				'icon': 'contacts',
				'route': `/projects/${pid}/houses/${id}/contacts`,
			},
			{
				'order': 3,
				'title': 'Pläne',
				'icon': 'docs',
				'route': `/projects/${pid}/houses/${id}/plans`,
			},
			{
				'order': 4,
				'title': 'Dokumente',
				'icon': 'docs',
				'route': `/projects/${pid}/houses/${id}/files`,
			},
			// {
			// 	'order': 5,
			// 	'title': 'Dokumentation',
			// 	'icon': 'notes',
			// 	'route': `/projects/${pid}/houses/${id}/notes/`,
			// },

		];

		this.setState({ tiles: tiles});
		this.setState({ isReady: true });
	}

	componentDidMount() {
		let { pid, id } = this.props.params;

		this.getHouse(pid, id);
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ location: this.props.location.state });
	}

	deleteHouseConfirm = (id) => {
		const shouldClose = window.confirm('Möchten Sie das Haus löschen?');
		if (shouldClose) {
			this.deleteHouse(id);
			this.setState({ isReady: false });
		}
	};

	toggleEdit() {
		if (this.state.edit) {
			this.setState({ edit: false });
		} else {
			this.setState({ edit: true });
		}
	}

	async deleteHouse(id) {

		const response = await deleteHouse(id);
		if (response.data.success) {
			window.history.go(-1)
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'name') {
			this.setState({ newHouseName: val });
		}

		if (name == 'street') {
			this.setState({ newHouseStreet: val });
		}

		if (name == 'zip') {
			this.setState({ newHouseZip: val });
		}

		if (name == 'city') {
			this.setState({ newHouseCity: val });
		}
		
	}

	saveHouse = () => {
		var newHouseName = this.state.newHouseName;
		var newHouseStreet = this.state.newHouseStreet;
		var newHouseZip = this.state.newHouseZip;
		var newHouseCity = this.state.newHouseCity;

		var houseId = this.props.params.id;

		if (newHouseName && houseId) {
			//this.setState({ isReady: false });
			this.addNewHouseData(newHouseName, newHouseStreet, newHouseZip, newHouseCity, houseId);
		} else {
			alert('Bitte alle Felder ausfüllen!');
		}
	}

	async addNewHouseData(newHouseName, newHouseStreet, newHouseZip, newHouseCity, houseId) {

		const response = await editHouse(newHouseName, newHouseStreet, newHouseZip, newHouseCity, houseId);

		if (response.data.success) {
			this.setState({ edit: false });
			this.getHouse(this.props.params.pid, this.props.params.id);
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}


	render() {

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.edit) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={() => this.toggleEdit()}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Haus bearbeiten</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Name</label>
									<input
										type="text"
										name="name"
										value={this.state.newHouseName}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Straße</label>
									<input
										type="text"
										name="street"
										value={this.state.newHouseStreet}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>PLZ</label>
									<input
										type="text"
										name="zip"
										value={this.state.newHouseZip}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Ort</label>
									<input
										type="text"
										name="city"
										value={this.state.newHouseCity}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveHouse(e) }
								>Speichern</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-1">
								<GoBack />
							</div>
							<div className="col-10 text-center">
								<h1>Haus</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-6">
							<h2>{ this.state.data.name }</h2>
							<p>
							{ this.state.data.street &&
								<span>{this.state.data.street}, </span>
							}
							{ this.state.data.zip &&
								<span>{this.state.data.zip} </span>
							}
							{ this.state.data.city &&
								<span>{this.state.data.city}</span>
							}
							</p>
						</div>
						<div className="col-6 text-end">
							{ this.state.userRole == 1 &&
								<div>
								<div
									className="btn btn-secondary mt-3 mb-3 mr-1"
									onClick={() => this.toggleEdit()}
								>Bearbeiten</div>
								<div
									className="btn btn-danger mt-3 mb-3"
									onClick={() => this.deleteHouseConfirm(this.props.params.id)}
								>Löschen</div>
								</div>
							}
						</div>
					</div>
					<Tiles data={ this.state.tiles } />
				</div>
			</>
		);
	}

}

export default withParams(ProjectDetails);
