import React, { Component, useRef } from 'react';
import { useParams } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";

import WebViewer, { AnnotationCreateToolNames } from '@pdftron/webviewer';
import { getFilesById, updateFile, editFileById, deleteFileById, getTickets } from '../helper/restapi.js';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class FilesPdf extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			file: '',
			annotations: [],
			data: [],
			tickets: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			userLastname: '',
			edit: false,
			newTitle: '',
		}
		this.viewer = React.createRef();
	}

	async getFile(id) {
		const response = await getFilesById(id);
		this.setState({ data: response.data.data });
		this.setState({ newTitle: response.data.data.title });

		var project = response.data.data.project;
		
		const ticketResponse = await getTickets(null, null, null, null, project, null);

		const object1 = ticketResponse.data.data[1];
		const object2 = ticketResponse.data.data[2];
		const object3 = ticketResponse.data.data[3];

		var tickets = [];
		var count = 0;
		for (const key in object1) {
			if (object1[key].plan) {
				if (object1[key].plan == id) {
					tickets[count++] = object1[key];
				}
			}
		}	
		for (const key in object2) {
			if (object2[key].plan) {
				if (object2[key].plan == id) {
					tickets[count++] = object2[key];
				}
			}
		}		
		for (const key in object3) {
			if (object3[key].plan) {
				if (object3[key].plan == id) {
					tickets[count++] = object3[key];
				}
			}
		}

		this.setState({ tickets: tickets });

		var comments = [];

		if (response.data.data.comments) {
			if (response.data.data.comments.length > 0) {
				var comments = response.data.data.comments;
				comments = JSON.parse(comments);
			}			
		}

		this.initializeWebViewer(response.data.data.fullpath, comments, tickets);
	}

	initializeWebViewer(file, comments, tickets) {

			const getMouseLocation = e => {
			  const scrollElement = document.getElementById('webviewer-1');
			  const scrollLeft = scrollElement.scrollLeft || 0;
			  const scrollTop = scrollElement.scrollTop || 0;

			  return {
			    x: e.pageX + scrollLeft,
			    y: e.pageY + scrollTop
			  };
			};

			WebViewer(
				{
					path: '/webviewer',
					initialDoc: file,
					fullAPI: true,
				},
				this.viewer.current,
			).then((instance) => {

				instance.UI.disableElements(['underlineToolGroupButton']);

				instance.UI.disableElements(['highlightToolGroupButton']);
				instance.UI.disableElements(['shapeToolGroupButton']);
				instance.UI.disableElements(['freeTextToolGroupButton']);
				instance.UI.disableElements(['freeHandHighlightToolGroupButton']);
				instance.UI.disableElements(['freeHandToolGroupButton']);
				instance.UI.disableElements(['squigglyToolGroupButton']);
				instance.UI.disableElements(['strikeoutToolGroupButton']);
				instance.UI.disableElements(['viewControlsButton']);
				instance.UI.disableElements(['toolsOverlay']);
				instance.UI.disableElements(['undoButton']);
				instance.UI.disableElements(['redoButton']);
				instance.UI.disableElements(['eraserToolButton']);
				instance.UI.disableElements(['leftPanelButton']);
				instance.UI.disableElements(['menuButton']);
				instance.UI.disableElements(['ribbonsDropdown']);
				instance.UI.disableElements(['ribbons']);
				
				const { docViewer, documentViewer, annotationManager, Annotations, UI, Actions } = instance.Core;

				// let capturePosition = false;

				// instance.UI.setHeaderItems(function (header) {
				// 	header.getHeader('toolbarGroup-Annotate').push({
				// 		type: 'actionButton',
				// 		onClick: () => {
				// 			capturePosition = true;
				// 			alert('Klicken Sie auf die Position im File um ein Ticket zu erstellen!');
				// 		},
				// 		dataElement: 'alertButton',
				// 		img: 'https://spl-hausbau.app/icon/add.svg',
				// 		title: 'Aufgabe erstellen'
				// 	});
				// });

				// documentViewer.addEventListener('mouseLeftDown', (e) => {
				// 	if (capturePosition) {
				// 		console.log(e)
				// 		const windowCoordinates = getMouseLocation(e);
				// 		const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
				// 		const page = displayMode.getSelectedPages(windowCoordinates, windowCoordinates);
				// 		const clickedPage = (page.first !== null) ? page.first : documentViewer.getCurrentPage();

				// 		const pageCoordinates = displayMode.windowToPage(windowCoordinates, clickedPage);

				// 		const annot = new Annotations.Link();
				// 		annot.PageNumber = 1;
				// 		annot.X = pageCoordinates.x;
				// 		annot.Y = pageCoordinates.y;
				// 		annot.Width = 50;
				// 		annot.Height = 50;

				// 		annot.StrokeColor = new Annotations.Color(0, 255, 0, 1);
				// 		annot.StrokeStyle = 'circle';
				// 		annot.StrokeThickness = 5;

				// 		annot.addAction(
				// 			'U',
				// 			new Actions.URI({
				// 				uri: window.location.origin + '/tickets/215',
				// 			}),
				// 		);

				// 		annotationManager.addAnnotation(annot);
				// 		annotationManager.redrawAnnotation(annot);	
				// 	}
				// });

				documentViewer.addEventListener('documentLoaded', () => {
					for (const key in comments) {
						const annot = new Annotations.StickyAnnotation({
							PageNumber: comments[key].page,
							X: comments[key].X,
							Y: comments[key].Y,
							Icon: Annotations.StickyAnnotation.IconNames.COMMENT,
						});
						annot.setContents(comments[key].text);
						annot.Author = comments[key].user;
						annot.DateCreated = comments[key].date;

						annotationManager.addAnnotation(annot);
						annotationManager.redrawAnnotation(annot);	
					}

					// for (const key in tickets) {
					// 	var coords = tickets[key].plancoords;
					// 	coords = coords.split(' ');
					// 	var X = coords[0];
					// 	var Y = coords[1];

					// 	const annot = new Annotations.Link();
					// 	annot.PageNumber = 1;
					// 	annot.X = X;
					// 	annot.Y = Y;
					// 	annot.Width = 50;
					// 	annot.Height = 50;

					// 	annot.StrokeColor = new Annotations.Color(0, 255, 0, 1);
					// 	annot.StrokeStyle = 'circle';
					// 	annot.StrokeThickness = 5;

					// 	annot.addAction(
					// 		'U',
					// 		new Actions.URI({
					// 			uri: window.location.origin + '/tickets/215',
					// 		}),
					// 	);

					// 	annotationManager.addAnnotation(annot);
					// 	annotationManager.redrawAnnotation(annot);	
					// }
				});

				annotationManager.setCurrentUser(this.state.myUserName);

				annotationManager.setAnnotationDisplayAuthorMap((annotationUserId) => {
					if (annotationUserId !== this.state.myUserName) {
						return annotationUserId;
					} else {
						return this.state.myUserName;
					}
				});

				annotationManager.addEventListener('annotationChanged', (annotations, action) => {
					if (action == 'modify' || action == 'delete') {
						const ticketsObj = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.EllipseAnnotation);
						console.log(ticketsObj);

						const annotationsObj = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.StickyAnnotation);
						this.saveComment(annotationsObj);	
					}
				});

				this.setState({ isReady: true });

			});
	}

	saveComment = (annotationsObj) => {
		
		var annotationsArray = [];

		var counter = annotationsObj.length;

		for (const key in annotationsObj) {
			var userName = annotationsObj[key]['PS'];
			if (key == counter-1) {
				userName = this.state.myUserName;
			}
			console.log(userName);
			annotationsArray.push({
				'user': userName,
				'X': annotationsObj[key]['cC'],
				'Y': annotationsObj[key]['dC'],
				'date': annotationsObj[key]['eT'],
				'text': annotationsObj[key]['hpa'],
				'page': annotationsObj[key]['RI'],
			})
		}

		annotationsArray = JSON.stringify(annotationsArray);
		this.addCommentToDb(annotationsArray);
	}

	async addCommentToDb(annotationsObj) {

		let { id } = this.props.params;
		const response = await updateFile(id, annotationsObj);

		console.log(response);
		// if (response.data.success) {
			
		// }
	}

	async componentDidMount() {
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ userLastname: this.props.user.user_lastname });
		this.setState({ myUserName: this.props.user.user_firstname + ' ' + this.props.user.user_lastname})

	    let { id } = this.props.params;
	    await this.getFile(id);
	}

	toggleEdit = () => {
		this.setState({ edit: true });
	}

	closeEdit = () => {
		this.setState({ edit: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'title') {
			this.setState({ newTitle: val });
		}
	}

	saveFile = () => {
		if (this.state.newTitle) {
			editFileById(this.state.data.id, this.state.newTitle);
			this.setState({ edit: false });
		}
	}

	deleteFile = () => {
		const fileId = this.state.data.id;
		const shouldClose = window.confirm('Möchten Sie die Datei löschen?');
		if (shouldClose) {
			deleteFileById(fileId);

			window.history.go(-1);
		}
	}

	render() {

		return (
			<>
				{!this.state.isReady  &&
					<HeaderLoading />
				}
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Dokumente</h1>
							</div>
							{ this.state.edit == true && this.state.userRole == 1 &&
								<div className="col-2 text-end">
									{ this.state.userRole == 1 &&
									<div
										className="add-link"
										onClick={this.closeEdit}
									>x</div>
									}
								</div>
							}
							{ !this.state.edit && this.state.userRole == 1 &&
								<div className="col-2 text-end">
									{ this.state.userRole == 1 &&
									<div
										className="add-link"
										onClick={this.toggleEdit}
									>✎</div>
									}
								</div>
							}
						</div>
					</div>
				</div>

				{ this.state.edit == true && this.state.userRole == 1 &&
					<div className="container mt-4">
						<div className="form-group">
							<label>Titel*</label>
							<input
								type="text"
								name="title"
								value={this.state.newTitle}
								className="form-control"
								onChange={evt => this.updateInputValue(evt)}
							/>
						</div>
						<button
							type="submit"
							className="btn btn-primary btn-block mb-2"
							onClick={ e => this.saveFile(e) }
						>Speichern</button>
						<button
							type="submit"
							className="btn btn-danger btn-block mb-5"
							onClick={ e => this.deleteFile(e) }
						>Dokument löschen</button>
					</div>
				}

				<div className="FilesPdf">
					<div className="webviewer" ref={this.viewer} style={{height: "calc(100vh - 107px)"}}></div>
				</div>
			</>
		);
	}
}

export default withParams(FilesPdf);