import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { checkToken } from './helper/restapi.js';

import Layout from "./views/Layout";
import Login from "./views/Login";
import Home from "./views/Home";
import Contacts from "./views/Contacts";
import ContactDetails from "./views/ContactDetails";
import Keys from "./views/Keys";
import KeyDetails from "./views/KeyDetails";
import Projects from "./views/Projects";
import ProjectDetails from "./views/ProjectDetails";
import HouseDetails from "./views/HouseDetails";
import Notes from "./views/Notes";
import Updates from "./views/Updates";
import Bauzeitplan from "./views/Bauzeitplan";
import Files from "./views/Files";
import Plans from "./views/Plans";
import FilesInvoices from "./views/FilesInvoices";
import FileInvoice from "./views/FileInvoice";
import FilesInternal from "./views/FilesInternal";
import FilesPdf from "./views/FilesPdf";
import Tickets from "./views/Tickets";
import TicketDetail from "./views/TicketDetail";
import User from "./views/User";
import Calendar from "./views/Calendar";
import Footer from "./views/Parts/Footer";

import { requestForToken, onMessageListener } from './firebase/firebase';


function Main(history) {

	const [user, setUser] = useState();
	//const location = useLocation();
	
	useEffect(() => {
		checkUserState();
	}, []);

	const checkUserState = async () => {
		const response = await checkToken();
		if (response.status == 200) {
			setUser(response.data);
		}
	}

	//requestForToken();

	onMessageListener()
	.then((payload) => {
		if (window.confirm(payload.notification.title)) {
			if (payload.notification.title.includes("Kommentar")) {
				if (payload.notification.title.includes("|")) {
					const parts = payload.notification.title.split("|");
					const partAfterPipe = parts[1].trim();
					window.location.href='/tickets/' + partAfterPipe + '?comment=true';
				} else {
					window.location.href='/tickets';
				}
				
			} else {
				// new ticket
				window.location.href='/tickets';
			}
			
		};   
	})
	.catch((err) => console.log('failed: ', err));

	if (!user) {
		return (
			<Routes>
				<Route index element={<Login />} />
			</Routes>
		);
	}

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home user={user} />} />
				<Route path="contacts" element={<Contacts user={user} />} />
				<Route path="contacts/:id" element={<ContactDetails user={user} />} />
				<Route path="keys" element={<Keys user={user} />} />
				<Route path="keys/:id" element={<KeyDetails user={user} />} />
				<Route path="projects" element={<Projects user={user} />} />
				<Route path="projects/:id" element={<ProjectDetails user={user} />} />
				<Route path="projects/:pid/houses/:id" element={<HouseDetails user={user} />} />
				<Route path="projects/:id/notes" element={<Notes user={user} />} />
				<Route path="projects/:id/bauzeitplan" element={<Bauzeitplan user={user} />} />
				<Route path="projects/:pid/houses/:id/notes" element={<Notes user={user} />} />
				<Route path="projects/:id/files" element={<Files user={user} />} />
				<Route path="projects/:id/filesanbote" element={<Files user={user} type="anbote" />} />
				<Route path="projects/:id/filesauftraege" element={<Files user={user} type="auftraege" />} />
				<Route path="projects/:id/plans" element={<Plans user={user} />} />
				<Route path="projects/:id/invoices" element={<FilesInvoices user={user} />} />
				<Route path="projects/:id/contacts" element={<Contacts user={user} />} />
				<Route path="projects/:pid/houses/:id/contacts" element={<Contacts user={user} />} />
				<Route path="projects/:pid/houses/:id/files" element={<Files user={user} />} />
				<Route path="projects/:pid/houses/:id/plans" element={<Plans user={user} />} />
				<Route path="notes" element={<Notes user={user} />} />
				<Route path="updates" element={<Updates user={user} />} />
				<Route path="files/pdf/:id" element={<FilesPdf user={user} />} />
				<Route path="tickets" element={<Tickets user={user} />} />
				<Route path="tickets/:id" element={<TicketDetail user={user} />} />
				<Route path="projects/:id/tickets" element={<Tickets user={user} />} />
				<Route path="projects/:pid/houses/:id/tickets" element={<Tickets user={user} />} />
				<Route path="files" element={<FilesInternal user={user} />} />
				<Route path="invoice/:id" element={<FileInvoice user={user} />} />
				<Route path="user" element={<User user={user} />} />
				<Route path="calendar" element={<Calendar user={user} />} />
			</Route>
		</Routes>
	);
}


export default function App() {
	return (
		<BrowserRouter>
			<Main/>
			<Footer/>
		</BrowserRouter>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);