import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";
import { getBauzeitPlan, editBauzeit } from '../helper/restapi.js';
import { HeaderLoading, GoBack, Loading } from "./Parts/Header.js";

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

class Bauzeitplan extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			userId: '',
			userRole: '',
			userFirstname: '',
			project: null,
			house: null,
			data: [],
			currentWeek: null,
			edit: false,
			projectName: '',
		};
		this.renderTable = this.renderTable.bind(this);
	}

	async getBauzeitPlan(project) {

		const response = await getBauzeitPlan(project);
		this.setState({ data: response.data.data });
		this.setState({ isReady: true });
		this.setState({ projectName: response.data.projectname });

	}

	componentDidMount() {
		
		this.setState({ isReady: false });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ location: this.props.location.state });

		var currentDate = new Date();
		var startDate = new Date(currentDate.getFullYear(), 0, 1);
		var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

		var weekNumber = Math.ceil(days / 7);
		this.setState({ currentWeek: weekNumber });

		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');
			if (checkProjectHouse[1] == 'projects') {
				this.setState({ project: checkProjectHouse[2] })
				this.getBauzeitPlan(checkProjectHouse[2]);
			}			
		}

	}

	editBauzeitplan = () => {
		this.setState({ edit: true });
	}

	editBauzeitplanBack = () => {
		this.setState({ edit: false });
	}

	renderTable() {
	  const { data, currentWeek } = this.state;
	  const currentYear = new Date().getFullYear();
	  const weeks = new Array(52).fill().map((_, i) => i + currentWeek);
	  return (
	  	<div style={{ overflowX: "auto" }}>
	    <table className="table table-bordered" style={{ maxWidth: "100%" }}>
	      <thead>
	        <tr>
	          <th></th>
	          {weeks.map((week) => (
	            <th key={week} style={{backgroundColor: week === currentWeek ? "#E8E8E8" : ""}}>{week <= 52 ? week : week - 52} {week <= 52 ? currentYear : currentYear + 1}</th>
	          ))}
	        </tr>
	      </thead>
	      <tbody>
	        {data.map((item) => {
	          const fromWeekString = item["kw-from"];
	          const toWeekString = item["kw-to"];
	          const name = item["name"];
	          if (!fromWeekString || !toWeekString) {
	            return null; // skip this item if from/to week is missing
	          }
	          const fromYear = parseInt(fromWeekString.split("-")[0], 10);
	          const toYear = parseInt(toWeekString.split("-")[0], 10);
	          const fromWeek = parseInt(fromWeekString.split("-")[1], 10);
	          const toWeek = parseInt(toWeekString.split("-")[1], 10);
	          const startingWeek = fromYear === currentYear ? fromWeek - currentWeek : fromWeek + 52 - currentWeek;
	          const endingWeek = toYear === currentYear ? toWeek - currentWeek : toWeek + 52 - currentWeek;
	          return (
	            <tr key={name} style={{backgroundColor: startingWeek <= currentWeek && endingWeek >= currentWeek ? "#399FD6" : ""}}>
	              <td className="text-nowrap">{name}</td>
	              {weeks.map((week, index) => {
	                if (index >= startingWeek && index <= endingWeek) {
	                  return (
	                    <td
	                      key={`${name}-${week}`}
	                      style={{
	                        backgroundColor: week === currentWeek - 1 ? "" : "#399FD6",
	                      }}
	                    ></td>
	                  );
	                } else {
	                  return <td key={`${name}-${week}`}></td>;
	                }
	              })}
	            </tr>
	          );
	        })}
	      </tbody>

	    </table>
	    </div>
	  );
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		const split = name.split('/');
		const id = parseInt(split[1]);
		const field = split[0];

		const updatedData = this.state.data.map(item => {
			if (item.id === id) {
				if (field === "week-from") {
					const [year, week] = (item["kw-from"] || "2023-16").split("-");
					const newWeekFrom = val;
					const newKwFrom = `${year}-${newWeekFrom}`;
					const kwTo = item["kw-to"] || newKwFrom;
					return { ...item, "week-from": newWeekFrom, "kw-from": newKwFrom, "kw-to": kwTo };
				}
				if (field === "week-to") {
					const [year, week] = (item["kw-to"] || "2023-16").split("-");
					const newWeekTo = val;
					const newKwTo = `${year}-${newWeekTo}`;
					const kwFrom = item["kw-from"] || newKwTo;
					return { ...item, "week-to": newWeekTo, "kw-to": newKwTo, "kw-from": kwFrom };
				}
				if (field === "year-from") {
					const [year, week] = (item["kw-from"] || "2023-16").split("-");
					const newYearFrom = val;
					const newKwFrom = `${newYearFrom}-${week}`;
					const kwTo = item["kw-to"] || newKwFrom;
					return { ...item, "year-from": newYearFrom, "kw-from": newKwFrom, "kw-to": kwTo };
				}
				if (field === "year-to") {
					const [year, week] = (item["kw-to"] || "2023-16").split("-");
					const newYearTo = val;
					const newKwTo = `${newYearTo}-${week}`;
					const kwFrom = item["kw-from"] || newKwTo;
					return { ...item, "year-to": newYearTo, "kw-to": newKwTo, "kw-from": kwFrom };
				}


			} else {
				return item;
			}
		});

		this.setState({ data: updatedData });
	}

	async saveData() {
		this.setState({ isReady: false });
		const updatedData = this.state.data.filter(item => item["kw-from"] && item["kw-to"]);
		const response = await editBauzeit(this.state.project, updatedData);
		if (response.status == 200) {
			this.setState({ isReady: true });
			this.setState({ edit: false });
			this.getBauzeitPlan(this.state.project);
		}
		
	}

	// async saveDataApi = () => {
		
	// 	const response = await editBauzeit(updatedData);
	// }



	render() {

		const { data } = this.state;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.edit == true)  {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.editBauzeitplanBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Bearbeiten</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4">
				        {data.map(item => {
				          const [yearFrom, weekFrom] = item["kw-from"] ? item["kw-from"].split("-") : ["", ""];
				          const [yearTo, weekTo] = item["kw-to"] ? item["kw-to"].split("-") : ["", ""];
				          return (
				            <div className="row mb-3" key={item.id}>
				              <div className="col-12"><strong>{item.name}</strong></div>
				              <div className="col-6">
				              	<div className="row">
				              		<div className="col-6 form-group">
				              			<label>KW von</label>
				              			<input 
				              				onChange={evt => this.updateInputValue(evt)}
				              				className="form-control"
				              				type="number"
				              				name={`week-from/${item.id}`}
				              				value={weekFrom}
				              			/>
				              		</div>
				              		<div className="col-6 form-group">
				              		<label>Jahr von</label>
				              			<input 
				              				onChange={evt => this.updateInputValue(evt)}
				              				className="form-control"
				              				type="number"
				              				name={`year-from/${item.id}`}
				              				value={yearFrom}
				              			/>
				              		</div>
				              	</div>
				              </div>
				              <div className="col-6">
				              	<div className="row">
				              		<div className="col-6 form-group">
				              			<label>KW bis</label>
				              			<input 
				              				onChange={evt => this.updateInputValue(evt)}
				              				className="form-control"
				              				type="number"
				              				name={`week-to/${item.id}`}
				              				value={weekTo}
				              			/>
				              		</div>
				              		<div className="col-6 form-group">
				              			<label>Jahr bis</label>
				              			<input 
				              				onChange={evt => this.updateInputValue(evt)}
				              				className="form-control"
				              				type="number"
				              				name={`year-to/${item.id}`}
				              				value={yearTo}
				              			/>
				              		</div>
				              	</div>
				              </div>
				            </div>
				          );
				        })}
						<button
							type="submit"
							className="btn btn-primary btn-block mb-5"
							onClick={ e => this.saveData(e) }
						>Speichern</button>
					</div>
				</>
			)
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Bauzeitplan</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.editBauzeitplan}
								>+</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.projectName &&
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h2>Bauzeitplan: { this.state.projectName }</h2>
							</div>
						</div>
					</div>
				}
				<div className="container bauzeitplan mt-1">
					<this.renderTable />
				</div>
			</>
		);
	}

}

export default withParams(Bauzeitplan);
