import React from 'react';
import ReactDOM from 'react-dom';

import { HeaderLogo } from "./Parts/Header.js";
import { Tiles } from "./Parts/Tiles.js";
import { requestForToken } from '../firebase/firebase';
import { getTickets  } from '../helper/restapi.js';


class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			openTickets: 0,
		};
	}

	async getTickets(title, cid, aid, state, project, house) {
		const response = await getTickets(title, cid, aid, state, project, house);
		const openTickets = response.data.data[1].length + response.data.data[2].length;
		this.setState({ openTickets: openTickets });
		// this.setState({ states: response.data.states });
		// this.setState({ users: response.data.users });
		// this.setState({ isReady: true });
	}

	componentDidMount() {
		this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		requestForToken(this.props.user.user_id);

		const tiles = [
			{
				'order': 1,
				'title': 'Projekte',
				'icon': 'projects',
				'route': 'projects',
			},
			{
				'order': 2,
				'title': 'Aufgaben',
				'icon': 'docs',
				'route': 'tickets',
			},
			{
				'order': 3,
				'title': 'Kontakte',
				'icon': 'contacts',
				'route': 'contacts',
			},
			{
				'order': 4,
				'title': 'Private Notizen',
				'icon': 'notes',
				'route': 'notes',
			},
			{
				'order': 5,
				'title': 'Baustellen-Update',
				'icon': 'notes',
				'route': 'updates',
			},
			{
				'order': 7,
				'title': 'Kalender',
				'icon': 'cal',
				'route': 'calendar',
			},
		];

		this.getTickets(null, null, this.props.user.user_id, null, null, null);

		if (this.props.user.user_role == 1 || this.props.user.user_role == 2) {
			tiles.push({
				'order': 5,
				'title': 'Besprechungs- protokolle',
				'icon': 'docs',
				'route': 'files',				
			})
		}

		if (this.props.user.user_role == 1) {
			tiles.push({
				'order': 6,
				'title': 'Schlüssel-Management',
				'icon': 'key',
				'route': 'keys',				
			})
		}

		this.setState({ data: tiles});
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLogo />
			);
		}

		return (
			<>
				<HeaderLogo />
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 offset-lg-3">
							<h2 className="text-center">Hallo { this.state.userFirstname }</h2>
						</div>
					</div>
				</div>
				<div className="container home">
					<Tiles data={ this.state.data } tickets={ this.state.openTickets } />
				</div>
			</>
		);
	}

}

export default Home;