import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { ShareOutline } from 'react-ionicons';

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getFilesApi, addNewFile } from '../helper/restapi.js';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}


function FileList(data) {
	return (
		<div className="row files">
			<File data={ data.data } handleLinkClick={data.handleLinkClick} />
		</div>
	);
}

function shareFile(item) {
	var text = 'Hier finden Sie die Datei "' + item.title + '" zum Projekt ' + item.projectname.name;
	if (navigator.share) {
	    navigator.share({
	        title: 'Datei: ' + item.title,
	        text: text,
	        url: item.fullpath
	    })
	}
}

function File({ data, handleLinkClick }) {
	var files = data;
	var i = 0;
	const items = files.map((item) =>
		<Link onClick={handleLinkClick} to={`/invoice/${item.in_id}`}>
		<div
			key={i++}
			className="file col-12"
		>
			
			<div className="inner">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-8">
								<div className="title">
									{ item.title }
								</div>
							</div>
							<div className="col-4 text-end">
								<div className="icons">
									<Link to={`/invoice/${item.in_id}`}>
										<img src="/icon/detail.svg" className="details" />
									</Link>
								</div>
							</div>
						</div>
					</div>
					{ item.in_id &&
						<>
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Status:
									</div>
									<div className="col-9">
										 { item.in_state_name }
									</div>
								</div>
							</div>	
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Kontrolle durch:
									</div>
									<div className="col-9">
										 { item.in_user }
									</div>
								</div>
							</div>	
							<div className="col-12 date">
								<div className="row">
									<div className="col-3">
										Hochgeladen:
									</div>
									<div className="col-9">
										 { item.date }
									</div>
								</div>
							</div>
							<div className="col-12 date">
								<div className="row">
									<div className="col-3">
										Höhe:
									</div>
									<div className="col-9">
										 € { item.in_amount }
									</div>
								</div>
							</div>
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Kommentar:
									</div>
									<div className="col-9">
										 { item.comment }
									</div>
								</div>
							</div>
							{ item.deadline &&
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Deadline:
									</div>
									<div className="col-9">
										 { item.deadline }
									</div>
								</div>
							</div>
							}
						</>					
					}
					{ !item.in_id &&
						<>
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Typ:
									</div>
									<div className="col-9">
										 <span className="text-uppercase">{ item.path.split('.').pop() }</span>
									</div>
								</div>
							</div>
							<div className="col-12 date">
								<div className="row">
									<div className="col-3">
										Datum:
									</div>
									<div className="col-9">
										 { item.date }
									</div>
								</div>
							</div>
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Kommentar:
									</div>
									<div className="col-9">
										 { item.comment }
									</div>
								</div>
							</div>
							{ item.deadline &&
							<div className="col-12 comment">
								<div className="row">
									<div className="col-3">
										Deadline:
									</div>
									<div className="col-9">
										 { item.deadline }
									</div>
								</div>
							</div>
							}
							<div className="col-12 user">
								<div className="row">
									<div className="col-3">
										Benutzer:
									</div>
									<div className="col-9">
										 { item.username }
									</div>
								</div>
							</div>
						</>
					}
				</div>
			</div>
		</div>
		</Link>
	);
	return items;
}

class Files extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			message: '',
			term: '',
			project: '',
			house: '',
			folders: [],
			files: [],
			filterfiles: [],
			currentFolder: '',
			add: false,
			users: [],
			filter: false,
			projectName: '',
			states: [],
			statefilter: 'all',
			filters: {
				user: null,
				project: null,
				house: null,
				title: null,
				comment: null,
				folder: 2,
			},
			userId: '',
			userRole: '',
			userFirstname: '',
			addFile: {
				user: '',
				project: '',
				house: '',
				title: '',
				comment: '',
				folder: 1,
				in_user: '',
				in_amount: '',
				in_state: 2,
				deadline: '',
			},
			scrollPosition: 0,
		};

		this.handleLinkClick = this.handleLinkClick.bind(this);
	}

	handleLinkClick() {
		const scrollPosition = window.scrollY;
		this.setState({ scrollPosition });
		localStorage.setItem('scrollPositionInvoice', scrollPosition);
	}

	async getFiles() {
		const response = await getFilesApi(this.state.filters);

		var responseData = response.data.data;

		console.log(responseData);

		responseData.sort((a, b) => new Date(b.date) - new Date(a.date));

		this.setState({ files: responseData }, () => {});
		this.setState({ users: response.data.users }, () => {});
		this.setState({ isReady: true })
		this.setState({ projectName: response.data.projectname })
		this.setState({ states: response.data.states })
	}

	addFile = () => {
		this.setState({ add: true });
	}

	addFileBack = () => {
		this.setState({ add: false });
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		console.log(name);
		console.log(val);

		if (name == 'file') {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]:  evt.target.files[0]
				}
			});
		} else {
			this.setState({
				addFile: {
					...this.state.addFile,
					[name]: val
				}
			});			
		}

	}

	saveFile = () => {

		var user = this.state.userId;
		var project = this.state.project;
		var house = this.state.house;
		var fileData = this.state.addFile;

		if (fileData.title && fileData.folder && fileData.file) {
			this.setState({ isReady: false });
			this.addFileData(user, project, house, fileData);
		} else {
			alert('Bitte Titel und Ordner wählen!');
		}

	}

	async addFileData(user, project, house, fileData) {
		const response = await addNewFile(user, project, house, fileData);
		if (response.data.success) {
			this.setState({ add: false });
			this.setState({ isReady: false });
			this.getFiles();
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: false });
		}
	}
	
	componentDidMount() {

		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');

			if (checkProjectHouse[1] == 'projects' && checkProjectHouse[3] == 'houses') {
				var project = checkProjectHouse[2];
				this.setState({ project: project });

				var house = checkProjectHouse[4];
				this.setState({ house: house })

				this.setState({
					filters: {
						...this.state.filters,
						'project': checkProjectHouse[2],
						'house': checkProjectHouse[4],
						'folder': 1,
					}
				}, () => {
					this.getFiles();
				});

			} else if (checkProjectHouse[1] == 'projects') {
				var project = checkProjectHouse[2];
				this.setState({ project: project })
				this.setState({
					filters: {
						...this.state.filters,
						'project': checkProjectHouse[2],
						'folder': 1,
					}
				}, () => {
					this.getFiles();
				});

			}

		} else {
			this.setState({
				filters: {
					...this.state.filters,
					'folder': 1,
				}
			}, () => {
				this.getFiles();
			});
		}

		const storedPosition = localStorage.getItem('scrollPositionInvoice');
		setTimeout(() => {
			window.scrollTo(0, parseInt(storedPosition));
		}, 1000);

	}

	searchInvoice = (event) => {
		var searchValue = event.target.value;
		searchValue = searchValue.toLowerCase();
		var originalData = this.state.files;

		if (searchValue.length > 0) {
			this.setState({ filter: true });

			var filteredData = originalData;
			this.setState({ filterfiles: filteredData });	

			var filtered = [];

			for(var i=0; i < filteredData.length; i++) {

				var houseNames = '';

				if (filteredData[i]['in_splits']) {
					const houseNamesArray = filteredData[i]['in_splits'].map(item => item.house_name);
					houseNames = houseNamesArray.join(' ');
				}

				var searchIn = filteredData[i]['title'] + ' ' + filteredData[i]['comment'] + ' ' + filteredData[i]['in_user'] + ' ' + filteredData[i]['in_amount'] + ' ' + houseNames;
				console.log(searchIn)

				searchIn = searchIn.toLowerCase();

				if(searchIn.indexOf(searchValue)!=-1) {
					filtered.push(filteredData[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
			}

			this.setState({ filterfiles: filtered });			
		} else {
			this.setState({ filter: false });	
			this.setState({ message: '' });
		}

	}

	updateFilterValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({ statefilter: val })

		var originalData = this.state.files;

		if (val !== 'all') {

			var stateSearch = parseInt(val) + 1;

			console.log(stateSearch)

			this.setState({ filter: true });

			var filteredData = originalData;
			this.setState({ filterfiles: filteredData });	

			var filtered = [];

			for(var i=0; i < filteredData.length; i++) {

				if( filteredData[i]['in_state'] == stateSearch ) {
					filtered.push(filteredData[i]);
				}
			}

			if (filtered.length < 1) {
				this.setState({ message: 'Die Suche ergab keine Treffer.' });
			} else {
				this.setState({ message: '' });
			}

			this.setState({ filterfiles: filtered });			
		} else {
			this.setState({ filter: false });	
			this.setState({ message: '' });
		}

	}

	render() {

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								{ !this.state.add &&
									<GoBack />
								}
								{ this.state.add &&
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addFileBack}
									/>
								}
							</div>
							<div className="col-8 text-center">
								<h1>Rechnungen</h1>
							</div>
							<div className="col-2 add-contact text-end">
								{ !this.state.add &&
								<div
									className="add-link"
									onClick={this.addFile}
								>+</div>
								}
							</div>
						</div>
					</div>
				</div>
				
				{ !this.state.add &&
					<>
						<div className="search-bar">
							<div className="container">
								<div className="row">
									<div className="col-6">
										<div className="form-group">
											<input
												type="text"
												placeholder="Suchen"
												onKeyUp={this.searchInvoice}
											/>
										</div>
									</div>
									<div className="col-6">
										<div className="form-group">
											<select
												className="form-select"
												onChange={evt => this.updateFilterValue(evt)}
												name="states"
												value={this.state.statefilter}
											>
												<option value="all">Alle</option>
												{this.state.states.map((option, index) => (
													<>
													{ index > 0 &&
														<option key={index} value={index} selected={index === this.state.statefilter}>
															{ option }
														</option>
													}
													</>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>					
						</div>
						{this.state.projectName &&
							<div className="container">
								<div className="row">
									<div className="col-12">
										<h2>Rechnungen: { this.state.projectName }</h2>
									</div>
								</div>
							</div>
						}
						
						{ this.state.files.length > 0 &&
							<div className="container">
								{ !this.state.filter &&
									<FileList handleLinkClick={this.handleLinkClick} data={ this.state.files } />
								}
								{ this.state.message && 
									<p className="mt-5 text-center">{ this.state.message }</p>
								}
								{ this.state.filter &&
									<FileList handleLinkClick={this.handleLinkClick} data={ this.state.filterfiles } />
								}
							</div>
						}
						{ this.state.files.length < 1 &&
							<div className="container">
								{ this.state.currentFolder &&
									<h2 className="">{ this.state.currentFolder }</h2>
								}
								{ !this.state.currentFolder &&
									<h2 className="">Alle Dateien</h2>
								}
								
								<p>Keine Dateien in diesem Ordner</p>
							</div>
						}

					</>
				}

				{ this.state.add &&
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Titel</label>
									<input
										type="text"
										name="title"
										value={this.state.addFile.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Benutzer</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="in_user"
										value={this.state.addFile.in_user}
									>
										<option value="">Benutzer wählen</option>
										{this.state.users.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>SUMME (BRUTTO)</label>
									<input
										type="number"
										name="in_amount"
										value={this.state.addFile.in_amount}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Deadline</label>
									<input
										type="date"
										name="deadline"
										value={this.state.addFile.deadline}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label>Kommentar</label>
									<textarea
										type="text"
										name="comment"
										value={this.state.addFile.comment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
							</div>
							<input
								id="imageUpload"
								name="file"
								type="file"
								className="mb-4"
								onChange={evt => this.updateInputValue(evt)}
							/>
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveFile(e) }
								>Dokument hochladen</button>
							</div>
						</div>
					</div>
				}
			</>
		);
	}

}

export default withParams(Files);
