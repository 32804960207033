import { Outlet, Link } from "react-router-dom";

var navigationStructur = [
	{
		name: 'Home',
		route: '/',
	},
	{
		name: 'Contact',
		route: '/contacts',
	},
];

const Layout = () => {

	const navigation = navigationStructur.map((item) =>
		<li key={item.route}>
			<Link to={item.route}>{item.name}</Link>
		</li>
	);

	return (
		<>
			<Outlet />
			<nav className="d-none">
				<ul>
					{navigation}
				</ul>
			</nav>
		</>
	)
};

export default Layout;