import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";
import { MicOutline, CameraOutline, DocumentTextOutline } from 'react-ionicons'

import { getNotesByUserId, getNotesByProjectId, getNotesByHouseId, addNote } from '../helper/restapi.js';

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { HeaderLoading, GoBack, Loading } from "./Parts/Header.js";
import { NotesList } from "./Parts/Notes.js";

import { ReactComponent as IconAudio } from '../images/audio.svg';
import { ReactComponent as IconPhoto } from '../images/photo.svg';
import { ReactComponent as IconText } from '../images/text.svg';


function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

const AudioRecorderComponent = (data) => {

	var currentdate = new Date(); 
	var datetime = "Notiz: " + currentdate.getDate() + "."
		+ (currentdate.getMonth()+1)  + "." 
		+ currentdate.getFullYear() + " "  
		+ currentdate.getHours() + ":"  
		+ currentdate.getMinutes() + ":" 
		+ currentdate.getSeconds();

	const [loading, setLoading] = useState(false);
	const [recording, setRecording] = useState(false);
	const [text, setText] = useState(datetime);
	const [success, setSuccuess] = useState('');

	const recorderControls = useAudioRecorder();

	const startRecording = () => {
		setRecording(true);
		recorderControls.startRecording()
	};
	const stopRecording = () => {
		setRecording(false);
		recorderControls.stopRecording()
	};

	const handleAudioElement = async (blob) => {
		setLoading(true);

		var type = 'audio';	
		var note = '';
		var image = '';
		const response = await addNote(text, type, data.data.userId, data.data.project, data.data.house, blob, note);
		
		if (response.statusText == 'OK') {
			setSuccuess('1');
			setLoading(false);
		} else {
			setSuccuess('0');
			setLoading(false);
		}
	};

	const updateInputValue = async (evt) => {
		const name = evt.target.name;
		const val = evt.target.value;
		setText(val);
	}

	return (
		<div>
		
			{loading == true &&
				<Loading />
			}

			<AudioRecorder 
				onRecordingComplete={(blob) => handleAudioElement(blob)}
				recorderControls={recorderControls}
			/>
			{success == '1' &&
				<div className="alert alert-success mt-3" role="alert">
					Erfolgreich gespeichert!
				</div>
			}
			{success == '0' &&
				<div className="alert alert-danger mt-3" role="alert">
					Es ist ein Fehler aufgetreten!
				</div>
			}

			{success !== '1' &&
				<div>
					<div className="form-group mt-3">
						<label>Titel</label>
						<input
							type="text"
							name="text"
							value={ text }
							className="form-control"
							onChange={evt => updateInputValue(evt)}
						/>
					</div>

					{!recording &&
						<button className="record-start" onClick={startRecording}>Aufnahme starten</button>
					}
					{recording &&
						<button className="record-end pulse" onClick={stopRecording}>Aufnahme beenden</button>
					}
				</div>
			}
		</div>
	)
}

const TextNoteComponent = (data) => {

	var currentdate = new Date(); 
	var datetime = "Notiz: " + currentdate.getDate() + "."
		+ (currentdate.getMonth()+1)  + "." 
		+ currentdate.getFullYear() + " "  
		+ currentdate.getHours() + ":"  
		+ currentdate.getMinutes() + ":" 
		+ currentdate.getSeconds();

	const [text, setText] = useState(datetime);
	const [note, setNote] = useState('');
	const [success, setSuccuess] = useState('');

	const updateInputValue = async (evt) => {
		const name = evt.target.name;
		const val = evt.target.value;
		setText(val);
	}

	const updateNoteValue = async (evt) => {
		const name = evt.target.name;
		const val = evt.target.value;
		setNote(val);
	}

	const saveNote = async (blob) => {
		var type = 'text';	
		var blob = '';
		var image = '';
		const response = await addNote(text, type, data.data.userId, data.data.project, data.data.house, blob, note);
		
		if (response.statusText == 'OK') {
			setSuccuess('1');
		} else {
			setSuccuess('0');
		}
	};

	return (
		<div>
			{success == '1' &&
				<div className="alert alert-success mt-3" role="alert">
					Erfolgreich gespeichert!
				</div>
			}
			{success == '0' &&
				<div className="alert alert-danger mt-3" role="alert">
					Es ist ein Fehler aufgetreten!
				</div>
			}

			{success !== '1' &&
				<div>
					<div className="form-group mt-3">
						<label>Titel</label>
						<input
							type="text"
							name="text"
							value={ text }
							className="form-control"
							onChange={evt => updateInputValue(evt)}
						/>
					</div>
					<div className="form-group">
						<label>Notiz</label>
						<textarea 
							className="form-control"
							name="note"
							value={ note }
							rows="3"
							onChange={evt => updateNoteValue(evt)}
						>
						</textarea>
					</div>
					<button
						type="submit"
						className="btn btn-primary btn-block"
						onClick={ e => saveNote() }
					>Speichern</button>

				</div>
			}
		</div>
	)

}

const ImageNoteComponent = (data) => {
	var currentdate = new Date(); 
	var datetime = "Notiz: " + currentdate.getDate() + "."
		+ (currentdate.getMonth()+1)  + "." 
		+ currentdate.getFullYear() + " "  
		+ currentdate.getHours() + ":"  
		+ currentdate.getMinutes() + ":" 
		+ currentdate.getSeconds();

	const [loading, setLoading] = useState(false);
	const [text, setText] = useState(datetime);
	const [note, setNote] = useState('');
	const [success, setSuccuess] = useState('');

	const [imageFile, setImageFile] = useState(null);
	const [showCamera, setShowCamera] = useState(false);
	const [showImage, setShowImage] = useState(false);

	const handleTakePhoto = (dataUri) => {
		const blob = dataURItoBlob(dataUri);
		setImageFile(blob);
		setShowImage(true);
		setShowCamera(false);
	};

	const updateInputValue = async (evt) => {
		const name = evt.target.name;
		const val = evt.target.value;
		setText(val);
	}

	const updateNoteValue = async (evt) => {
		const name = evt.target.name;
		const val = evt.target.value;
		setNote(val);
	}

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		setImageFile(file);
		setShowImage(true);
	};

	const handleCancel = () => {
		setImageFile(null);
		setShowCamera(false);
		setShowImage(false);
	};

	const handleSubmit = async () => {
		setLoading(true);
		var type = 'image';	
		var blob = imageFile;

		const response = await addNote(text, type, data.data.userId, data.data.project, data.data.house, blob, note);

		if (response.statusText == 'OK') {
			setSuccuess('1');
			setLoading(false);
		} else {
			setSuccuess('0');
			setLoading(false);
		}

	};

	const handleUploadClick = () => {
		document.getElementById('imageUpload').click();
	};

	const handleTakePhotoClick = () => {
		setShowCamera(true);
	};

	return (

		<div>

			{loading == true &&
				<Loading />
			}
			{success == '1' &&
				<div className="alert alert-success mt-3" role="alert">
					Erfolgreich gespeichert!
				</div>
			}
			{success == '0' &&
				<div className="alert alert-danger mt-3" role="alert">
					Es ist ein Fehler aufgetreten!
				</div>
			}

			{!showCamera && !showImage && (
				<div>
					<button
						className="record-start mt-3"
						onClick={handleUploadClick}>
						Foto hochladen
					</button>
					<button
						className="record-start mt-3"
						onClick={handleTakePhotoClick}>
						Foto aufnehmen
					</button>
				</div>
			)}

			{showCamera && (
				<Camera
					//idealFacingMode={FACING_MODES.USER}
					idealFacingMode={FACING_MODES.ENVIRONMENT}
					onTakePhoto={(dataUri) => {
						handleTakePhoto(dataUri);
					}}
				/>
			)}

			{showImage && success !== '1' && (
				<div>
					<div className="form-group mt-3">
						<label>Titel</label>
						<input
							type="text"
							name="text"
							value={ text }
							className="form-control"
							onChange={evt => updateInputValue(evt)}
						/>
					</div>
					<div className="form-group">
						<label>Notiz</label>
						<textarea 
							className="form-control"
							name="note"
							value={ note }
							rows="3"
							onChange={evt => updateNoteValue(evt)}
						>
						</textarea>
					</div>
					<img src={URL.createObjectURL(imageFile)} alt="Taken or uploaded" />
					<button
						className="record-start mt-3"
						onClick={handleSubmit}>
						Speichern
					</button>
					<button
						className="record-start red mt-3"
						onClick={handleCancel}>
						Abbrechen
					</button>
				</div>
			)}

			<input
				id="imageUpload"
				type="file"
				accept="image/*"
				onChange={handleFileUpload}
				style={{ display: 'none' }}
			/>
		</div>
	);
};

function dataURItoBlob(dataURI) {
	const byteString = atob(dataURI.split(',')[1]);
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: 'image/jpeg' });
}

class Notes extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			userId: '',
			userRole: '',
			userFirstname: '',
			location: '',
			project: null,
			projectName: '',
			house: null,
			view: '',
			data: [],
			recording: false,
			blobURL: null,
		};
	}

	async getMyNotes() {
		if (this.state.project && this.state.house) {
			const response = await getNotesByHouseId(this.state.house);
			this.setState({ data: response.data });
			this.setState({ isReady: true });
		} else if (this.state.project) {
			const response = await getNotesByProjectId(this.state.project);
			this.setState({ data: response.data });
			this.setState({ isReady: true });
			this.setState({ projectName: response.data.projectname });
		}	else {
			const response = await getNotesByUserId();
			this.setState({ data: response.data });
			this.setState({ isReady: true });
		}
		

	}

	componentDidMount() {
		this.getMyNotes();
		this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ location: this.props.location.state });


		if (this.props.location.state) {
			var checkProjectHouse = this.props.location.state.split('/');
			if (checkProjectHouse[1] == 'projects') {
				this.setState({ project: checkProjectHouse[2] });
			}	
			if (checkProjectHouse[3] == 'houses') {
				this.setState({ house: checkProjectHouse[4] });
			}			
		}

		
	}

	componentDidUpdate() {
		this.getMyNotes();
	}

	toggleAudio = () => {
		this.setState({ view: 'audio' })
	}
	toggleImage = () => {
		this.setState({ view: 'image' })
	}
	toggleText = () => {
		this.setState({ view: 'text' })
	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.view == 'audio') {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-1">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={() => {
											this.setState({ view: '' })
										}}
									/>
								</div>
								<div className="col-10 text-center">
									<h1>Notiz hinzufügen</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<AudioRecorderComponent
							data={{ location: this.state.location, userId: this.state.userId, project: this.state.project, house: this.state.house }}
						/>
					</div>
				</>
			);
		}

		if (this.state.view == 'image') {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-1">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={() => {
											this.setState({ view: '' })
										}}
									/>
								</div>
								<div className="col-10 text-center">
									<h1>Notiz hinzufügen</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<ImageNoteComponent
							data={{ location: this.state.location, userId: this.state.userId, project: this.state.project, house: this.state.house }}
						/>
					</div>
				</>
			);
		}

		if (this.state.view == 'text') {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-1">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={() => {
											this.setState({ view: '' })
										}}
									/>
								</div>
								<div className="col-10 text-center">
									<h1>Notiz hinzufügen</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<TextNoteComponent
							data={{ location: this.state.location, userId: this.state.userId, project: this.state.project, house: this.state.house }}
						/>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-1">
								<GoBack />
							</div>
							<div className="col-10 text-center">
								<h1>Notizen</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="container notes">

					{this.state.projectName &&
						<div className="row">
							<div className="col-12">
								<h2 className="pb-0">Notizen: { this.state.projectName }</h2>
							</div>
						</div>
					}

					{!this.state.projectName &&
						<div className="row">
							<div className="col-12">
								<h2 className="pb-0">Notizen</h2>
							</div>
						</div>
					}

					<div className="row tiles">
						<div 
							className="tile col-4 col-md-4 col-lg-2"
							onClick={this.toggleAudio}
						>
							<div className="inner ratio ratio-1x1">
								<div className="inner-inner text-center">	
									<div className="icon-sm">
										<IconAudio
											className=""
											height="30px"
											width="30px"
										/>
									</div>
									<div className="title">
										Audio
									</div>
								</div>
							</div>
						</div>
						<div 
							className="tile col-4 col-md-4 col-lg-2"
							onClick={this.toggleImage}
						>
							<div className="inner ratio ratio-1x1">
								<div className="inner-inner text-center">	
									<div className="icon-sm">
										<IconPhoto
											className=""
											height="30px"
											width="30px"
										/>
									</div>
									<div className="title">
										Bild
									</div>
								</div>
							</div>
						</div>
						<div 
							className="tile col-4 col-md-4 col-lg-2"
							onClick={this.toggleText}
						>
							<div className="inner ratio ratio-1x1">
								<div className="inner-inner text-center">	
									<div className="icon-sm">
										<IconText
											className=""
											height="30px"
											width="30px"
										/>	
									</div>
									<div className="title">
										Text
									</div>
								</div>
							</div>
						</div>
					</div>

					<h2 className="pb-0 mt-3">Meine Notizen</h2>

					{ this.state.data.data &&
						<NotesList data={ this.state.data.data } userid={ this.state.userId } userrole={ this.state.userRole } />
					}
				</div>
			</>
		);
	}

}

export default withParams(Notes);
