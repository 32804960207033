import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, Link } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getKeys, addKey } from '../helper/restapi.js';

import ReactCanvasPaint from 'react-canvas-paint'
import 'react-canvas-paint/dist/index.css'

import SignatureCanvas from 'react-signature-canvas'

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

function KeyList(props) {	
	return (
		<ul className="contact-list keys mt-3">
			<KeyPart props={ props } />
		</ul>
	);
}

function KeyPart(data) {
	var keys = data.props.data;
	var i = 0;

	const items = keys.map((item) =>
		<li
			key={i++}
			className="contact-item"
		>
			<div className="row">
				<Link className="col-7" to={`/keys/${item.id}`}>
					<div className="title mb-2">
						{ item.name &&
							<span>{item.name} </span>
						}
					</div>
					<div className="company">
						<div className="row">
							<div className="col-6 col-lg-4"><strong>Status:</strong></div>
							{ item.date_return &&
								<div className="col-6 col-lg-8 text-success">retourniert</div>
							}
							{ !item.date_return &&
								<div className="col-6 col-lg-8 text-danger">nicht retourniert</div>
							}
						</div>
						{ item.date_given &&
							<div className="row">
								<div className="col-6 col-lg-4"><strong>Vergeben am:</strong></div>
								<div className="col-6 col-lg-8"><span>{item.date_given}</span></div>
							</div>
						}
						{ item.date_return &&
							<div className="row">
								<div className="col-6 col-lg-4"><strong>Retourniert am:</strong></div>
								<div className="col-6 col-lg-8"><span>{item.date_return}</span></div>
							</div>
						}
						{ item.projectname &&
							<div className="row">
								<div className="col-6 col-lg-4"><strong>Projekt:</strong></div>
								<div className="col-6 col-lg-8"><span>{item.projectname}</span></div>
							</div>
						}
						{ item.housename &&
							<div className="row">
								<div className="col-6 col-lg-4"><strong>Haus:</strong></div>
								<div className="col-6 col-lg-8"><span>{item.housename}</span></div>
							</div>
						}
						{ item.keys &&
							<div className="row">
								<div className="col-6 col-lg-4"><strong>Schlüsselnr.:</strong></div>
								<div className="col-6 col-lg-8"><span>{item.keys}</span></div>
							</div>
						}
					</div>
				</Link>
				<div
					className="col-5 text-end"
				>
					
					<Link className="link" to={`/keys/${item.id}`}><img src="/icon/detail.svg" className="details" /></Link>
				</div>
			</div>
		</li>
	);
	return items;
	
}

class Keys extends React.Component {

	constructor(props) {
		super(props);
		this.signatureRef = React.createRef();
		this.state = {
			isReady: false,
			data: [],
			message: '',
			project: '',
			house: '',
			houses: [],
			add: false,
			signature: false,
			addKey: {
				date_given: '',
				name: '',
				signature_given: '',
				project: '',
				house: '',
				comment: '',
				keys: '',
				key1: '',
				key2: '',
				key3: '',
				key4: '',
				key5: '',
				count: 1,
			},
		};
	}

	async getKeys(project, house, state) {

		const response = await getKeys(project, house, state);

		this.setState({ data: response.data });
		this.setState({ isReady: true });

	}

	componentDidMount() {
		this.getKeys(null, null, 2);

		var m = new Date();
		var dateString = m.getUTCFullYear() + '-' + (m.getUTCMonth()+1) + '-' + m.getUTCDate();
		this.setState({
			addKey: {
				...this.state.addKey,
				date_given: dateString
			}
		});

	}

	addKey = () => {
		this.setState({ add: true });
	}

	addKeyBack = () => {
		this.setState({ add: false });
	}

	closeSignature = () => {
		this.setState({ signature: false });
	}

	updateFilter(evt) {
		const val = evt.target.value;
		if (val == 1) {
			this.getKeys(null, null, 1);
		} else if (val == 1) {
			this.getKeys(null, null, 2);
		} else {
			this.getKeys(null, null, null);
		}
	}

	countDown = () => {
		var count = this.state.addKey.count;
		count--;

		if (count < 1) {
			count = 1;
		}
		if (count > 5) {
			count = 5;
		}

		this.setState({
			addKey: {
				...this.state.addKey,
				count: count
			}
		});
	}

	countUp = () => {
		var count = this.state.addKey.count;
		count++;

		if (count < 1) {
			count = 1;
		}
		if (count > 5) {
			count = 5;
		}

		this.setState({
			addKey: {
				...this.state.addKey,
				count: count
			}
		});
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'upload') {
			this.setState({
				addKey: {
					...this.state.addKey,
					[name]:  evt.target.files[0]
				}
			});
		} else {
			if (name == 'project') {
				var houses = this.state.data.projects.find(item => item.id == val);
				this.setState({ houses: houses.houses });
			}

			if (name == 'count') {
				if (val < 1) {
					val = 1;
				}
				if (val > 5) {
					val = 5;
				}
			}

			this.setState({
				addKey: {
					...this.state.addKey,
					[name]: val
				}
			});			
		}


	}

	preSaveKey(e) {

		var keys = [];

		var key1 = this.state.addKey.key1;
		var key2 = this.state.addKey.key2;
		var key3 = this.state.addKey.key3;
		var key4 = this.state.addKey.key4;
		var key5 = this.state.addKey.key5;
		if (this.state.addKey.count > 0) {
			keys.push(key1);
		}
		if (this.state.addKey.count > 1) {
			keys.push(key2);
		}
		if (this.state.addKey.count > 2) {
			keys.push(key3);
		}
		if (this.state.addKey.count > 3) {
			keys.push(key4);
		}
		if (this.state.addKey.count > 4) {
			keys.push(key5);
		}

		this.setState({
			addKey: {
				...this.state.addKey,
				keys: keys.toString()
			}
		});

		this.setState({ signature: true })

	}

	async saveKey() {
		// this.setState({
		// 	isReady: false,
		// })
		var keyData = {
			date_given: this.state.addKey.date_given,
			name: this.state.addKey.name,
			signature_given: this.state.addKey.signature_given,
			project: this.state.addKey.project,
			house: this.state.addKey.house,
			comment: this.state.addKey.comment,
			keys: this.state.addKey.keys,
			upload: this.state.addKey.upload,			
		}

		const response = await addKey(keyData);

		if (response.data.success) {
			this.setState({
				addKey: {
					...this.state.addKey,
					name: '',
					signature_given: '',
					project: null,
					house: null,
					comment: '',
					keys: '',
					key1: '',
					key2: '',
					key3: '',
					key4: '',
					key5: '',	
				}
			});
			this.getKeys(null, null, 2);
			this.setState({
				add: false,
				signature: false,
				isReady: true,
			})
		}
	}

	getSignature = () => {
		const imageDataUrl = this.signatureRef.current.toDataURL();

		this.setState({
			addKey: {
				...this.state.addKey,
				signature_given: imageDataUrl
			}
		});
	};

	getSignatureOld(data) {

		const canvas = document.createElement('canvas');
		canvas.width = 300;
		canvas.height = 200;
		const ctx = canvas.getContext('2d');

		const imageData = ctx.createImageData(300, 200);

		var draw = data.data;

		for (let i = 0; i < draw.length; i += 4) {
			imageData.data[i] = draw[i];
			imageData.data[i + 1] = draw[i + 1];
			imageData.data[i + 2] = draw[i + 2];
			imageData.data[i + 3] = draw[i + 3];
		}

		ctx.putImageData(imageData, 0, 0);

		const base64Image = canvas.toDataURL('image/png');

		this.setState({
			addKey: {
				...this.state.addKey,
				signature_given: base64Image
			}
		});

	}

	render() {

		const { data } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.signature == true) {
			return (
				<div className="signature-outer">
					<div className="signature text-center pt-5">
						
						<div className="text-center">
							<SignatureCanvas
								ref={this.signatureRef}
								penColor='#0170C1'
    							canvasProps={{width: 300, height: 200, className: 'signature-detail'}}
    							onEnd={this.getSignature}
    						/>
						</div>
						<label>Unterschrift von { this.state.addKey.name }</label>

						<div className="container mt-5">
						<button
							type="submit"
							className="btn btn-primary btn-block mb-1"
							onClick={ e => this.saveKey(e) }
						>Speichern</button>
						<button
							type="submit"
							className="btn btn-danger btn-block"
							onClick={ e => this.closeSignature() }
						>Abbrechen</button>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.add == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addKeyBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Eintrag hinzufügen</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Anzahl Schlüssel</label>
									<div className="container">
										<div className="row">
											<div onClick={this.countDown} className="col-1 pr-0 text-center tab-count">
												<span>-</span>
											</div>
											<div className="col-10 text-center">
												<input
													type="number"
													name="count"
													value={this.state.addKey.count}
													className="form-control"
													onChange={evt => this.updateInputValue(evt)}
												/>
											</div>
											<div onClick={this.countUp} className="col-1 text-center tab-count">
												<span>+</span>
											</div>
										</div>
									</div>
								</div>
								{ this.state.addKey.count > 0 &&
									<div className="form-group col-12">
										<label>Schlüsselnummer 1</label>
										<input
											type="text"
											name="key1"
											value={this.state.addKey.key1}
											className="form-control"
											onChange={evt => this.updateInputValue(evt)}
										/>
									</div>
								}
								{ this.state.addKey.count > 1 &&
									<div className="form-group col-11 offset-1">
										<label>Schlüsselnummer 2</label>
										<input
											type="text"
											name="key2"
											value={this.state.addKey.key2}
											className="form-control"
											onChange={evt => this.updateInputValue(evt)}
										/>
									</div>
								}
								{ this.state.addKey.count > 2 &&
									<div className="form-group col-11 offset-1">
										<label>Schlüsselnummer 3</label>
										<input
											type="text"
											name="key3"
											value={this.state.addKey.key3}
											className="form-control"
											onChange={evt => this.updateInputValue(evt)}
										/>
									</div>
								}
								{ this.state.addKey.count > 3 &&
									<div className="form-group col-11 offset-1">
										<label>Schlüsselnummer 4</label>
										<input
											type="text"
											name="key4"
											value={this.state.addKey.key4}
											className="form-control"
											onChange={evt => this.updateInputValue(evt)}
										/>
									</div>
								}
								{ this.state.addKey.count > 4 &&
									<div className="form-group col-11 offset-1">
										<label>Schlüsselnummer 5</label>
										<input
											type="text"
											name="key5"
											value={this.state.addKey.key5}
											className="form-control"
											onChange={evt => this.updateInputValue(evt)}
										/>
									</div>
								}
								<div className="form-group">
									<label>Projekt</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="project"
										value={this.state.addKey.project}
									>
										<option value="">Projekt wählen</option>
										{this.state.data.projects.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
								{ this.state.houses.length > 0 &&
									<div className="form-group">
										<label>Haus</label>
										<select
											className="form-select"
											onChange={evt => this.updateInputValue(evt)}
											name="house"
											value={this.state.addKey.house}
										>
											<option value="">Haus wählen</option>
											{this.state.houses.map((option) => (
												<option key={option.id} value={option.id}>
													{ option.name}
												</option>
											))}
										</select>
									</div>
								}
								<div className="form-group">
									<label>Name</label>
									<input
										type="text"
										name="name"
										value={this.state.addKey.name}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Kommentar</label>
									<input
										type="text"
										name="comment"
										value={this.state.addKey.comment}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Datum der Übergabe</label>
									<input
										type="date"
										name="date_given"
										value={this.state.addKey.date_given}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>

								<div>
									<label>Bild hinzufügen</label>
									<div>
									<input
										id="imageUpload"
										name="upload"
										type="file"
										className="mb-4"
										onChange={evt => this.updateInputValue(evt)}
									/>
									</div>
								</div>

								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.preSaveKey(e) }
								>Unterzeichnen</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Schlüsselmanagement</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.addKey}
								>+</div>
							</div>
						</div>
					</div>
				</div>
				<div className="search-bar">
					<div className="container">
						<div className="form-group">
							<select
								className="form-select"
								onChange={evt => this.updateFilter(evt)}
								name="filter"
								value={this.state.filter}
							>
								<option key="1" value="2">
									Nicht retourniert
								</option>
								<option key="2" value="1">
									Bereits retourniert
								</option>
								<option key="3" value="">
									Alle anzeigen
								</option>
							</select>
						</div>
					</div>					
				</div>
				
				{ data &&
					<div className="container">
					<KeyList data={ data } />
					</div>
				}
				
			</>
		);
	}

}

export default withParams(Keys);
